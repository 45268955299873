// import { allapiAction } from "@/Redux/common/action";
// import { URL } from "@/Redux/common/url";
// import Link from "next/link";
// import { useRouter } from "next/router";
import React, { useEffect, useLayoutEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { allapiAction } from "../../Redux/common/action";
import { URL } from "../../Redux/common/url";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import CoustomImg from "../CoustomImg/CoustomImg";
import TruncateText from "../TruncateText/TruncateText";
import OurWork from "../OurWork/OurWork";
import Seo from "../Seo/Seo";
// import CoustomImg from "../../Component/CoustomImg/CoustomImg";
// import TruncateText from "../../Component/TruncateText/TruncateText";

function AllProducts() {
  const dispatch = useDispatch();
  const router = useNavigate();

  const locationv = useLocation();

  const searchParams = new URLSearchParams(locationv.search);
  const search = searchParams.get("search");
  const location = searchParams.get("location");
  const page = searchParams.get("page");

  // const { search, page, location } = useParams();
  const get_product_list = useSelector((state) =>
    state?.allapi?.user_product_search ? state?.allapi?.user_product_search : {}
  );

  const get_category_Filter = useSelector((state) =>
    state?.allapi?.get_category_Filter ? state?.allapi?.get_category_Filter : []
  );

  console.log(get_category_Filter);

  const pageno = page ? page : 1;

  const listdata = get_product_list?.docs ? get_product_list?.docs : [];
  const listdataListing = get_product_list?.listingList?.docs
    ? get_product_list?.listingList?.docs
    : [];
  // get_product_list

  useEffect(() => {
    // searchProduct

    if (search) {
      if (location) {
        dispatch(
          allapiAction.userSearchProduct({ page: pageno, id: search, location })
        );
      } else {
        dispatch(allapiAction.userSearchProduct({ page: pageno, id: search }));
      }
    } else {
      if (location) {
        dispatch(allapiAction.userSearchProduct({ page: pageno, location }));
      } else {
        dispatch(allapiAction.userSearchProduct({ page: pageno }));
      }
    }
    dispatch(allapiAction.getfiltercategory({}));
    return () => {};
  }, [locationv.search]);

  const henldetofilterbyCate = (e) => {
    if (search) {
      dispatch(
        allapiAction.userSearchProduct({ page: pageno, id: search, cateId: e })
      );
    }
  };
  const [childcateee, setchildcateee] = useState("");
  const hendletoviewchlidcate = (e) => {};

  const items = get_product_list?.totalDocs;
  const itemsPerPage = 16;
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setPageCount(Math.ceil(items && items / itemsPerPage));
  }, [itemOffset, itemsPerPage, get_product_list, items]);

  const handlePageClick = (event) => {
    const data = event?.selected + 1;
    // const newOffset = (event.selected * itemsPerPage) % items;
    // setItemOffset(newOffset);
    // setPage(data);
    const srch = search ? search : "";
    router("/products?search=" + srch + "&page=" + data);
    window.scrollTo(12, 0);
    // dispatch(allapiAction.lostfoundlistpublic({ page: data }));
  };

  const [windowWidth, setWindowWidth] = useState(600);

  // Update window width when the window is resized
  useLayoutEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }
    setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="container-fluid">
      <Seo
        settitle={
          "India's Best Online Shopping Platform for Products & Services"
        }
        titleSeo={
          "Shop Online in India | Best Bharat eCommerce for Affordable Products & Services"
        }
        seoKeyword={
          "Best eCommerce platform India, Affordable online shopping India, Vendor product marketplace, Buy and sell products online India, Online shopping website India, Best Bharat eCommerce portal, Small business eCommerce India, Local shopping platform India, Product comparison website India, Shop services online in India"
        }
        seo_descriptions={
          "Shop on Best Bharat, India's trusted eCommerce platform for a wide range of products and services. Find the best deals, compare prices, and enjoy seamless shopping with vendor-direct listings and fast lead generation.​"
        }
        seoconcal={"https://bestbharat.com/"}
        title={"India's Best Online Shopping Platform for Products & Services"}
      />
      <div className="ps-deal-of-day shewBox">
        <div className="ps-container">
          <div class="ps-layout--shop mt-30">
            <div class="ps-layout__left">
              <aside class="widget widget_shop">
                <h4 class="widget-title">Categories</h4>
                <ul class="ps-list--categories">
                  {get_category_Filter &&
                    get_category_Filter?.map((data, i) => {
                      return (
                        <li class="menu-item-has-children" key={i}>
                          <a href="#">{data?.name}</a>
                          <span
                            class={
                              childcateee == data?._id
                                ? "sub-toggle active "
                                : "sub-toggle"
                            }
                            onClick={() => {
                              setchildcateee(data?._id);
                            }}
                          >
                            <i class="fa fa-angle-down"></i>
                          </span>
                          <ul
                            class="sub-menu"
                            style={
                              childcateee == data?._id
                                ? { display: "block" }
                                : { display: "none" }
                            }
                          >
                            {data?.subcates &&
                              data?.subcates?.map((datachild, index) => {
                                return (
                                  <li key={index}>
                                    <a
                                      href="#"
                                      onClick={() => {
                                        henldetofilterbyCate(datachild?._id);
                                      }}
                                    >
                                      {datachild?.name}
                                    </a>
                                  </li>
                                );
                              })}
                          </ul>
                        </li>
                      );
                    })}
                </ul>
              </aside>
            </div>
            <div class="ps-layout__right">
              <div class="ps-block--shop-features">
                <div class="ps-block__header">
                  <h3>Product</h3>
                  <div class="ps-block__navigation">
                    <a class="ps-carousel__prev" href="#recommended1">
                      <i class="icon-chevron-left"></i>
                    </a>
                    <a class="ps-carousel__next" href="#recommended1">
                      <i class="icon-chevron-right"></i>
                    </a>
                  </div>
                </div>
                {listdata?.length == 0 && (
                  <div className="notfound">
                    <h2>Not Found</h2>
                  </div>
                )}
                <div className="row ps-section__content mt-10">
                  {" "}
                  {listdata &&
                    listdata?.map((data, i) => {
                      console.log(data);
                      return (
                        <div className="col-6 col-sm-3" key={i}>
                          <div className="recentlostfound">
                            <div className="ps-product">
                              <div className="ps-product__thumbnail  ps-product__thumbnailms">
                                <Link
                                  to={
                                    data?.typeof == "Listing"
                                      ? "/listing/" + data?.pro_sulg
                                      : "/product/" + data?.pro_sulg
                                  }
                                >
                                  <CoustomImg
                                    url={URL.API_BASE_URL + data?.featureImage}
                                    altkey={data?.title}
                                    w1={200}
                                    w2={300}
                                    h1={200}
                                    h2={300}
                                  />
                                </Link>
                              </div>
                              <div className="ps-product__container">
                                <div className="ps-product__content">
                                  <Link
                                    className="ps-product__title"
                                    to={
                                      data?.typeof == "Listing"
                                        ? "/listing/" + data?.pro_sulg
                                        : "/product/" + data?.pro_sulg
                                    }
                                  >
                                    <TruncateText
                                      text={data?.title}
                                      maxLength={windowWidth > 600 ? 25 : 15}
                                    />
                                    {/* {data?.title} */}
                                  </Link>

                                  {data?.afterdiscountprice && (
                                    <p className="ps-product__price sale">
                                      ₹{data?.afterdiscountprice}{" "}
                                      <del>₹{data?.price} </del>
                                    </p>
                                  )}
                                  <Link
                                    className="ps-product__vendor"
                                    to={"/vendor-store/" + data?.sellerId?.slug}
                                  >
                                    {data?.sellerId?.displayName}
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container mt-20">
        <nav
          className="d-flex justify-content-between"
          aria-label="Page navigation"
        >
          <ReactPaginate
            breakLabel="..."
            nextLabel="Next"
            onPageChange={handlePageClick}
            className="paging_simple_numbersc pagination"
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="Previous"
            renderOnZeroPageCount={null}
          />
        </nav>
      </div>
      <OurWork />
    </div>
  );
}

export default AllProducts;
