import React, { useEffect, useLayoutEffect, useState } from "react";
import { Image } from "react-bootstrap";
import ReactImageMagnify from "react-image-magnify";
// import Image from "next/image";
// import { URL } from "@/Redux/common/url";
import { useDispatch, useSelector } from "react-redux";
// import { useRouter } from "next/router";
// import { allapiAction } from "@/Redux/common/action";
import Slider from "react-slick";
import { URL } from "../../Redux/common/url";
import { Link, useNavigate, useParams } from "react-router-dom";
import { allapiAction } from "../../Redux/common/action";
import { toast } from "react-toastify";
import CoustomImg from "../CoustomImg/CoustomImg";

import { Button, Col, Input, Modal, Row, Space } from "antd";
import Seo from "../Seo/Seo";
import { Rating } from "react-simple-star-rating";
// import { Button, Input } from "antd";
// import Link from "next/link";
function ListingDetailsPubl({}) {
  //   const data = useParams();
  const dispatch = useDispatch();
  //   const navigate = useNavigate();

  const [post, setpost] = useState({});
  const [reletedProduct, setreletedProduct] = useState([]);

  console.log(post);

  const router = useNavigate();
  const { id } = useParams();
  const data = id;
  const [productImage, setproductImage] = useState([]);
  const [productColor, setproductColor] = useState("");
  const [productSize, setproductSize] = useState({});
  const [productSizeList, setproductSizeList] = useState([]);
  const [shopDetails, setshopDetails] = useState({});
  console.log(post);
  console.log(reletedProduct);
  const recentProduct = reletedProduct ? reletedProduct : [];

  const productDetais = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      slug: id,
    });

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      // body: raw,
      redirect: "follow",
    };

    const bgl = await fetch(
      URL.API_BASE_URL + `${URL.ListingstDetails}/${id}`,
      requestOptions
    );
    const bg2 = await fetch(
      URL.API_BASE_URL + `${URL.ListingsDetailsReleted}/${id}`,
      requestOptions
    );

    const bg4 = await fetch(
      URL.API_BASE_URL + `${URL.ListingShopDetails}/${id}`,
      requestOptions
    );
    const events = await bgl.json();
    const events2 = await bg2.json();
    const events4 = await bg4.json();
    // const postData = getPostData(slug);
    const postData = events?.data;
    const postData2 = events2?.data;
    const postData4 = events4?.data;
    setpost(postData);
    setreletedProduct(postData2);
    setshopDetails(postData4);
    // if (!events) {
    //   return { notFound: true };
    // }

    // return {
    //   props: {
    //     post: postData,
    //     reletedProduct: postData2,
    //     // postList: postDataList,
    //   },
    // };
  };

  useEffect(() => {
    productDetais();

    return () => {};
  }, [id]);

  var settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToShow:
      recentProduct?.length == 1
        ? 1
        : recentProduct?.length > 6
          ? 6
          : recentProduct?.length - 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow:
            recentProduct?.length == 1
              ? 1
              : recentProduct?.length > 4
                ? 4
                : recentProduct?.length - 1,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow:
            recentProduct?.length == 1
              ? 1
              : recentProduct?.length > 3
                ? 3
                : recentProduct?.length - 1,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow:
            recentProduct?.length == 1
              ? 1
              : recentProduct?.length > 2
                ? 2
                : recentProduct?.length - 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const hendleToAddItme = (e, id) => {
    // setactiveCate(e);

    if (productSize?.stock > 0) {
      dispatch(allapiAction.AddCartItme(e, productColor, productSize?._id));
    } else {
      toast.error("stock not available");
    }
  };

  let pageUrl;

  if (typeof window !== "undefined") {
    if (window.location !== undefined) {
      // Outputs: 'https:'

      // Outputs: '/blog/react-get-current-url/'

      pageUrl = window.location.href;
    }
  }

  const [open, setOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  function copy() {
    const el = document.createElement("input");
    el.value = pageUrl;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    // setCopied(true);
    toast.success("Copied!");
  }

  const get_product_details = post;

  // console.log(get_product_details);

  const get_product_detailsm = useSelector((state) =>
    state?.allapi?.get_product_details ? state?.allapi?.get_product_details : {}
  );

  const [dispImg, setDispImg] = useState("");

  useEffect(() => {
    // dispatch(allapiAction.productDetails(id));
    // dispatch(allapiAction.getsubsubcatehome({}));
    // return () => {};
  }, [id]);
  // console.log(get_product_details?.subcolor);
  useEffect(() => {
    const feImg = URL.API_BASE_URL + get_product_details?.featureImage;

    // setproductColor(get_product_details?.subcolor[0]?._id);
    // setproductSize(get_product_details?.subcolor[0]?.productsize[0]);
    // setproductSizeList(get_product_details?.subcolor[0]?.productsize);

    const allImg = get_product_details?.images?.map((data) => {
      return URL.API_BASE_URL + data?.img;
    });

    // console.log(allImg);

    // if (get_product_details?.images !== undefined) {
    const allImgs = allImg?.filter((data) => {
      return !data.match("undefined");
    });

    // const allimgMarge = [feImg, ...allImgs];

    if (allImg) {
      setDispImg(allImg[0]);
      setproductImage(allImg);
    }

    // }
  }, [get_product_details]);

  const [showWhatsappIn, setShowWhatsappIn] = useState(false);
  const handleCloseWhatsappIn = () => setShowWhatsappIn(false);
  const handleOpneWhatsappIn = () => setShowWhatsappIn(true);

  const [showEmailIn, setShowEmailIn] = useState(false);
  const handleCloseEmailIn = () => setShowEmailIn(false);
  const handleOpneEmailIn = () => setShowEmailIn(true);

  const [whatInq, setwhatInq] = useState({ countryCode: "", whatsappNo: "" });
  const [emailInq, setemailInq] = useState({
    country: "",
    customerEmail: "",
    userName: "",
  });

  const hendleToWhatsapp = (e) => {
    const { name, value } = e.target;
    setwhatInq({ ...whatInq, [name]: value });
  };

  const hendleToEmail = (e) => {
    const { name, value } = e.target;
    setemailInq({ ...emailInq, [name]: value });
  };

  // start

  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode1, setCountryCode1] = useState("");
  const [phoneNumber1, setPhoneNumber1] = useState("");

  const [phoneNumber2, setPhoneNumber2] = useState("");
  const [countryCode12, setCountryCode12] = useState("");
  const [phoneNumber12, setPhoneNumber12] = useState("");
  //   const handlePhoneChange = (value) => {
  //     const phone = value || "";
  //     const phoneNumberObj = parsePhoneNumberFromString(phone);
  //     const conCode = phoneNumberObj ? phoneNumberObj.countryCallingCode : "";
  //     const contry = phoneNumberObj ? phoneNumberObj.nationalNumber : "";
  //     setCountryCode1(conCode);
  //     setPhoneNumber1(contry);
  //     setPhoneNumber(value);
  //   };

  //   const handlePhoneChange2 = (value) => {
  //     const phone = value || "";
  //     const phoneNumberObj = parsePhoneNumberFromString(phone);
  //     const conCode = phoneNumberObj ? phoneNumberObj.countryCallingCode : "";
  //     const contry = phoneNumberObj ? phoneNumberObj.nationalNumber : "";
  //     setCountryCode12(conCode);
  //     setPhoneNumber12(contry);
  //     setPhoneNumber2(value);
  //   };

  useEffect(() => {
    // const blogIds = localStorage.getItem("access_name");
    // const blogIdss = blogIds !== null ? blogIds : "{}";
    // const arr = JSON?.parse(blogIdss);
    // const blogIdsnum = localStorage.getItem("access_number");
    // const blogIdssnum = blogIdsnum !== null ? blogIdsnum : "{}";
    // const arrnum = JSON.parse(blogIdssnum);
    // setPhoneNumber("+" + arrnum?.countryCode + arrnum?.whatsappNo);
    // setPhoneNumber2("+" + arr?.countryCode + arr?.whatsappNo);
    // setCountryCode1(arrnum?.countryCode);
    // setPhoneNumber1(arrnum?.whatsappNo);
    // setCountryCode12(arr?.countryCode);
    // setPhoneNumber12(arr?.whatsappNo);
    // setwhatInq({
    //   name: arrnum?.name,
    //   email: arrnum?.email,
    // });
    // setemailInq({
    //   // message: arr?.message,
    //   customerEmail: arr?.Email,
    //   userName: arr?.Name,
    // });
  }, []);

  const urltet = URL.API_BASE_URL_2 + "/product-details/";

  const feImg = URL.API_BASE_URL + get_product_details?.featureImage;

  const [value, setValue] = useState("");

  useEffect(() => {
    if (typeof window !== "undefined") {
      setValue(localStorage.getItem("access_token"));
    } else {
      setValue("");
    }
  }, []);

  const [windowWidth, setWindowWidth] = useState(600);

  // Update window width when the window is resized
  useLayoutEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }
    setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Determine the width and height based on screen width
  const imageWidth = windowWidth <= 767 ? 150 : 300;
  const imageHeight = windowWidth <= 767 ? 150 : 300;

  const [statusDataBoxReturn, setstatusDataBoxReturn] = useState(false);
  const [enquiryData, setenquiryData] = useState({});

  const onChangeData = (e) => {
    setenquiryData({ ...enquiryData, [e.target.name]: e.target.value });
  };
  const hendletoSubmitEnquiry = async () => {
    if (!enquiryData?.name) {
      toast?.error("please enter your name");
      return;
    }
    if (!enquiryData?.number) {
      toast?.error("please enter your number");
      return;
    }
    if (!enquiryData?.address) {
      toast?.error("please enter your address");
      return;
    }

    const response = await dispatch(
      allapiAction.emailenquirysubmit({
        sellerId: get_product_details?.sellerId?._id,
        sellerName: get_product_details?.sellerId?.displayName,
        productId: get_product_details?._id,
        productName: get_product_details?.title,
        userName: enquiryData?.name,
        address: enquiryData?.address,
        // country,
        // countryCode,
        whatsappNo: enquiryData?.number,
        customerEmail: enquiryData?.email,
        subject: "Inquiry From Product",
        // sellerEmail,
        // query,
      })
    );

    console.log(response);
    if (response?.success) {
      setstatusDataBoxReturn(false);
      setenquiryData({ name: "", email: "", number: "", address: "" });
    }
  };

  const [reviewBox, setreviewBox] = useState("0");
  const [ReviewText, setReviewText] = useState("");
  const [rating, setRating] = useState(0);
  const [ReviewList, setReviewList] = useState([]);
  console.log(rating);
  console.log(ReviewList);

  // Catch Rating value
  const handleRating = (rate) => {
    setRating(rate);

    // other logic
  };
  // Optinal callback functions
  const onPointerEnter = () => console.log("Enter");
  const onPointerLeave = () => console.log("Leave");
  const onPointerMove = (value, index) => console.log(value, index);

  const hendleToSubmit = async (e) => {
    // submitReview
    const response = await dispatch(
      allapiAction.submitReview({
        rating: rating,
        discription: ReviewText,
        sellerId: post?.sellerId?._id,
        mainProductId: post?._id,
        productId: post?._id,
        type: "listing",
      })
    );
    hendletoGetReviews();
    productDetais();
  };

  console.log(post?.rating);

  useEffect(() => {
    hendletoGetReviews();
    return () => {};
  }, []);

  const hendletoGetReviews = async () => {
    // reviewListpub
    const response = await dispatch(
      allapiAction.reviewListpub({
        page: 1,
        productId: id,
        type: "product",
      })
    );
    console.log(response?.data);

    if (response?.data?.docs?.length > 0) {
      setReviewList(response?.data?.docs);
    }
  };

  return (
    <div className="overflowhidn">
      <div></div>
      <Seo
        settitle={get_product_details?.title}
        titleSeo={get_product_details?.seo_title}
        seoKeyword={
          "Best eCommerce platform India, Affordable online shopping India, Vendor product marketplace, Buy and sell products online India, Online shopping website India, Best Bharat eCommerce portal, Small business eCommerce India, Local shopping platform India, Product comparison website India, Shop services online in India"
        }
        seo_descriptions={get_product_details?.seo_description}
        seoconcal={"https://bestbharat.com/"}
        title={get_product_details?.title}
      />
      <Modal
        title="Enquiry Now !!"
        centered
        open={statusDataBoxReturn}
        onOk={() => hendletoSubmitEnquiry(false)}
        onCancel={() => setstatusDataBoxReturn(false)}
        width={500}
      >
        <div>
          <Row>
            <Col span={24}>Product Name</Col>
            <Col span={24}>
              <Input
                name="name"
                placeholder="enter product name..."
                value={get_product_details?.title}
                // onChange={(e) => {
                //   onChangeData(e);
                // }}
              />
            </Col>
            <Col span={24}>Name</Col>
            <Col span={24}>
              <Input
                name="name"
                placeholder="enter name..."
                value={enquiryData?.name}
                onChange={(e) => {
                  onChangeData(e);
                }}
              />
            </Col>
            <Col span={24}>Number</Col>
            <Col span={24}>
              <Input
                name="number"
                placeholder="enter number..."
                value={enquiryData?.number}
                onChange={(e) => {
                  onChangeData(e);
                }}
              />
            </Col>
            <Col span={24}>Email</Col>
            <Col span={24}>
              <Input
                name="email"
                placeholder="enter email..."
                value={enquiryData?.email}
                onChange={(e) => {
                  onChangeData(e);
                }}
              />
            </Col>
            <Col span={24}>Address</Col>
            <Col span={24}>
              <Input
                name="address"
                placeholder="enter address..."
                value={enquiryData?.address}
                onChange={(e) => {
                  onChangeData(e);
                }}
              />
            </Col>
          </Row>
        </div>
      </Modal>

      <div className="ps-page--product p-25">
        <div className="ps-container">
          <div className="ps-page__container">
            <div className="ps-page__left">
              <div className="ps-product--detail ps-product--fullwidth">
                <div className="ps-product__header">
                  <div className="col-12 col-sm-5" data-vertical="true">
                    <div className="productPage__left">
                      <div className="productPage__displayImageContainer">
                        <div
                          className="productPage__displayImage"
                          // style={{
                          //   backgroundImage: `url(${
                          //     URL.API_BASE_URL + dispImg
                          //   })`,
                          // }}
                        >
                          {/* <div style={{ width: "250px" }}>
                            <img
                              src={dispImg}
                              alt=""
                              style={{ width: "100%" }}
                            />
                          </div> */}

                          <ReactImageMagnify
                            {...{
                              smallImage: {
                                alt: "Wristwatch by Ted Baker London",
                                isFluidWidth: true,
                                src: dispImg,
                                // sizes:
                                //   "(max-width: 480px) 100vw, (max-width: 1200px) 30vw, 360px",
                              },
                              largeImage: {
                                src: dispImg,
                                width: 1200,
                                height: 1800,
                              },

                              imageClassName: "imgViewS1",

                              enlargedImageStyle: {
                                width: "50px",
                              },
                              lensStyle: {
                                width: "20px",
                                height: "20px",
                              },
                              shouldUsePositiveSpaceLens: true,
                              shouldHideHintAfterFirstActivation: false,
                              // isHintEnabled: true,
                              lensStyle: {
                                backgroundColor: "rgba(0,0,0,.6)",
                              },
                              enlargedImagePosition: "over",
                            }}
                          />
                        </div>
                      </div>
                      <div className="productPage__sideImagesContainer">
                        {productImage &&
                          productImage?.map((item, index) => {
                            return (
                              <div
                                key={index}
                                onClick={() => setDispImg(item)}
                                className={
                                  dispImg == item
                                    ? "sideImageActive"
                                    : "sideImage "
                                }
                              >
                                <Image
                                  src={item}
                                  alt={"ract"}
                                  width={300}
                                  height={250}
                                  // onError={(e) => {
                                  //   e.target.onerror = null;
                                  //   e.target.src =
                                  //     "../assets/imgs/notfound.png";
                                  // }}
                                />
                              </div>
                            );
                          })}
                      </div>
                    </div>
                    {/* <ReactImageMagnify
                      {...{
                        smallImage: {
                          alt: "Wristwatch by Ted Baker London",
                          isFluidWidth: true,
                          src: dispImg,
                          // sizes:
                          //   "(max-width: 480px) 100vw, (max-width: 1200px) 30vw, 360px",
                        },
                        largeImage: {
                          src: dispImg,
                          width: 1200,
                          height: 1800,
                        },

                        imageClassName: "imgViewS1",

                        enlargedImageStyle: {
                          width: "50px",
                        },
                        lensStyle: {
                          width: "20px",
                          height: "20px",
                        },
                        shouldUsePositiveSpaceLens: true,
                        shouldHideHintAfterFirstActivation: false,
                        // isHintEnabled: true,
                        lensStyle: {
                          backgroundColor: "rgba(0,0,0,.6)",
                        },
                        enlargedImagePosition: "over",
                      }}
                    /> */}
                    {/* <figure>
                      <div className="ps-wrapper">
                        <div className="ps-product__gallery" data-arrow="true">
                          <div className="item">
                            <a href="/img/products/detail/fullwidth/1.jpg">
                              <img
                                src="/img/products/detail/fullwidth/1.jpg"
                                alt=""
                              />
                            </a>
                          </div>
                          <div className="item">
                            <a href="/img/products/detail/fullwidth/2.jpg">
                              <img
                                src="/img/products/detail/fullwidth/2.jpg"
                                alt=""
                              />
                            </a>
                          </div>
                          <div className="item">
                            <a href="/img/products/detail/fullwidth/3.jpg">
                              <img
                                src="/img/products/detail/fullwidth/3.jpg"
                                alt=""
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </figure>
                    <div
                      className="ps-product__variants"
                      data-item="4"
                      data-md="4"
                      data-sm="4"
                      data-arrow="false"
                    >
                      <div className="item">
                        <img
                          src="/img/products/detail/fullwidth/1.jpg"
                          alt=""
                        />
                      </div>
                      <div className="item">
                        <img
                          src="/img/products/detail/fullwidth/2.jpg"
                          alt=""
                        />
                      </div>
                      <div className="item">
                        <img
                          src="/img/products/detail/fullwidth/3.jpg"
                          alt=""
                        />
                      </div>
                    </div> */}
                  </div>
                  <div className="ps-product__info">
                    <h1>{get_product_details?.title}</h1>
                    {/* <div className="ps-product__meta">
                      <p>
                        Brand:<a href="shop-default.html">Sony</a>
                      </p>
                      <div className="ps-product__rating">
                        <select className="ps-rating" data-read-only="true">
                          <option value="1">1</option>
                          <option value="1">2</option>
                          <option value="1">3</option>
                          <option value="1">4</option>
                          <option value="2">5</option>
                        </select>
                        <span>(1 review)</span>
                      </div>
                    </div> */}
                    {productSize?.afterdiscountprice && productSize?.price && (
                      <h4 className="ps-product__price">
                        ₹{productSize?.afterdiscountprice} – ₹
                        <del>{productSize?.price}</del>
                      </h4>
                    )}

                    <div className="ps-product__shopping">
                      {/* <figure>
                        <figcaption>Quantity</figcaption>
                        <div className="form-group--number">
                          <button className="up">
                            <i className="fa fa-plus"></i>
                          </button>
                          <button className="down">
                            <i className="fa fa-minus"></i>
                          </button>
                          <input
                            className="form-control"
                            type="text"
                            placeholder="1"
                          />
                        </div>
                      </figure> */}
                      {/* <a
                        className="ps-btn ps-btn--black"
                        href="#"
                        onClick={() => {
                          hendleToAddItme(get_product_details?._id);
                        }}
                      >
                        Add to cart
                      </a> */}
                      {/* <a className="ps-btn" href="#">
                        Buy Now
                      </a>
                      <div className="ps-product__actions">
                        <a href="#">
                          <i className="icon-heart"></i>
                        </a>
                        <a href="#">
                          <i className="icon-chart-bars"></i>
                        </a>
                      </div> */}
                    </div>

                    <div className="ps-product__specification">
                      <table>
                        <thead></thead>
                        <tbody>
                          <tr>
                            <th>
                              <b>Raiting</b>
                            </th>
                            <th>
                              {" "}
                              <Rating
                                readonly={true}
                                allowFraction={true}
                                transition
                                initialValue={
                                  get_product_details?.rating
                                    ? get_product_details?.rating
                                    : 0
                                }
                                /* Available Props */
                              />
                            </th>
                          </tr>
                          <tr>
                            <th>
                              <b>Categories</b>
                            </th>
                            <th> {get_product_details?.categoryId?.name}</th>
                          </tr>
                          <tr>
                            <th>
                              <b>Brands</b>
                            </th>
                            <th>
                              {" "}
                              {get_product_details?.brand2
                                ? get_product_details?.brand2
                                : get_product_details?.brandId?.name}
                            </th>
                          </tr>

                          <tr>
                            <th>
                              <b>SKU</b>
                            </th>
                            <th>
                              {" "}
                              {get_product_details?.sku
                                ? get_product_details?.sku
                                : "--"}
                            </th>
                          </tr>
                          <tr>
                            <th>
                              <b>Vendor</b>
                            </th>
                            <th
                              onClick={() => {
                                router("/vendor-store/" + shopDetails?.slug);
                              }}
                              style={{ color: "rgb(127, 9, 127)" }}
                            >
                              {" "}
                              <b>{shopDetails?.displayName}</b>
                            </th>
                          </tr>
                        </tbody>
                      </table>
                      {/* <a className="report" href="#">
                        Report Abuse
                      </a>
                      <p>
                        <strong>SKU:</strong> SF1133569600-1
                      </p>
                      <p className="categories">
                        <strong> Categories:</strong>
                        <a href="#">
                          {get_product_details?.categoryDetails?.[0]?.name}
                        </a> */}
                      {/* , */}
                      {/* <a href="#"> Refrigerator</a>,
                        <a href="#">Babies & Moms</a> */}
                      {/* </p> */}
                      {/* <p className="tags">
                        <strong> Tags</strong>
                        <a href="#">sofa</a>,<a href="#">technologies</a>,
                        <a href="#">wireless</a>
                      </p> */}
                    </div>

                    <div className="ps-product__shopping">
                      <a
                        className="ps-btn ps-btn--black"
                        href="#"
                        onClick={() => {
                          setstatusDataBoxReturn(true);
                        }}
                      >
                        Enquiry
                      </a>
                    </div>
                    {/* <div className="ps-product__sharing">
                      <a className="facebook" href="#">
                        <i className="fa fa-facebook"></i>
                      </a>
                      <a className="twitter" href="#">
                        <i className="fa fa-twitter"></i>
                      </a>
                      <a className="google" href="#">
                        <i className="fa fa-google-plus"></i>
                      </a>
                      <a className="linkedin" href="#">
                        <i className="fa fa-linkedin"></i>
                      </a>
                      <a className="instagram" href="#">
                        <i className="fa fa-instagram"></i>
                      </a>
                    </div> */}
                  </div>
                </div>
                <div className="ps-product__content ps-tab-root">
                  <ul className="ps-tab-list">
                    {/* <li className="active">
                      <a href="#tab-1">Description</a>
                    </li> */}
                    <li
                      className={reviewBox == "1" ? "" : "active"}
                      onClick={() => {
                        setreviewBox("0");
                      }}
                    >
                      <a href="#tab-1">Description</a>
                    </li>
                    <li
                      className={reviewBox == "1" ? "active" : ""}
                      onClick={() => {
                        setreviewBox("1");
                      }}
                    >
                      <a href="#tab-1">Review</a>
                    </li>
                    {/* <li>
                      <a href="#tab-2">Specification</a>
                    </li>
                    <li>
                      <a href="#tab-3">Vendor</a>
                    </li>
                    <li>
                      <a href="#tab-4">Reviews (1)</a>
                    </li>
                    <li>
                      <a href="#tab-5">Questions and Answers</a>
                    </li>
                    <li>
                      <a href="#tab-6">More Offers</a>
                    </li> */}
                  </ul>
                  <div className="ps-tabs">
                    {/* <div className="ps-tab active" id="tab-1">
                      <div className="ps-document">
                        <div
                          className=""
                          dangerouslySetInnerHTML={{
                            __html: get_product_details?.description,
                          }}
                        ></div>
                     
                      </div>
                    </div> */}
                    {reviewBox == "0" && (
                      <div className="ps-tab active" id="tab-1">
                        <div className="ps-document">
                          <div
                            className=""
                            dangerouslySetInnerHTML={{
                              __html: get_product_details?.description,
                            }}
                          ></div>
                        </div>
                      </div>
                    )}
                    {reviewBox == "1" && (
                      <div className="ps-tab active" id="tab-2">
                        <div className="ps-document">
                          <div className="">
                            <div className="review-input-section">
                              <h5>Leave a Review</h5>

                              <div className="row">
                                <div className="col-sm-5">
                                  <Rating
                                    onClick={handleRating}
                                    onPointerEnter={onPointerEnter}
                                    onPointerLeave={onPointerLeave}
                                    onPointerMove={onPointerMove}
                                    transition
                                    allowFraction={true}
                                    initialRating={rating}
                                    /* Available Props */
                                  />

                                  <Input.TextArea
                                    rows={4}
                                    placeholder="Write your review here..."
                                    onChange={(e) =>
                                      setReviewText(e.target.value)
                                    }
                                  />
                                  <Button
                                    className="mt-2"
                                    type="primary"
                                    onClick={() => hendleToSubmit()}
                                  >
                                    Submit
                                  </Button>
                                </div>
                                <div className="col-12">
                                  <div>
                                    <div className="review-list mt-20">
                                      {ReviewList.map((review, index) => (
                                        <div
                                          key={index}
                                          className="review-item shadow-sm p-3 mb-3 bg-white rounded border p-10"
                                        >
                                          <div className="d-flex justify-content-between">
                                            <h6 className="font-weight-bold text-primary">
                                              {review.userId?.fullName}
                                            </h6>
                                            <Rating
                                              readonly
                                              allowFraction={true}
                                              initialValue={review.rating}
                                              className="text-warning"
                                            />
                                          </div>
                                          <p className="mt-2 text-muted">
                                            {review.discription}
                                          </p>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="ps-page__right">
              <aside className="widget widget_product widget_features">
                <div className="row">
                  <div className="col-12">
                    <img
                      width={100}
                      src={URL?.API_BASE_URL + shopDetails?.sellerProfile}
                      alt=""
                    />
                  </div>
                  <div className="col-12">
                    <h3>{shopDetails?.displayName} </h3>
                    <span className="mt-5">
                      {shopDetails?.city}, {shopDetails?.district},{" "}
                      {shopDetails?.state}, {shopDetails?.zip}
                    </span>
                  </div>
                  <div className="col-6">
                    <div
                      style={{
                        backgroundColor: "#7f097f1f",
                        textAlign: "center",
                        height: "80px",
                        paddingTop: "10px",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "18px",
                          fontWeight: "700",
                          color: "#7f097f",
                        }}
                      >
                        Followers{" "}
                      </span>
                      <br />
                      <span> {shopDetails?.follower}</span>
                      {/* <p>Likes: 50k</p> */}
                    </div>
                  </div>
                  <div className="col-6">
                    <div
                      style={{
                        backgroundColor: "#7f097f1f",
                        textAlign: "center",
                        height: "80px",
                        paddingTop: "10px",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "18px",
                          fontWeight: "700",
                          color: "#7f097f",
                        }}
                      >
                        Likes{" "}
                      </span>
                      <br />
                      <span> {shopDetails?.like}</span>
                      {/* <p>Likes: 50k</p> */}
                    </div>
                  </div>

                  {/* <div className="col-6">r</div> */}
                  <div className="col-12">
                    <button
                      style={{
                        width: "100%",
                        backgroundColor: "#7f097f",
                        color: "#fff",
                        border: "none",
                        padding: "10px 0",
                        fontSize: "18px",
                        fontWeight: "700",
                        borderRadius: "0",
                        marginTop: "10px",
                        transition: "background-color 0.3s, color 0.3s",
                      }}
                      onClick={() => {
                        router("/vendor-store/" + shopDetails?.slug);
                      }}
                    >
                      <i
                        className="fa fa-cart-arrow-down icon-bag2"
                        style={{ color: "#ffffff" }}
                      ></i>{" "}
                      Visit Store
                    </button>
                  </div>
                </div>
                {/* <p>
                  <i className="icon-network"></i> Shipping worldwide
                </p>
                <p>
                  <i className="icon-3d-rotate"></i> Free 7-day return if
                  eligible, so easy
                </p>
                <p>
                  <i className="icon-receipt"></i> Supplier give bills for this
                  product.
                </p>
                <p>
                  <i className="icon-credit-card"></i> Pay online or when
                  receiving goods
                </p> */}
              </aside>
              {/* <aside className="widget widget_product widget_features">
                <p>
                  <i className="icon-network"></i> Shipping worldwide
                </p>
                <p>
                  <i className="icon-3d-rotate"></i> Free 7-day return if
                  eligible, so easy
                </p>
                <p>
                  <i className="icon-receipt"></i> Supplier give bills for this
                  product.
                </p>
                <p>
                  <i className="icon-credit-card"></i> Pay online or when
                  receiving goods
                </p>
              </aside>
              <aside className="widget widget_sell-on-site">
                <p>
                  <i className="icon-store"></i> Sell on Martfury?
                  <a href="#"> Register Now !</a>
                </p>
              </aside>
              <aside className="widget widget_ads">
                <a href="#">
                  <img src="/img/ads/product-ads.png" alt="" />
                </a>
              </aside> */}
              {/* <aside className="widget widget_same-brand">
                <h3>Same Brand</h3>
                <div className="widget__content">
                  <div className="ps-product">
                    <div className="ps-product__thumbnail">
                      <a href="product-default.html">
                        <img src="/img/products/shop/5.jpg" alt="" />
                      </a>
                      <div className="ps-product__badge">-37%</div>
                      <ul className="ps-product__actions">
                        <li>
                          <a
                            href="#"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Add To Cart"
                          >
                            <i className="icon-bag2"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            data-placement="top"
                            title="Quick View"
                            data-toggle="modal"
                            data-target="#product-quickview"
                          >
                            <i className="icon-eye"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Add to Whishlist"
                          >
                            <i className="icon-heart"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Compare"
                          >
                            <i className="icon-chart-bars"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="ps-product__container">
                      <a className="ps-product__vendor" href="#">
                        Robert&apos;s Store
                      </a>
                      <div className="ps-product__content">
                        <a
                          className="ps-product__title"
                          href="product-default.html"
                        >
                          Grand Slam Indoor Of Show Jumping Novel
                        </a>
                        <div className="ps-product__rating">
                          <select className="ps-rating" data-read-only="true">
                            <option value="1">1</option>
                            <option value="1">2</option>
                            <option value="1">3</option>
                            <option value="1">4</option>
                            <option value="2">5</option>
                          </select>
                          <span>01</span>
                        </div>
                        <p className="ps-product__price sale">
                          $32.99 <del>$41.00 </del>
                        </p>
                      </div>
                      <div className="ps-product__content hover">
                        <a
                          className="ps-product__title"
                          href="product-default.html"
                        >
                          Grand Slam Indoor Of Show Jumping Novel
                        </a>
                        <p className="ps-product__price sale">
                          $32.99 <del>$41.00 </del>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="ps-product">
                    <div className="ps-product__thumbnail">
                      <a href="product-default.html">
                        <img src="/img/products/shop/6.jpg" alt="" />
                      </a>
                      <div className="ps-product__badge">-5%</div>
                      <ul className="ps-product__actions">
                        <li>
                          <a
                            href="#"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Add To Cart"
                          >
                            <i className="icon-bag2"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            data-placement="top"
                            title="Quick View"
                            data-toggle="modal"
                            data-target="#product-quickview"
                          >
                            <i className="icon-eye"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Add to Whishlist"
                          >
                            <i className="icon-heart"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Compare"
                          >
                            <i className="icon-chart-bars"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="ps-product__container">
                      <a className="ps-product__vendor" href="#">
                        Youngshop
                      </a>
                      <div className="ps-product__content">
                        <a
                          className="ps-product__title"
                          href="product-default.html"
                        >
                          Sound Intone I65 Earphone White Version
                        </a>
                        <div className="ps-product__rating">
                          <select className="ps-rating" data-read-only="true">
                            <option value="1">1</option>
                            <option value="1">2</option>
                            <option value="1">3</option>
                            <option value="1">4</option>
                            <option value="2">5</option>
                          </select>
                          <span>01</span>
                        </div>
                        <p className="ps-product__price sale">
                          $100.99 <del>$106.00 </del>
                        </p>
                      </div>
                      <div className="ps-product__content hover">
                        <a
                          className="ps-product__title"
                          href="product-default.html"
                        >
                          Sound Intone I65 Earphone White Version
                        </a>
                        <p className="ps-product__price sale">
                          $100.99 <del>$106.00 </del>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </aside> */}
            </div>
          </div>
          {/* <div className="ps-section--default ps-customer-bought">
            <div className="ps-section__header">
              <h3>Customers who bought this item also bought</h3>
            </div>
            <div className="ps-section__content">
              <div className="row">
                <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-6 ">
                  <div className="ps-product">
                    <div className="ps-product__thumbnail">
                      <a href="product-default.html">
                        <img src="/img/products/shop/4.jpg" alt="" />
                      </a>
                      <div className="ps-product__badge hot">hot</div>
                      <ul className="ps-product__actions">
                        <li>
                          <a
                            href="#"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Add To Cart"
                          >
                            <i className="icon-bag2"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            data-placement="top"
                            title="Quick View"
                            data-toggle="modal"
                            data-target="#product-quickview"
                          >
                            <i className="icon-eye"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Add to Whishlist"
                          >
                            <i className="icon-heart"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Compare"
                          >
                            <i className="icon-chart-bars"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="ps-product__container">
                      <a className="ps-product__vendor" href="#">
                        Global Office
                      </a>
                      <div className="ps-product__content">
                        <a
                          className="ps-product__title"
                          href="product-default.html"
                        >
                          Xbox One Wireless Controller Black Color
                        </a>
                        <div className="ps-product__rating">
                          <select className="ps-rating" data-read-only="true">
                            <option value="1">1</option>
                            <option value="1">2</option>
                            <option value="1">3</option>
                            <option value="1">4</option>
                            <option value="2">5</option>
                          </select>
                          <span>01</span>
                        </div>
                        <p className="ps-product__price">$55.99</p>
                      </div>
                      <div className="ps-product__content hover">
                        <a
                          className="ps-product__title"
                          href="product-default.html"
                        >
                          Xbox One Wireless Controller Black Color
                        </a>
                        <p className="ps-product__price">$55.99</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-6 ">
                  <div className="ps-product">
                    <div className="ps-product__thumbnail">
                      <a href="product-default.html">
                        <img src="/img/products/shop/5.jpg" alt="" />
                      </a>
                      <div className="ps-product__badge">-37%</div>
                      <ul className="ps-product__actions">
                        <li>
                          <a
                            href="#"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Add To Cart"
                          >
                            <i className="icon-bag2"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            data-placement="top"
                            title="Quick View"
                            data-toggle="modal"
                            data-target="#product-quickview"
                          >
                            <i className="icon-eye"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Add to Whishlist"
                          >
                            <i className="icon-heart"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Compare"
                          >
                            <i className="icon-chart-bars"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="ps-product__container">
                      <a className="ps-product__vendor" href="#">
                        Robert's Store
                      </a>
                      <div className="ps-product__content">
                        <a
                          className="ps-product__title"
                          href="product-default.html"
                        >
                          Grand Slam Indoor Of Show Jumping Novel
                        </a>
                        <div className="ps-product__rating">
                          <select className="ps-rating" data-read-only="true">
                            <option value="1">1</option>
                            <option value="1">2</option>
                            <option value="1">3</option>
                            <option value="1">4</option>
                            <option value="2">5</option>
                          </select>
                          <span>01</span>
                        </div>
                        <p className="ps-product__price sale">
                          $32.99 <del>$41.00 </del>
                        </p>
                      </div>
                      <div className="ps-product__content hover">
                        <a
                          className="ps-product__title"
                          href="product-default.html"
                        >
                          Grand Slam Indoor Of Show Jumping Novel
                        </a>
                        <p className="ps-product__price sale">
                          $32.99 <del>$41.00 </del>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-6 ">
                  <div className="ps-product">
                    <div className="ps-product__thumbnail">
                      <a href="product-default.html">
                        <img src="/img/products/shop/6.jpg" alt="" />
                      </a>
                      <div className="ps-product__badge">-5%</div>
                      <ul className="ps-product__actions">
                        <li>
                          <a
                            href="#"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Add To Cart"
                          >
                            <i className="icon-bag2"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            data-placement="top"
                            title="Quick View"
                            data-toggle="modal"
                            data-target="#product-quickview"
                          >
                            <i className="icon-eye"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Add to Whishlist"
                          >
                            <i className="icon-heart"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Compare"
                          >
                            <i className="icon-chart-bars"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="ps-product__container">
                      <a className="ps-product__vendor" href="#">
                        Youngshop
                      </a>
                      <div className="ps-product__content">
                        <a
                          className="ps-product__title"
                          href="product-default.html"
                        >
                          Sound Intone I65 Earphone White Version
                        </a>
                        <div className="ps-product__rating">
                          <select className="ps-rating" data-read-only="true">
                            <option value="1">1</option>
                            <option value="1">2</option>
                            <option value="1">3</option>
                            <option value="1">4</option>
                            <option value="2">5</option>
                          </select>
                          <span>01</span>
                        </div>
                        <p className="ps-product__price sale">
                          $100.99 <del>$106.00 </del>
                        </p>
                      </div>
                      <div className="ps-product__content hover">
                        <a
                          className="ps-product__title"
                          href="product-default.html"
                        >
                          Sound Intone I65 Earphone White Version
                        </a>
                        <p className="ps-product__price sale">
                          $100.99 <del>$106.00 </del>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-6 ">
                  <div className="ps-product">
                    <div className="ps-product__thumbnail">
                      <a href="product-default.html">
                        <img src="/img/products/shop/7.jpg" alt="" />
                      </a>
                      <div className="ps-product__badge">-16%</div>
                      <ul className="ps-product__actions">
                        <li>
                          <a
                            href="#"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Add To Cart"
                          >
                            <i className="icon-bag2"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            data-placement="top"
                            title="Quick View"
                            data-toggle="modal"
                            data-target="#product-quickview"
                          >
                            <i className="icon-eye"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Add to Whishlist"
                          >
                            <i className="icon-heart"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Compare"
                          >
                            <i className="icon-chart-bars"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="ps-product__container">
                      <a className="ps-product__vendor" href="#">
                        Youngshop
                      </a>
                      <div className="ps-product__content">
                        <a
                          className="ps-product__title"
                          href="product-default.html"
                        >
                          Korea Long Sofa Fabric In Blue Navy Color
                        </a>
                        <div className="ps-product__rating">
                          <select className="ps-rating" data-read-only="true">
                            <option value="1">1</option>
                            <option value="1">2</option>
                            <option value="1">3</option>
                            <option value="1">4</option>
                            <option value="2">5</option>
                          </select>
                          <span>01</span>
                        </div>
                        <p className="ps-product__price sale">
                          $567.89 <del>$670.20 </del>
                        </p>
                      </div>
                      <div className="ps-product__content hover">
                        <a
                          className="ps-product__title"
                          href="product-default.html"
                        >
                          Korea Long Sofa Fabric In Blue Navy Color
                        </a>
                        <p className="ps-product__price sale">
                          $567.89 <del>$670.20 </del>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-6 ">
                  <div className="ps-product">
                    <div className="ps-product__thumbnail">
                      <a href="product-default.html">
                        <img src="/img/products/shop/8.jpg" alt="" />
                      </a>
                      <div className="ps-product__badge">-16%</div>
                      <ul className="ps-product__actions">
                        <li>
                          <a
                            href="#"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Add To Cart"
                          >
                            <i className="icon-bag2"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            data-placement="top"
                            title="Quick View"
                            data-toggle="modal"
                            data-target="#product-quickview"
                          >
                            <i className="icon-eye"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Add to Whishlist"
                          >
                            <i className="icon-heart"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Compare"
                          >
                            <i className="icon-chart-bars"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="ps-product__container">
                      <a className="ps-product__vendor" href="#">
                        Young shop
                      </a>
                      <div className="ps-product__content">
                        <a
                          className="ps-product__title"
                          href="product-default.html"
                        >
                          Unero Military Classical Backpack
                        </a>
                        <div className="ps-product__rating">
                          <select className="ps-rating" data-read-only="true">
                            <option value="1">1</option>
                            <option value="1">2</option>
                            <option value="1">3</option>
                            <option value="1">4</option>
                            <option value="2">5</option>
                          </select>
                          <span>02</span>
                        </div>
                        <p className="ps-product__price sale">
                          $35.89 <del>$42.20 </del>
                        </p>
                      </div>
                      <div className="ps-product__content hover">
                        <a
                          className="ps-product__title"
                          href="product-default.html"
                        >
                          Unero Military Classical Backpack
                        </a>
                        <p className="ps-product__price sale">
                          $35.89 <del>$42.20 </del>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-6 ">
                  <div className="ps-product">
                    <div className="ps-product__thumbnail">
                      <a href="product-default.html">
                        <img src="/img/products/shop/9.jpg" alt="" />
                      </a>
                      <ul className="ps-product__actions">
                        <li>
                          <a
                            href="#"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Add To Cart"
                          >
                            <i className="icon-bag2"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            data-placement="top"
                            title="Quick View"
                            data-toggle="modal"
                            data-target="#product-quickview"
                          >
                            <i className="icon-eye"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Add to Whishlist"
                          >
                            <i className="icon-heart"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Compare"
                          >
                            <i className="icon-chart-bars"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="ps-product__container">
                      <a className="ps-product__vendor" href="#">
                        Young shop
                      </a>
                      <div className="ps-product__content">
                        <a
                          className="ps-product__title"
                          href="product-default.html"
                        >
                          Rayban Rounded Sunglass Brown Color
                        </a>
                        <div className="ps-product__rating">
                          <select className="ps-rating" data-read-only="true">
                            <option value="1">1</option>
                            <option value="1">2</option>
                            <option value="1">3</option>
                            <option value="1">4</option>
                            <option value="2">5</option>
                          </select>
                          <span>02</span>
                        </div>
                        <p className="ps-product__price">$35.89</p>
                      </div>
                      <div className="ps-product__content hover">
                        <a
                          className="ps-product__title"
                          href="product-default.html"
                        >
                          Rayban Rounded Sunglass Brown Color
                        </a>
                        <p className="ps-product__price">$35.89</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          <div className="ps-deal-of-day shewBox">
            <div className="ps-container">
              <div className="ps-section__header">
                <div className="ps-block--countdown-deal">
                  <div className="ps-block__left">
                    <b>
                      {" "}
                      <h3>Related Product</h3>
                    </b>
                  </div>
                  {/* <div className="ps-block__right">
                <figure>
                  <figcaption>End in:</figcaption>
                  <ul
                    className="ps-countdown"
                    data-time="December 30, 2021 15:37:25"
                  >
                    <li>
                      <span className="days"></span>
                    </li>
                    <li>
                      <span className="hours"></span>
                    </li>
                    <li>
                      <span className="minutes"></span>
                    </li>
                    <li>
                      <span className="seconds"></span>
                    </li>
                  </ul>
                </figure>
              </div> */}
                </div>
                <a href="#">View all</a>
              </div>
              <div className="ps-section__content mt-30">
                {recentProduct?.length > 0 && (
                  <Slider {...settings}>
                    {recentProduct &&
                      recentProduct?.map((data, i) => {
                        return (
                          <div className="recentlostfound" key={i}>
                            <div className="ps-product">
                              <div className="ps-product__thumbnail">
                                <Link to={"/listing/" + data?.pro_sulg}>
                                  {/* <img
                                    src={URL.API_BASE_URL + data?.featureImage}
                                    alt=""
                                  /> */}
                                  <CoustomImg
                                    url={URL.API_BASE_URL + data?.featureImage}
                                    altkey={data?.title}
                                    w1={"100%"}
                                    w2={"100%"}
                                    h1={80}
                                    h2={180}
                                  />
                                </Link>
                                {/* <div className="ps-product__badge">-16%</div>
                    <ul className="ps-product__actions">
                      <li>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Add To Cart"
                        >
                          <i className="icon-bag2"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-placement="top"
                          title="Quick View"
                          data-toggle="modal"
                          data-target="#product-quickview"
                        >
                          <i className="icon-eye"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Add to Whishlist"
                        >
                          <i className="icon-heart"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Compare"
                        >
                          <i className="icon-chart-bars"></i>
                        </a>
                      </li>
                    </ul> */}
                              </div>
                              <div className="ps-product__container">
                                <div className="ps-product__content">
                                  <Link
                                    className="ps-product__title"
                                    to={"/listing/" + data?.pro_sulg}
                                  >
                                    {data?.title}
                                  </Link>

                                  {data?.afterdiscountprice && data?.price && (
                                    <p className="ps-product__price sale">
                                      ₹{data?.afterdiscountprice}{" "}
                                      <del>₹{data?.price} </del>
                                    </p>
                                  )}
                                </div>
                                {/* <div className="ps-product__content hover">
                              <a
                                className="ps-product__title"
                                href="product-default.html"
                              >
                                {data?.title}
                              </a>
                              <p className="ps-product__price sale">
                                ₹{data?.afterdiscountprice}{" "}
                                <del>₹{data?.price}</del>
                              </p>
                            </div> */}
                              </div>
                            </div>
                          </div>
                        );
                      })}

                    {/* <div className="recentlistor">
                <div className="ps-product">
                  <div className="ps-product__thumbnail">
                    <a href="product-default.html">
                      <img src="img/products/electronic/1.jpg" alt="" />
                    </a>
                    <div className="ps-product__badge">-16%</div>
                    <ul className="ps-product__actions">
                      <li>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Add To Cart"
                        >
                          <i className="icon-bag2"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-placement="top"
                          title="Quick View"
                          data-toggle="modal"
                          data-target="#product-quickview"
                        >
                          <i className="icon-eye"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Add to Whishlist"
                        >
                          <i className="icon-heart"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Compare"
                        >
                          <i className="icon-chart-bars"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="ps-product__container">
                    <a className="ps-product__vendor" href="#">
                      Go Pro
                    </a>
                    <div className="ps-product__content">
                      <a className="ps-product__title" href="product-default.html">
                        Marshall Kilburn Portable Wireless
                      </a>
                      <div className="ps-product__rating">
                        <select className="ps-rating" data-read-only="true">
                          <option value="1">1</option>
                          <option value="1">2</option>
                          <option value="1">3</option>
                          <option value="1">4</option>
                          <option value="2">5</option>
                        </select>
                        <span>01</span>
                      </div>
                      <p className="ps-product__price sale">
                        $567.99 <del>$670.00 </del>
                      </p>
                    </div>
                    <div className="ps-product__content hover">
                      <a className="ps-product__title" href="product-default.html">
                        Marshall Kilburn Portable Wireless
                      </a>
                      <p className="ps-product__price sale">
                        $567.99 <del>$670.00 </del>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="recentlistor">
                <div className="ps-product">
                  <div className="ps-product__thumbnail">
                    <a href="product-default.html">
                      <img src="img/products/electronic/1.jpg" alt="" />
                    </a>
                    <div className="ps-product__badge">-16%</div>
                    <ul className="ps-product__actions">
                      <li>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Add To Cart"
                        >
                          <i className="icon-bag2"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-placement="top"
                          title="Quick View"
                          data-toggle="modal"
                          data-target="#product-quickview"
                        >
                          <i className="icon-eye"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Add to Whishlist"
                        >
                          <i className="icon-heart"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Compare"
                        >
                          <i className="icon-chart-bars"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="ps-product__container">
                    <a className="ps-product__vendor" href="#">
                      Go Pro
                    </a>
                    <div className="ps-product__content">
                      <a className="ps-product__title" href="product-default.html">
                        Marshall Kilburn Portable Wireless
                      </a>
                      <div className="ps-product__rating">
                        <select className="ps-rating" data-read-only="true">
                          <option value="1">1</option>
                          <option value="1">2</option>
                          <option value="1">3</option>
                          <option value="1">4</option>
                          <option value="2">5</option>
                        </select>
                        <span>01</span>
                      </div>
                      <p className="ps-product__price sale">
                        $567.99 <del>$670.00 </del>
                      </p>
                    </div>
                    <div className="ps-product__content hover">
                      <a className="ps-product__title" href="product-default.html">
                        Marshall Kilburn Portable Wireless
                      </a>
                      <p className="ps-product__price sale">
                        $567.99 <del>$670.00 </del>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="recentlistor">
                <div className="ps-product">
                  <div className="ps-product__thumbnail">
                    <a href="product-default.html">
                      <img src="img/products/electronic/1.jpg" alt="" />
                    </a>
                    <div className="ps-product__badge">-16%</div>
                    <ul className="ps-product__actions">
                      <li>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Add To Cart"
                        >
                          <i className="icon-bag2"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-placement="top"
                          title="Quick View"
                          data-toggle="modal"
                          data-target="#product-quickview"
                        >
                          <i className="icon-eye"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Add to Whishlist"
                        >
                          <i className="icon-heart"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Compare"
                        >
                          <i className="icon-chart-bars"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="ps-product__container">
                    <a className="ps-product__vendor" href="#">
                      Go Pro
                    </a>
                    <div className="ps-product__content">
                      <a className="ps-product__title" href="product-default.html">
                        Marshall Kilburn Portable Wireless
                      </a>
                      <div className="ps-product__rating">
                        <select className="ps-rating" data-read-only="true">
                          <option value="1">1</option>
                          <option value="1">2</option>
                          <option value="1">3</option>
                          <option value="1">4</option>
                          <option value="2">5</option>
                        </select>
                        <span>01</span>
                      </div>
                      <p className="ps-product__price sale">
                        $567.99 <del>$670.00 </del>
                      </p>
                    </div>
                    <div className="ps-product__content hover">
                      <a className="ps-product__title" href="product-default.html">
                        Marshall Kilburn Portable Wireless
                      </a>
                      <p className="ps-product__price sale">
                        $567.99 <del>$670.00 </del>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="recentlistor">
                <div className="ps-product">
                  <div className="ps-product__thumbnail">
                    <a href="product-default.html">
                      <img src="img/products/electronic/1.jpg" alt="" />
                    </a>
                    <div className="ps-product__badge">-16%</div>
                    <ul className="ps-product__actions">
                      <li>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Add To Cart"
                        >
                          <i className="icon-bag2"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-placement="top"
                          title="Quick View"
                          data-toggle="modal"
                          data-target="#product-quickview"
                        >
                          <i className="icon-eye"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Add to Whishlist"
                        >
                          <i className="icon-heart"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Compare"
                        >
                          <i className="icon-chart-bars"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="ps-product__container">
                    <a className="ps-product__vendor" href="#">
                      Go Pro
                    </a>
                    <div className="ps-product__content">
                      <a className="ps-product__title" href="product-default.html">
                        Marshall Kilburn Portable Wireless
                      </a>
                      <div className="ps-product__rating">
                        <select className="ps-rating" data-read-only="true">
                          <option value="1">1</option>
                          <option value="1">2</option>
                          <option value="1">3</option>
                          <option value="1">4</option>
                          <option value="2">5</option>
                        </select>
                        <span>01</span>
                      </div>
                      <p className="ps-product__price sale">
                        $567.99 <del>$670.00 </del>
                      </p>
                    </div>
                    <div className="ps-product__content hover">
                      <a className="ps-product__title" href="product-default.html">
                        Marshall Kilburn Portable Wireless
                      </a>
                      <p className="ps-product__price sale">
                        $567.99 <del>$670.00 </del>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="recentlistor">
                <div className="ps-product">
                  <div className="ps-product__thumbnail">
                    <a href="product-default.html">
                      <img src="img/products/electronic/1.jpg" alt="" />
                    </a>
                    <div className="ps-product__badge">-16%</div>
                    <ul className="ps-product__actions">
                      <li>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Add To Cart"
                        >
                          <i className="icon-bag2"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-placement="top"
                          title="Quick View"
                          data-toggle="modal"
                          data-target="#product-quickview"
                        >
                          <i className="icon-eye"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Add to Whishlist"
                        >
                          <i className="icon-heart"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Compare"
                        >
                          <i className="icon-chart-bars"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="ps-product__container">
                    <a className="ps-product__vendor" href="#">
                      Go Pro
                    </a>
                    <div className="ps-product__content">
                      <a className="ps-product__title" href="product-default.html">
                        Marshall Kilburn Portable Wireless
                      </a>
                      <div className="ps-product__rating">
                        <select className="ps-rating" data-read-only="true">
                          <option value="1">1</option>
                          <option value="1">2</option>
                          <option value="1">3</option>
                          <option value="1">4</option>
                          <option value="2">5</option>
                        </select>
                        <span>01</span>
                      </div>
                      <p className="ps-product__price sale">
                        $567.99 <del>$670.00 </del>
                      </p>
                    </div>
                    <div className="ps-product__content hover">
                      <a className="ps-product__title" href="product-default.html">
                        Marshall Kilburn Portable Wireless
                      </a>
                      <p className="ps-product__price sale">
                        $567.99 <del>$670.00 </del>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="recentlistor">
                <div className="ps-product">
                  <div className="ps-product__thumbnail">
                    <a href="product-default.html">
                      <img src="img/products/electronic/1.jpg" alt="" />
                    </a>
                    <div className="ps-product__badge">-16%</div>
                    <ul className="ps-product__actions">
                      <li>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Add To Cart"
                        >
                          <i className="icon-bag2"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-placement="top"
                          title="Quick View"
                          data-toggle="modal"
                          data-target="#product-quickview"
                        >
                          <i className="icon-eye"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Add to Whishlist"
                        >
                          <i className="icon-heart"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Compare"
                        >
                          <i className="icon-chart-bars"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="ps-product__container">
                    <a className="ps-product__vendor" href="#">
                      Go Pro
                    </a>
                    <div className="ps-product__content">
                      <a className="ps-product__title" href="product-default.html">
                        Marshall Kilburn Portable Wireless
                      </a>
                      <div className="ps-product__rating">
                        <select className="ps-rating" data-read-only="true">
                          <option value="1">1</option>
                          <option value="1">2</option>
                          <option value="1">3</option>
                          <option value="1">4</option>
                          <option value="2">5</option>
                        </select>
                        <span>01</span>
                      </div>
                      <p className="ps-product__price sale">
                        $567.99 <del>$670.00 </del>
                      </p>
                    </div>
                    <div className="ps-product__content hover">
                      <a className="ps-product__title" href="product-default.html">
                        Marshall Kilburn Portable Wireless
                      </a>
                      <p className="ps-product__price sale">
                        $567.99 <del>$670.00 </del>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="recentlistor">
                <div className="ps-product">
                  <div className="ps-product__thumbnail">
                    <a href="product-default.html">
                      <img src="img/products/electronic/1.jpg" alt="" />
                    </a>
                    <div className="ps-product__badge">-16%</div>
                    <ul className="ps-product__actions">
                      <li>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Add To Cart"
                        >
                          <i className="icon-bag2"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-placement="top"
                          title="Quick View"
                          data-toggle="modal"
                          data-target="#product-quickview"
                        >
                          <i className="icon-eye"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Add to Whishlist"
                        >
                          <i className="icon-heart"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Compare"
                        >
                          <i className="icon-chart-bars"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="ps-product__container">
                    <a className="ps-product__vendor" href="#">
                      Go Pro
                    </a>
                    <div className="ps-product__content">
                      <a className="ps-product__title" href="product-default.html">
                        Marshall Kilburn Portable Wireless
                      </a>
                      <div className="ps-product__rating">
                        <select className="ps-rating" data-read-only="true">
                          <option value="1">1</option>
                          <option value="1">2</option>
                          <option value="1">3</option>
                          <option value="1">4</option>
                          <option value="2">5</option>
                        </select>
                        <span>01</span>
                      </div>
                      <p className="ps-product__price sale">
                        $567.99 <del>$670.00 </del>
                      </p>
                    </div>
                    <div className="ps-product__content hover">
                      <a className="ps-product__title" href="product-default.html">
                        Marshall Kilburn Portable Wireless
                      </a>
                      <p className="ps-product__price sale">
                        $567.99 <del>$670.00 </del>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="recentlistor">
                <div className="ps-product">
                  <div className="ps-product__thumbnail">
                    <a href="product-default.html">
                      <img src="img/products/electronic/1.jpg" alt="" />
                    </a>
                    <div className="ps-product__badge">-16%</div>
                    <ul className="ps-product__actions">
                      <li>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Add To Cart"
                        >
                          <i className="icon-bag2"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-placement="top"
                          title="Quick View"
                          data-toggle="modal"
                          data-target="#product-quickview"
                        >
                          <i className="icon-eye"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Add to Whishlist"
                        >
                          <i className="icon-heart"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Compare"
                        >
                          <i className="icon-chart-bars"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="ps-product__container">
                    <a className="ps-product__vendor" href="#">
                      Go Pro
                    </a>
                    <div className="ps-product__content">
                      <a className="ps-product__title" href="product-default.html">
                        Marshall Kilburn Portable Wireless
                      </a>
                      <div className="ps-product__rating">
                        <select className="ps-rating" data-read-only="true">
                          <option value="1">1</option>
                          <option value="1">2</option>
                          <option value="1">3</option>
                          <option value="1">4</option>
                          <option value="2">5</option>
                        </select>
                        <span>01</span>
                      </div>
                      <p className="ps-product__price sale">
                        $567.99 <del>$670.00 </del>
                      </p>
                    </div>
                    <div className="ps-product__content hover">
                      <a className="ps-product__title" href="product-default.html">
                        Marshall Kilburn Portable Wireless
                      </a>
                      <p className="ps-product__price sale">
                        $567.99 <del>$670.00 </del>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="recentlistor">
                <div className="ps-product">
                  <div className="ps-product__thumbnail">
                    <a href="product-default.html">
                      <img src="img/products/electronic/1.jpg" alt="" />
                    </a>
                    <div className="ps-product__badge">-16%</div>
                    <ul className="ps-product__actions">
                      <li>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Add To Cart"
                        >
                          <i className="icon-bag2"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-placement="top"
                          title="Quick View"
                          data-toggle="modal"
                          data-target="#product-quickview"
                        >
                          <i className="icon-eye"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Add to Whishlist"
                        >
                          <i className="icon-heart"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Compare"
                        >
                          <i className="icon-chart-bars"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="ps-product__container">
                    <a className="ps-product__vendor" href="#">
                      Go Pro
                    </a>
                    <div className="ps-product__content">
                      <a className="ps-product__title" href="product-default.html">
                        Marshall Kilburn Portable Wireless
                      </a>
                      <div className="ps-product__rating">
                        <select className="ps-rating" data-read-only="true">
                          <option value="1">1</option>
                          <option value="1">2</option>
                          <option value="1">3</option>
                          <option value="1">4</option>
                          <option value="2">5</option>
                        </select>
                        <span>01</span>
                      </div>
                      <p className="ps-product__price sale">
                        $567.99 <del>$670.00 </del>
                      </p>
                    </div>
                    <div className="ps-product__content hover">
                      <a className="ps-product__title" href="product-default.html">
                        Marshall Kilburn Portable Wireless
                      </a>
                      <p className="ps-product__price sale">
                        $567.99 <del>$670.00 </del>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="recentlistor">
                <div className="ps-product">
                  <div className="ps-product__thumbnail">
                    <a href="product-default.html">
                      <img src="img/products/electronic/1.jpg" alt="" />
                    </a>
                    <div className="ps-product__badge">-16%</div>
                    <ul className="ps-product__actions">
                      <li>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Add To Cart"
                        >
                          <i className="icon-bag2"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-placement="top"
                          title="Quick View"
                          data-toggle="modal"
                          data-target="#product-quickview"
                        >
                          <i className="icon-eye"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Add to Whishlist"
                        >
                          <i className="icon-heart"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Compare"
                        >
                          <i className="icon-chart-bars"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="ps-product__container">
                    <a className="ps-product__vendor" href="#">
                      Go Pro
                    </a>
                    <div className="ps-product__content">
                      <a className="ps-product__title" href="product-default.html">
                        Marshall Kilburn Portable Wireless
                      </a>
                      <div className="ps-product__rating">
                        <select className="ps-rating" data-read-only="true">
                          <option value="1">1</option>
                          <option value="1">2</option>
                          <option value="1">3</option>
                          <option value="1">4</option>
                          <option value="2">5</option>
                        </select>
                        <span>01</span>
                      </div>
                      <p className="ps-product__price sale">
                        $567.99 <del>$670.00 </del>
                      </p>
                    </div>
                    <div className="ps-product__content hover">
                      <a className="ps-product__title" href="product-default.html">
                        Marshall Kilburn Portable Wireless
                      </a>
                      <p className="ps-product__price sale">
                        $567.99 <del>$670.00 </del>
                      </p>
                    </div>
                  </div>
                </div>
              </div> */}
                  </Slider>
                )}
              </div>
            </div>
          </div>
          {/* <div className="ps-section--default">
            <div className="ps-section__header">
              <h3>Related products</h3>
            </div>
            <div className="ps-section__content">
              <div
                className="ps-carousel--nav owl-slider"
                data-owl-auto="true"
                data-owl-loop="true"
                data-owl-speed="10000"
                data-owl-gap="30"
                data-owl-nav="true"
                data-owl-dots="true"
                data-owl-item="6"
                data-owl-item-xs="2"
                data-owl-item-sm="2"
                data-owl-item-md="3"
                data-owl-item-lg="4"
                data-owl-item-xl="5"
                data-owl-duration="1000"
                data-owl-mousedrag="on"
              >
                <div className="ps-product">
                  <div className="ps-product__thumbnail">
                    <a href="product-default.html">
                      <img src="/img/products/shop/11.jpg" alt="" />
                    </a>
                    <ul className="ps-product__actions">
                      <li>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Add To Cart"
                        >
                          <i className="icon-bag2"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-placement="top"
                          title="Quick View"
                          data-toggle="modal"
                          data-target="#product-quickview"
                        >
                          <i className="icon-eye"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Add to Whishlist"
                        >
                          <i className="icon-heart"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Compare"
                        >
                          <i className="icon-chart-bars"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="ps-product__container">
                    <a className="ps-product__vendor" href="#">
                      Robert's Store
                    </a>
                    <div className="ps-product__content">
                      <a className="ps-product__title" href="product-default.html">
                        Men’s Sports Runnning Swim Board Shorts
                      </a>
                      <div className="ps-product__rating">
                        <select className="ps-rating" data-read-only="true">
                          <option value="1">1</option>
                          <option value="1">2</option>
                          <option value="1">3</option>
                          <option value="1">4</option>
                          <option value="2">5</option>
                        </select>
                        <span>01</span>
                      </div>
                      <p className="ps-product__price">$13.43</p>
                    </div>
                    <div className="ps-product__content hover">
                      <a className="ps-product__title" href="product-default.html">
                        Men’s Sports Runnning Swim Board Shorts
                      </a>
                      <p className="ps-product__price">$13.43</p>
                    </div>
                  </div>
                </div>
                <div className="ps-product">
                  <div className="ps-product__thumbnail">
                    <a href="product-default.html">
                      <img src="/img/products/shop/12.jpg" alt="" />
                    </a>
                    <ul className="ps-product__actions">
                      <li>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Add To Cart"
                        >
                          <i className="icon-bag2"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-placement="top"
                          title="Quick View"
                          data-toggle="modal"
                          data-target="#product-quickview"
                        >
                          <i className="icon-eye"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Add to Whishlist"
                        >
                          <i className="icon-heart"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Compare"
                        >
                          <i className="icon-chart-bars"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="ps-product__container">
                    <a className="ps-product__vendor" href="#">
                      Global Office
                    </a>
                    <div className="ps-product__content">
                      <a className="ps-product__title" href="product-default.html">
                        Paul’s Smith Sneaker InWhite Color
                      </a>
                      <div className="ps-product__rating">
                        <select className="ps-rating" data-read-only="true">
                          <option value="1">1</option>
                          <option value="1">2</option>
                          <option value="1">3</option>
                          <option value="1">4</option>
                          <option value="2">5</option>
                        </select>
                        <span>01</span>
                      </div>
                      <p className="ps-product__price">$75.44</p>
                    </div>
                    <div className="ps-product__content hover">
                      <a className="ps-product__title" href="product-default.html">
                        Paul’s Smith Sneaker InWhite Color
                      </a>
                      <p className="ps-product__price">$75.44</p>
                    </div>
                  </div>
                </div>
                <div className="ps-product">
                  <div className="ps-product__thumbnail">
                    <a href="product-default.html">
                      <img src="/img/products/shop/13.jpg" alt="" />
                    </a>
                    <div className="ps-product__badge">-7%</div>
                    <ul className="ps-product__actions">
                      <li>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Add To Cart"
                        >
                          <i className="icon-bag2"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-placement="top"
                          title="Quick View"
                          data-toggle="modal"
                          data-target="#product-quickview"
                        >
                          <i className="icon-eye"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Add to Whishlist"
                        >
                          <i className="icon-heart"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Compare"
                        >
                          <i className="icon-chart-bars"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="ps-product__container">
                    <a className="ps-product__vendor" href="#">
                      Young Shop
                    </a>
                    <div className="ps-product__content">
                      <a className="ps-product__title" href="product-default.html">
                        MVMTH Classical Leather Watch In Black
                      </a>
                      <div className="ps-product__rating">
                        <select className="ps-rating" data-read-only="true">
                          <option value="1">1</option>
                          <option value="1">2</option>
                          <option value="1">3</option>
                          <option value="1">4</option>
                          <option value="2">5</option>
                        </select>
                        <span>01</span>
                      </div>
                      <p className="ps-product__price sale">
                        $57.99 <del>$62.99 </del>
                      </p>
                    </div>
                    <div className="ps-product__content hover">
                      <a className="ps-product__title" href="product-default.html">
                        MVMTH Classical Leather Watch In Black
                      </a>
                      <p className="ps-product__price sale">
                        $57.99 <del>$62.99 </del>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="ps-product">
                  <div className="ps-product__thumbnail">
                    <a href="product-default.html">
                      <img src="/img/products/shop/14.jpg" alt="" />
                    </a>
                    <div className="ps-product__badge">-7%</div>
                    <ul className="ps-product__actions">
                      <li>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Add To Cart"
                        >
                          <i className="icon-bag2"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-placement="top"
                          title="Quick View"
                          data-toggle="modal"
                          data-target="#product-quickview"
                        >
                          <i className="icon-eye"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Add to Whishlist"
                        >
                          <i className="icon-heart"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Compare"
                        >
                          <i className="icon-chart-bars"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="ps-product__container">
                    <a className="ps-product__vendor" href="#">
                      Global Office
                    </a>
                    <div className="ps-product__content">
                      <a className="ps-product__title" href="product-default.html">
                        Beat Spill 2.0 Wireless Speaker – White
                      </a>
                      <div className="ps-product__rating">
                        <select className="ps-rating" data-read-only="true">
                          <option value="1">1</option>
                          <option value="1">2</option>
                          <option value="1">3</option>
                          <option value="1">4</option>
                          <option value="2">5</option>
                        </select>
                        <span>01</span>
                      </div>
                      <p className="ps-product__price sale">
                        $57.99 <del>$62.99 </del>
                      </p>
                    </div>
                    <div className="ps-product__content hover">
                      <a className="ps-product__title" href="product-default.html">
                        Beat Spill 2.0 Wireless Speaker – White
                      </a>
                      <p className="ps-product__price sale">
                        $57.99 <del>$62.99 </del>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="ps-product">
                  <div className="ps-product__thumbnail">
                    <a href="product-default.html">
                      <img src="/img/products/shop/15.jpg" alt="" />
                    </a>
                    <ul className="ps-product__actions">
                      <li>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Add To Cart"
                        >
                          <i className="icon-bag2"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-placement="top"
                          title="Quick View"
                          data-toggle="modal"
                          data-target="#product-quickview"
                        >
                          <i className="icon-eye"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Add to Whishlist"
                        >
                          <i className="icon-heart"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Compare"
                        >
                          <i className="icon-chart-bars"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="ps-product__container">
                    <a className="ps-product__vendor" href="#">
                      Young Shop
                    </a>
                    <div className="ps-product__content">
                      <a className="ps-product__title" href="product-default.html">
                        ASUS Chromebook Flip – 10.2 Inch
                      </a>
                      <div className="ps-product__rating">
                        <select className="ps-rating" data-read-only="true">
                          <option value="1">1</option>
                          <option value="1">2</option>
                          <option value="1">3</option>
                          <option value="1">4</option>
                          <option value="2">5</option>
                        </select>
                        <span>01</span>
                      </div>
                      <p className="ps-product__price sale">$332.38</p>
                    </div>
                    <div className="ps-product__content hover">
                      <a className="ps-product__title" href="product-default.html">
                        ASUS Chromebook Flip – 10.2 Inch
                      </a>
                      <p className="ps-product__price sale">$332.38</p>
                    </div>
                  </div>
                </div>
                <div className="ps-product">
                  <div className="ps-product__thumbnail">
                    <a href="product-default.html">
                      <img src="/img/products/shop/16.jpg" alt="" />
                    </a>
                    <div className="ps-product__badge">-7%</div>
                    <ul className="ps-product__actions">
                      <li>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Add To Cart"
                        >
                          <i className="icon-bag2"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-placement="top"
                          title="Quick View"
                          data-toggle="modal"
                          data-target="#product-quickview"
                        >
                          <i className="icon-eye"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Add to Whishlist"
                        >
                          <i className="icon-heart"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Compare"
                        >
                          <i className="icon-chart-bars"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="ps-product__container">
                    <a className="ps-product__vendor" href="#">
                      Young Shop
                    </a>
                    <div className="ps-product__content">
                      <a className="ps-product__title" href="product-default.html">
                        Apple Macbook Retina Display 12&quot;
                      </a>
                      <div className="ps-product__rating">
                        <select className="ps-rating" data-read-only="true">
                          <option value="1">1</option>
                          <option value="1">2</option>
                          <option value="1">3</option>
                          <option value="1">4</option>
                          <option value="2">5</option>
                        </select>
                        <span>01</span>
                      </div>
                      <p className="ps-product__price sale">
                        $1200.00 <del>$1362.99 </del>
                      </p>
                    </div>
                    <div className="ps-product__content hover">
                      <a className="ps-product__title" href="product-default.html">
                        Apple Macbook Retina Display 12&quot;
                      </a>
                      <p className="ps-product__price sale">
                        $1200.00 <del>$1362.99 </del>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="ps-product">
                  <div className="ps-product__thumbnail">
                    <a href="product-default.html">
                      <img src="/img/products/shop/17.jpg" alt="" />
                    </a>
                    <ul className="ps-product__actions">
                      <li>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Add To Cart"
                        >
                          <i className="icon-bag2"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-placement="top"
                          title="Quick View"
                          data-toggle="modal"
                          data-target="#product-quickview"
                        >
                          <i className="icon-eye"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Add to Whishlist"
                        >
                          <i className="icon-heart"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Compare"
                        >
                          <i className="icon-chart-bars"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="ps-product__container">
                    <a className="ps-product__vendor" href="#">
                      Robert's Store
                    </a>
                    <div className="ps-product__content">
                      <a className="ps-product__title" href="product-default.html">
                        Samsung UHD TV 24inch
                      </a>
                      <div className="ps-product__rating">
                        <select className="ps-rating" data-read-only="true">
                          <option value="1">1</option>
                          <option value="1">2</option>
                          <option value="1">3</option>
                          <option value="1">4</option>
                          <option value="2">5</option>
                        </select>
                        <span>01</span>
                      </div>
                      <p className="ps-product__price">$599.00</p>
                    </div>
                    <div className="ps-product__content hover">
                      <a className="ps-product__title" href="product-default.html">
                        Samsung UHD TV 24inch
                      </a>
                      <p className="ps-product__price">$599.00</p>
                    </div>
                  </div>
                </div>
                <div className="ps-product">
                  <div className="ps-product__thumbnail">
                    <a href="product-default.html">
                      <img src="/img/products/shop/18.jpg" alt="" />
                    </a>
                    <ul className="ps-product__actions">
                      <li>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Add To Cart"
                        >
                          <i className="icon-bag2"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-placement="top"
                          title="Quick View"
                          data-toggle="modal"
                          data-target="#product-quickview"
                        >
                          <i className="icon-eye"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Add to Whishlist"
                        >
                          <i className="icon-heart"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Compare"
                        >
                          <i className="icon-chart-bars"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="ps-product__container">
                    <a className="ps-product__vendor" href="#">
                      Robert's Store
                    </a>
                    <div className="ps-product__content">
                      <a className="ps-product__title" href="product-default.html">
                        EPSION Plaster Printer
                      </a>
                      <div className="ps-product__rating">
                        <select className="ps-rating" data-read-only="true">
                          <option value="1">1</option>
                          <option value="1">2</option>
                          <option value="1">3</option>
                          <option value="1">4</option>
                          <option value="2">5</option>
                        </select>
                        <span>01</span>
                      </div>
                      <p className="ps-product__price">$233.28</p>
                    </div>
                    <div className="ps-product__content hover">
                      <a className="ps-product__title" href="product-default.html">
                        EPSION Plaster Printer
                      </a>
                      <p className="ps-product__price">$233.28</p>
                    </div>
                  </div>
                </div>
                <div className="ps-product">
                  <div className="ps-product__thumbnail">
                    <a href="product-default.html">
                      <img src="/img/products/shop/19.jpg" alt="" />
                    </a>
                    <ul className="ps-product__actions">
                      <li>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Add To Cart"
                        >
                          <i className="icon-bag2"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-placement="top"
                          title="Quick View"
                          data-toggle="modal"
                          data-target="#product-quickview"
                        >
                          <i className="icon-eye"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Add to Whishlist"
                        >
                          <i className="icon-heart"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Compare"
                        >
                          <i className="icon-chart-bars"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="ps-product__container">
                    <a className="ps-product__vendor" href="#">
                      Robert's Store
                    </a>
                    <div className="ps-product__content">
                      <a className="ps-product__title" href="product-default.html">
                        EPSION Plaster Printer
                      </a>
                      <div className="ps-product__rating">
                        <select className="ps-rating" data-read-only="true">
                          <option value="1">1</option>
                          <option value="1">2</option>
                          <option value="1">3</option>
                          <option value="1">4</option>
                          <option value="2">5</option>
                        </select>
                        <span>01</span>
                      </div>
                      <p className="ps-product__price">$233.28</p>
                    </div>
                    <div className="ps-product__content hover">
                      <a className="ps-product__title" href="product-default.html">
                        EPSION Plaster Printer
                      </a>
                      <p className="ps-product__price">$233.28</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default ListingDetailsPubl;
