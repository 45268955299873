// import { allapiAction } from "@/Redux/common/action";
// import { URL } from "@/Redux/common/url";
// import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import HomeSlider from "../Home/HomeSlider";
import OurWork from "../OurWork/OurWork";

import { URL } from "../../Redux/common/url";
import { allapiAction } from "../../Redux/common/action";
import { useNavigate } from "react-router-dom";

function AllSellerCom() {
  // get_seller_all_list
  const dispatch = useDispatch();
  const route = useNavigate();
  const get_seller_all_list = useSelector((state) =>
    state?.allapi?.get_seller_all_list ? state?.allapi?.get_seller_all_list : {}
  );
  const lost_and_found_pub = useSelector((state) =>
    state?.allapi?.get_seller_all_list ? state?.allapi?.get_seller_all_list : {}
  );

  // console.log(get_seller_all_list);

  const allsellerList = get_seller_all_list?.docs
    ? get_seller_all_list?.docs
    : [];

  useEffect(() => {
    dispatch(allapiAction.getsellersList(1));
    dispatch(allapiAction.getsliderList({ page: "home" }));
  }, []);
  const [page, setPage] = useState(1);
  const items = lost_and_found_pub?.totalDocs;
  const itemsPerPage = 12;
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setPageCount(Math.ceil(items && items / itemsPerPage));
  }, [itemOffset, itemsPerPage, lost_and_found_pub, items]);
  const handlePageClick = (event) => {
    const data = event?.selected + 1;
    const newOffset = (event.selected * itemsPerPage) % items;
    setItemOffset(newOffset);
    setPage(data);
    // dispatch(allapiAction.getsellersList(data));
    // setsearch(e);
    // setPage(1);

    var fanalquery = { page: data };

    if (search) {
      fanalquery.key = search;
    }
    if (state) {
      fanalquery.state = state;
    }
    if (District) {
      fanalquery.District = District;
    }
    if (Citys) {
      fanalquery.City = Citys;
    }
    if (pincode) {
      fanalquery.pincode = pincode;
    }
    dispatch(allapiAction.getsellersListFilter(fanalquery));
  };
  const [state, setState] = useState("");
  const [District, setDistrict] = useState("");
  const [Citys, setCity] = useState("");
  const [CitysList, setCitysList] = useState([]);
  const [pincode, setpincode] = useState("");
  const [search, setsearch] = useState("");
  // const allCountry = Country.getAllCountries();
  // const allStates = State.getAllStates();

  // const statesindia =
  //   allStates &&
  //   allStates?.filter((data) => {
  //     return data.countryCode == "IN";
  //   });
  // console.log(statesindia);
  // useEffect(() => {
  //   if (state) {
  //     console.log("sfdsfdskjfnsdnfn");
  //     // const statecode =
  //     //   statesindia &&
  //     //   statesindia?.filter((data) => {
  //     //     return data.name == state;
  //     //   });

  //     // console.log(state);
  //     const statecode = statesindia.find((data) => data.name == state);
  //     // console.log(statecode);
  //     const cityData = City.getCitiesOfState("IN", statecode?.isoCode);

  //     console.log(cityData);
  //     setCitysList(cityData);
  //   }
  // }, [state]);

  const hendletosearche = (e) => {
    setsearch(e);
    setPage(1);

    var fanalquery = { page: page, key: e };

    // if (search) {
    //   fanalquery.key = search;
    // }
    if (state) {
      fanalquery.state = state;
    }
    if (District) {
      fanalquery.District = District;
    }
    if (Citys) {
      fanalquery.City = Citys;
    }
    if (pincode) {
      fanalquery.pincode = pincode;
    }
    dispatch(allapiAction.getsellersListFilter(fanalquery));
  };
  const hendletosetState = (e) => {
    setState(e);
    setCity("");
    setPage(1);
    var fanalquery = { page: page, state: e };
    if (search) {
      fanalquery.key = search;
    }
    // if (state) {
    //   fanalquery.state = state;
    // }
    if (District) {
      fanalquery.District = District;
    }
    if (Citys) {
      fanalquery.City = Citys;
    }
    if (pincode) {
      fanalquery.pincode = pincode;
    }
    dispatch(allapiAction.getsellersListFilter(fanalquery));
  };
  const hendletosetCity = (e) => {
    setCity(e);
    setPage(1);
    var fanalquery = { page: page, City: e };
    if (search) {
      fanalquery.key = search;
    }
    if (state) {
      fanalquery.state = state;
    }
    if (District) {
      fanalquery.District = District;
    }
    // if (Citys) {
    //   fanalquery.City = Citys;
    // }
    if (pincode) {
      fanalquery.pincode = pincode;
    }
    dispatch(allapiAction.getsellersListFilter(fanalquery));
  };
  const hendletosetDis = (e) => {
    setDistrict(e);
    setPage(1);
    var fanalquery = { page: page, District: e };
    if (search) {
      fanalquery.key = search;
    }
    if (state) {
      fanalquery.state = state;
    }
    // if (District) {
    //   fanalquery.District = District;
    // }
    if (Citys) {
      fanalquery.City = Citys;
    }
    if (pincode) {
      fanalquery.pincode = pincode;
    }
    dispatch(allapiAction.getsellersListFilter(fanalquery));
  };
  const hendletosetPin = (e) => {
    setpincode(e);
    setPage(1);
    var fanalquery = { page: page, pincode: e };
    if (search) {
      fanalquery.key = search;
    }
    if (state) {
      fanalquery.state = state;
    }
    if (District) {
      fanalquery.District = District;
    }
    if (Citys) {
      fanalquery.City = Citys;
    }
    // if (pincode) {
    //   fanalquery.pincode = pincode;
    // }
    dispatch(allapiAction.getsellersListFilter(fanalquery));
  };
  return (
    <div className="hometest">
       
      <div className="container-fluid my-3">
        <div className="row">
          <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 p-1">
            <div className="input-box m-0">
              <i className="uil uil-search"></i>
              <input
                type="text"
                placeholder="Search For Vender..."
                onChange={(e) => {
                  hendletosearche(e?.target?.value);
                }}
              />
              <i className="button">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="27"
                  height="27"
                >
                  <path
                    d="M18.031 16.6168L22.3137 20.8995L20.8995 22.3137L16.6168 18.031C15.0769 19.263 13.124 20 11 20C6.032 20 2 15.968 2 11C2 6.032 6.032 2 11 2C15.968 2 20 6.032 20 11C20 13.124 19.263 15.0769 18.031 16.6168ZM16.0247 15.8748C17.2475 14.6146 18 12.8956 18 11C18 7.1325 14.8675 4 11 4C7.1325 4 4 7.1325 4 11C4 14.8675 7.1325 18 11 18C12.8956 18 14.6146 17.2475 15.8748 16.0247L16.0247 15.8748Z"
                    fill="rgba(234,173,33,1)"
                  ></path>
                </svg>
              </i>
            </div>
          </div>
          <div className="col-xl-2 col-lg-2 col-md-6 col-sm-6 col-6 p-1">
            <select
              id="inputState"
              onChange={(e) => {
                hendletosetState(e?.target?.value);
              }}
              className="form-control"
            >
              <option value="">Select State</option>
              
            </select>
          </div>
          <div className="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-6 p-1">
          
            <input
              type="text"
              className="form-control"
              placeholder="Enter District"
              onChange={(e) => {
                hendletosetDis(e?.target?.value);
              }}
            />
          </div>
          <div className="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-6 p-1">
            <select
              id="inputState"
              className="form-control"
              onChange={(e) => {
                hendletosetCity(e?.target?.value);
              }}
            >
              <option selected="">City</option>
              {CitysList &&
                CitysList?.map((data, i) => {
                  return (
                    <option key={i} value={data?.name}>
                      {data?.name}
                    </option>
                  );
                })}
            </select>
          </div>
          <div className="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-6 p-1">
             
            <input
              type="text"
              className="form-control"
              placeholder="Enter pincode"
              onChange={(e) => {
                hendletosetPin(e?.target?.value);
              }}
            />
          </div>
        </div>
      </div>
      <div className="container-fluid mt-5">
        <div className="row">
          {allsellerList &&
            allsellerList?.map((data, i) => {
              return (
                <div
                  key={i}
                  className="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-6 mb-2"
                  onClick={() => {
                    route("/vendor-store/" + data?.slug);
                  }}
                >
                  <div className="card p-3 text-center">
                    <div className="mb-1">
                      <img
                        src={
                          data?.sellerProfile
                            ? URL?.API_BASE_URL + data?.sellerProfile
                            : "/img/message/sudarshanchakra.gif"
                        }
                        alt="card_img"
                        className="img-fluid over_img"
                      />
                    </div>
                    <h4 className="m-0">
                      {data?.displayName ? data?.displayName : "test"}
                    </h4>
                    <small>
                      {data?.district} , {data?.state}
                    </small>
                    {/* <div className="ps-product__rating">
                            <select className="ps-rating" data-read-only="true">
                                <option value="1">1</option>
                                <option value="1">2</option>
                                <option value="1">3</option>
                                <option value="1">4</option>
                                <option value="2">5</option>
                            </select>
                        </div> */}
                    <h5>{data?.phone}</h5>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <div className="container mt-20">
        <nav
          className="d-flex justify-content-between"
          aria-label="Page navigation"
        >
          <ReactPaginate
            breakLabel="..."
            nextLabel="Next"
            onPageChange={handlePageClick}
            className="paging_simple_numbersc pagination"
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="Previous"
            renderOnZeroPageCount={null}
            // forcePage={page}
          />
        </nav>
      </div>
      <OurWork />
    </div>
  );
}

export default AllSellerCom;
