import React from "react";

function ExploreTopCities() {
  return (
    <div>
      {" "}
      <div class="container-fluid my-4 mt-4">
        <div class=" main-div-6">
          <h4 class="text-center mb-4 mt-4">
            Explore Top Cities <span class="badge bg-danger">NEW</span>
          </h4>
          <div className="container-fluid">
            <div class="row g-4 mb-4">
              <div class="col-6 col-sm-6 col-md-6 col-lg-3">
                <div class="city-card-8 text-center">
                  <img
                    src="img/listing/city 1.png"
                    alt="Mumbai"
                    class="w-50 h-auto"
                  />
                  <div class="main-8-text mt-4">
                    <div class="fw-bold text-center">MUMBAI</div>
                    <a href="#" class="explore-link">
                      Explore{" "}
                    </a>
                  </div>
                </div>
              </div>

              <div class="col-6 col-sm-6 col-md-6 col-lg-3">
                <div class="city-card-8 text-center">
                  <img
                    src="img/listing/city 2.png"
                    alt="Bangalore"
                    class="w-50 h-auto"
                  />
                  <div class="main-8-text mt-4">
                    <div class="fw-bold text-center">BANGALORE</div>
                    <a href="#" class="explore-link">
                      Explore{" "}
                    </a>
                  </div>
                </div>
              </div>

              <div class="col-6 col-sm-6 col-md-6 col-lg-3">
                <div class="city-card-8 text-center">
                  <img
                    src="img/listing/city 3.png"
                    alt="Delhi"
                    class="w-50 h-auto"
                  />
                  <div class="main-8-text mt-4">
                    <div class="fw-bold text-center">DELHI</div>
                    <a href="#" class="explore-link">
                      Explore{" "}
                    </a>
                  </div>
                </div>
              </div>

              <div class="col-6 col-sm-6 col-md-6 col-lg-3">
                <div class="city-card-8 text-center">
                  <img
                    src="img/listing/city 4.png"
                    alt="Kolkata"
                    class="w-50 h-auto"
                  />
                  <div class="main-8-text mt-4">
                    <div class="fw-bold text-center">KOLKATA</div>
                    <a href="#" class="explore-link">
                      Explore{" "}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExploreTopCities;
