// import { allapiAction } from "@/Redux/common/action";
// import { URL } from "@/Redux/common/url";
// import Link from "next/link";
// import { useRouter } from "next/router";
import React, { useEffect, useLayoutEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { allapiAction } from "../../Redux/common/action";
import { URL } from "../../Redux/common/url";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import CoustomImg from "../CoustomImg/CoustomImg";
import TruncateText from "../TruncateText/TruncateText";
import OurWork from "../OurWork/OurWork";
import { AsyncPaginate } from "react-select-async-paginate";
import Seo from "../Seo/Seo";
import axios from "axios";
import Slider from "react-slick";
// import CoustomImg from "../../Component/CoustomImg/CoustomImg";
// import TruncateText from "../../Component/TruncateText/TruncateText";

function AllListing() {
  const dispatch = useDispatch();
  const router = useNavigate();
  // const { search, page } = useParams();
  const locationv = useLocation();

  const searchParams = new URLSearchParams(locationv.search);
  const search = searchParams.get("search");
  const location = searchParams.get("location");
  const locationst = searchParams.get("locationst");
  const locationar = searchParams.get("locationar");
  const page = searchParams.get("page");
  const cateIds = searchParams.get("cateId");
  const maincateIds = searchParams.get("maincateId");
  const pcateIds = searchParams.get("pcateId");
  const get_product_list = useSelector((state) =>
    state?.allapi?.user_product_search ? state?.allapi?.user_product_search : {}
  );
  const get_category_Filter = useSelector((state) =>
    state?.allapi?.get_category_Filter ? state?.allapi?.get_category_Filter : []
  );

  console.log(get_category_Filter);

  const pageno = page ? page : 1;

  const listdata = get_product_list?.docs ? get_product_list?.docs : [];
  const listdataListing = get_product_list?.listingList?.docs
    ? get_product_list?.listingList?.docs
    : [];

  function groupIntoChunks(array, chunkSize) {
    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize));
    }
    return result;
  }

  // Divide the documents array into groups of 4
  const groupedDocs = groupIntoChunks(listdata, 4);

  console.log(groupedDocs);
  // get_product_list
  const items = get_product_list?.totalDocs;
  const itemsPerPage = 20;
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setPageCount(Math.ceil(items && items / itemsPerPage));
  }, [itemOffset, itemsPerPage, get_product_list, items]);

  const [userselete, setuserselete] = useState(null);

  console.log(userselete, "userselete");
  useEffect(() => {
    // searchProduct

    const qur = {
      page: pageno,
    };

    if (search) {
      qur.id = search;
    }

    if (location) {
      qur.location = location;
    }
    if (locationar) {
      qur.locationa = locationar;
    }
    if (locationst) {
      qur.locations = locationst;
    }
    if (cateIds) {
      qur.cateId = cateIds;
    }
    if (maincateIds) {
      qur.maincateId = maincateIds;
    }
    if (pcateIds) {
      qur.pcateId = pcateIds;
    }

    // if (search) {
    //   dispatch(allapiAction.userSearchListings({ page: pageno, id: search }));
    // } else {
    dispatch(allapiAction.userSearchListings(qur));
    // }

    return () => {};
  }, [locationv.search]);

  useEffect(() => {
    dispatch(allapiAction.getfiltercategory({}));

    return () => {};
  }, []);

  const [childcateee, setchildcateee] = useState("");
  const hendletoviewchlidcate = (e) => {};
  const hendletochangemainCt = (e) => {
    setchildcateee(e);

    const srch = search ? search : "";
    const locations = location ? location : "";
    const locationsts = locationst ? locationst : "";
    const locationars = locationar ? locationar : "";
    const cateIdsm = cateIds ? cateIds : "";
    const maincateId = maincateIds ? maincateIds : "";
    router(
      "/listings?search=" +
        srch +
        "&page=" +
        1 +
        "&location=" +
        locations +
        "&cateId=" +
        "" +
        "&maincateId=" +
        e +
        "&locationar=" +
        locationars +
        "&locationst=" +
        locationsts
    );
  };

  const handlePageClick = (event) => {
    const data = event?.selected + 1;
    // const newOffset = (event.selected * itemsPerPage) % items;
    // setItemOffset(newOffset);
    // setPage(data);
    const srch = search ? search : "";
    const locations = location ? location : "";
    const cateIdsm = cateIds ? cateIds : "";
    const maincateId = maincateIds ? maincateIds : "";
    const pcateId = pcateIds ? pcateIds : "";
    const locationsts = locationst ? locationst : "";
    const locationars = locationar ? locationar : "";
    router(
      "/listings?search=" +
        srch +
        "&page=" +
        data +
        "&location=" +
        locations +
        "&cateId=" +
        cateIdsm +
        "&pcateId=" +
        pcateId +
        "&maincateId=" +
        maincateId +
        "&locationar=" +
        locationars +
        "&locationst=" +
        locationsts
    );
    window.scrollTo(12, 0);
    // dispatch(allapiAction.lostfoundlistpublic({ page: data }));
  };
  const handlesearch = (event) => {
    // const data = event?.selected + 1;
    // const newOffset = (event.selected * itemsPerPage) % items;
    // setItemOffset(newOffset);
    // setPage(data);
    const srch = event ? event : "";
    const locations = location ? location : "";
    const pagess = page ? page : "";
    const cateIdsm = cateIds ? cateIds : "";
    const pcateId = pcateIds ? pcateIds : "";
    const maincateId = maincateIds ? maincateIds : "";
    const locationsts = locationst ? locationst : "";
    const locationars = locationar ? locationar : "";
    router(
      "/listings?search=" +
        srch +
        "&page=" +
        1 +
        "&location=" +
        locations +
        "&cateId=" +
        cateIdsm +
        "&pcateId=" +
        pcateId +
        "&maincateId=" +
        maincateId +
        "&locationar=" +
        locationars +
        "&locationst=" +
        locationsts
    );
    window.scrollTo(12, 0);
    // dispatch(allapiAction.lostfoundlistpublic({ page: data }));
  };

  const hendleToLocation = (e) => {
    setuserselete(e);
    // const srch = event ? event : "";
    const srch = search ? search : "";
    const locations = e?._id ? e?._id : "";

    const pagess = page ? page : "";
    const cateIdsm = cateIds ? cateIds : "";
    const pcateId = pcateIds ? pcateIds : "";
    const maincateId = maincateIds ? maincateIds : "";
    if (e?.type == "state") {
      router(
        "/listings?search=" +
          srch +
          "&page=" +
          1 +
          "&locationst=" +
          locations +
          "&cateId=" +
          cateIdsm +
          "&pcateId=" +
          pcateId +
          "&maincateId=" +
          maincateId
      );
    } else if (e?.type == "district") {
      router(
        "/listings?search=" +
          srch +
          "&page=" +
          1 +
          "&location=" +
          locations +
          "&cateId=" +
          cateIdsm +
          "&pcateId=" +
          pcateId +
          "&maincateId=" +
          maincateId
      );
    } else {
      router(
        "/listings?search=" +
          srch +
          "&page=" +
          1 +
          "&locationar=" +
          locations +
          "&cateId=" +
          cateIdsm +
          "&pcateId=" +
          pcateId +
          "&maincateId=" +
          maincateId
      );
    }

    window.scrollTo(12, 0);
    // dispatch(allapiAction.lost
  };
  const henldetofilterbyCate = (e) => {
    // setuserselete(e);
    // const srch = event ? event : "";
    const srch = search ? search : "";
    // const locations = e?._id ? e?._id : "";
    const locations = location ? location : "";
    const pagess = page ? page : "";
    const cateIdsm = cateIds ? cateIds : "";
    const locationsts = locationst ? locationst : "";
    const locationars = locationar ? locationar : "";
    router(
      "/listings?search=" +
        srch +
        "&page=" +
        1 +
        "&location=" +
        locations +
        "&cateId=" +
        e +
        "&locationar=" +
        locationars +
        "&locationst=" +
        locationsts
    );
    window.scrollTo(12, 0);
    // dispatch(allapiAction.lost
  };
  const henldetofilterbyCate2 = (e) => {
    if (search) {
      dispatch(
        allapiAction.userSearchListings({ page: pageno, id: search, cateId: e })
      );
    }
  };
  const handleclear = (e) => {
    setuserselete(null);
    router("/listings?search=" + "" + "&page=" + 1 + "&location=");
    window.scrollTo(12, 0);
    // dispatch(allapiAction.lost
  };

  const loadOptionsPalyer = async (search, loadedOptions, pages) => {
    console.log(loadedOptions);
    console.log(pages);
    const response = await fetch(
      URL.API_BASE_URL +
        `/publicApi/searchareaforproductDis2?key=${search}&page=${pages?.page}`
    );
    const responseJSON = await response.json();

    const options = responseJSON?.results?.map((data) => {
      data.value = data?._id;
      data.label =
        data?.type == "state"
          ? data?.State + " (All)"
          : data?.type == "district"
            ? data?.District + ", " + data?.State + " (All)"
            : data?.Area +
              ", " +
              data?.District +
              ", " +
              data?.State +
              ", " +
              data?.Pincode;

      return data;
    });

    return {
      options: options,
      hasMore: responseJSON.has_more,
      additional: {
        page: pages?.page + 1,
      },
    };
  };

  const [windowWidth, setWindowWidth] = useState(600);

  // Update window width when the window is resized
  useLayoutEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }
    setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [prompt, setPrompt] = useState("");
  const [response, setResponse] = useState("");

  const API_URL = "https://api-inference.huggingface.co/models/gpt2"; // Model ka endpoint
  const API_TOKEN = "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"; // Apna API Token yaha paste karein

  async function queryHuggingFace(prompt) {
    try {
      const response = await fetch(API_URL, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${API_TOKEN}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ inputs: prompt }),
      });

      const result = await response.json();
      console.log("Response:", result);
      if (result && result[0] && result[0].generated_text) {
        console.log("Generated Text:", result[0].generated_text);
      } else {
        console.error("No generated text found in the response");
      }
    } catch (error) {
      console.error("Error querying Hugging Face:", error.message);
    }
  }

  const userPrompt = "Write a short poem about technology.";

  const handleSubmit = async () => {
    try {
      queryHuggingFace(prompt);
      const res = await axios.post(URL?.API_BASE_URL + URL?.chatgpt, {
        prompt,
      });

      setResponse(res.data.response);
    } catch (error) {
      console.error("Error fetching response:", error);
    }
  };

  var settings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="container-fluid">
      <Seo
        settitle={
          "Free Vendor Product Listing & Lead Generation Portal in India"
        }
        titleSeo={
          "List Your Products | Generate Leads Online | Best Bharat Vendor Portal"
        }
        seoKeyword={
          "Vendor product listing platform, Lead generation website India, Free product listing portal, Best Bharat vendor portal, Small business leads India, Product listing and lead generation, Vendor lead generation India, Online business growth platform, Indian product listing website, Local vendor lead generation"
        }
        seo_descriptions={
          "Best Bharat is India's leading vendor product listing and lead generation platform. List your products or services for free, connect with customers, and grow your business with targeted leads. Perfect for small businesses and vendors across India​"
        }
        seoconcal={"https://bestbharat.com/"}
        title={"Free Vendor Product Listing & Lead Generation Portal in India"}
      />

      <div className="ps-deal-of-day shewBox">
        <div className="ps-container">
          <div class="ps-layout--shop mt-30">
            <div class="ps-layout__left">
              <aside class="widget widget_shop">
                <h4 class="widget-title">Categories</h4>
                <ul class="ps-list--categories">
                  {get_category_Filter &&
                    get_category_Filter?.map((data, i) => {
                      return (
                        <li class="menu-item-has-children" key={i}>
                          <a
                            href="#"
                            onClick={() => {
                              hendletochangemainCt(data?._id);
                            }}
                          >
                            {data?.name}
                          </a>
                          <span
                            class={
                              childcateee == data?._id
                                ? "sub-toggle active "
                                : "sub-toggle"
                            }
                            onClick={() => {
                              hendletochangemainCt(data?._id);
                            }}
                          >
                            <i class="fa fa-angle-down"></i>
                          </span>
                          <ul
                            class="sub-menu"
                            style={
                              childcateee == data?._id
                                ? { display: "block" }
                                : { display: "none" }
                            }
                          >
                            {data?.subcates &&
                              data?.subcates?.map((datachild, index) => {
                                return (
                                  <li key={index}>
                                    <a
                                      href="#"
                                      onClick={() => {
                                        henldetofilterbyCate(datachild?._id);
                                      }}
                                    >
                                      {datachild?.name}
                                    </a>
                                  </li>
                                );
                              })}
                          </ul>
                        </li>
                      );
                    })}
                </ul>
              </aside>
            </div>
            <div class="ps-layout__right">
              {/* <h1>AI Chatbot</h1>
              <textarea
                rows="4"
                cols="50"
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
                placeholder="Type your message here..."
              ></textarea>
              <br />
              <button onClick={handleSubmit}>Send</button>
              <h3>AI Response:</h3>
              <p>{response}</p> */}

              <div className="container-fluid my-3">
                <div className="row">
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 p-1 mt-3">
                    <div className="input-boxvs m-0">
                      <i className="uil uil-search"></i>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search For listing"
                        onChange={(e) => {
                          handlesearch(e?.target?.value);
                        }}
                      />
                      <i className="button">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="27"
                          height="27"
                        >
                          <path
                            d="M18.031 16.6168L22.3137 20.8995L20.8995 22.3137L16.6168 18.031C15.0769 19.263 13.124 20 11 20C6.032 20 2 15.968 2 11C2 6.032 6.032 2 11 2C15.968 2 20 6.032 20 11C20 13.124 19.263 15.0769 18.031 16.6168ZM16.0247 15.8748C17.2475 14.6146 18 12.8956 18 11C18 7.1325 14.8675 4 11 4C7.1325 4 4 7.1325 4 11C4 14.8675 7.1325 18 11 18C12.8956 18 14.6146 17.2475 15.8748 16.0247L16.0247 15.8748Z"
                            fill="rgba(234,173,33,1)"
                          ></path>
                        </svg>
                      </i>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 p-1 mt-3">
                    <AsyncPaginate
                      value={userselete}
                      className="liceninput"
                      // placeholder="location"
                      placeholder="Location"
                      loadOptions={loadOptionsPalyer}
                      onChange={hendleToLocation}
                      additional={{
                        page: 1,
                      }}
                    />
                    {/* <select
                      id="inputState"
                      // onChange={(e) => {
                      //   hendletosetState(e?.target?.value);
                      // }}
                      className="form-control"
                    >
                      <option value="">Select State</option>
                    </select> */}
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 p-1 mt-3">
                    <button
                      className="ps-btnm btn btn-warning w-100"
                      onClick={handleclear}
                    >
                      Clear Search
                    </button>
                  </div>
                  {/* <div className="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-6 p-1">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter District"
                      // onChange={(e) => {
                      //   hendletosetDis(e?.target?.value);
                      // }}
                    />
                  </div>
                  <div className="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-6 p-1">
                    <select
                      id="inputState"
                      className="form-control"
                      // onChange={(e) => {
                      //   hendletosetCity(e?.target?.value);
                      // }}
                    >
                      <option selected="">City</option>
                      
                    </select>
                  </div>
                  <div className="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-6 p-1">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter pincode"
                      // onChange={(e) => {
                      //   hendletosetPin(e?.target?.value);
                      // }}
                    />
                  </div> */}
                </div>
              </div>
              <div class="ps-block--shop-features">
                <div class="ps-block__header">
                  <h3>Listing</h3>
                  <div class="ps-block__navigation">
                    <a class="ps-carousel__prev" href="#recommended1">
                      <i class="icon-chevron-left"></i>
                    </a>
                    <a class="ps-carousel__next" href="#recommended1">
                      <i class="icon-chevron-right"></i>
                    </a>
                  </div>
                </div>
                {listdata?.length == 0 && (
                  <div className="notfound">
                    <h2>Not Found</h2>
                  </div>
                )}

                {groupedDocs &&
                  groupedDocs?.map((datamain) => {
                    console.log(datamain);
                    return (
                      <>
                        <div className="row ps-section__content mt-10">
                          {" "}
                          {datamain &&
                            datamain?.map((data, i) => {
                              return (
                                <div className="col-6 col-sm-3" key={i}>
                                  <div className="recentlostfound">
                                    <div className="ps-product">
                                      <div className="ps-product__thumbnail  ps-product__thumbnailms">
                                        <Link to={"/listing/" + data?.pro_sulg}>
                                          <CoustomImg
                                            url={
                                              URL.API_BASE_URL +
                                              data?.featureImage
                                            }
                                            altkey={data?.title}
                                            w1={200}
                                            w2={280}
                                            h1={200}
                                            h2={280}
                                          />
                                        </Link>
                                      </div>
                                      <div className="ps-product__container">
                                        <div className="ps-product__content">
                                          <Link
                                            className="ps-product__title"
                                            to={"/listing/" + data?.pro_sulg}
                                          >
                                            <TruncateText
                                              text={data?.title}
                                              maxLength={
                                                windowWidth > 600 ? 25 : 15
                                              }
                                            />
                                            {/* {data?.title} */}
                                          </Link>

                                          <Link
                                            className="ps-product__vendor1"
                                            to={
                                              "/vendor-store/" +
                                              data?.sellerInfo?.slug
                                            }
                                          >
                                            {data?.sellerInfo?.displayName}
                                          </Link>

                                          <div className="ps-product__actions">
                                            <Link
                                              to={"/listing/" + data?.pro_sulg}
                                              className="ps-btn ps-btn--fullwidth"
                                            >
                                              View Details
                                            </Link>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                        <div>
                          <Slider {...settings}>
                            <div className="p-3">
                              <img
                                src="img/listing/banner_bills_2024.webp"
                                alt="Slide 1"
                              />
                            </div>
                            <div className="p-3">
                              <img
                                src="img/listing/banner_cctv_2024.webp"
                                alt="Slide 2"
                              />
                            </div>
                            <div className="p-3">
                              <img
                                src="img/listing/banner_hotels_2024.webp"
                                alt="Slide 3"
                              />
                            </div>
                            <div className="p-3">
                              <img
                                src="img/listing/banner_interiordesigners_2024.webp"
                                alt="Slide 1"
                              />
                            </div>
                            <div className="p-3">
                              <img
                                src="img/listing/banner_packersmovers_2024.webp"
                                alt="Slide 2"
                              />
                            </div>
                          </Slider>
                        </div>
                      </>
                    );
                  })}
                {/* 
                <div className="row ps-section__content mt-10">
                  {" "}
                  {listdata &&
                    listdata?.map((data, i) => {
                      return (
                        <div className="col-6 col-sm-3" key={i}>
                          <div className="recentlostfound">
                            <div className="ps-product">
                              <div className="ps-product__thumbnail  ps-product__thumbnailms">
                                <Link to={"/listing/" + data?.pro_sulg}>
                                   

                                  <CoustomImg
                                    url={URL.API_BASE_URL + data?.featureImage}
                                    altkey={data?.title}
                                    w1={200}
                                    w2={280}
                                    h1={200}
                                    h2={280}
                                  />
                                </Link>
                                 
                              </div>
                              <div className="ps-product__container">
                                <div className="ps-product__content">
                                  <Link
                                    className="ps-product__title"
                                    to={"/listing/" + data?.pro_sulg}
                                  >
                                    <TruncateText
                                      text={data?.title}
                                      maxLength={windowWidth > 600 ? 25 : 15}
                                    />
                                    
                                  </Link>
                                  
                                  <Link
                                    className="ps-product__vendor1"
                                    to={
                                      "/vendor-store/" + data?.sellerInfo?.slug
                                    }
                                  >
                                    {data?.sellerInfo?.displayName}
                                  </Link>

                                  <div className="ps-product__actions">
                                    <Link
                                      to={"/listing/" + data?.pro_sulg}
                                      className="ps-btn ps-btn--fullwidth"
                                    >
                                      View Details
                                    </Link>
                                  </div>

                                  
                                </div>
                                
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-20">
        <nav
          className="d-flex justify-content-between"
          aria-label="Page navigation"
        >
          <ReactPaginate
            breakLabel="..."
            nextLabel="Next"
            onPageChange={handlePageClick}
            className="paging_simple_numbersc pagination"
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="Previous"
            renderOnZeroPageCount={null}
          />
        </nav>
      </div>
      <OurWork />
    </div>
  );
}

export default AllListing;
