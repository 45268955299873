import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function VendorFooter() {
  const vendorDetails = useSelector((state) =>
    state.allapi.get_seller_details ? state.allapi.get_seller_details : {}
  );
  return (
    <div className="ssft">
      {" "}
      <div className="navigation--list">
        <div class="navigation__content">
          <Link class="navigation__item ps-toggle--sidebar footico" to="/">
            <i class="icon-home"></i>
            <span> BestBharat Home </span>
          </Link>
          <Link
            class="navigation__item ps-toggle--sidebar footico"
            to={"/vendor-store/" + vendorDetails?.slug}
          >
            <i class="icon-home"></i>
            <span> {vendorDetails?.displayName}</span>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default VendorFooter;
