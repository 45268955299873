import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { URL } from "../../../Redux/common/url";

function LsitingCategory() {
  const all_categories_List = useSelector((state) =>
    state?.allapi?.all_categories_List ? state?.allapi?.all_categories_List : []
  );
  console.log(all_categories_List);

  return (
    <div>
      {" "}
      <div class="container-fluid mt-30">
        <div class="row ps-section__content catetext">
          {all_categories_List &&
            all_categories_List?.map((data, i) => {
              return (
                <div class="col-4 col-sm-3 col-md-2 ">
                  <div className="recentlostfound" key={data._id}>
                    <div className="ps-product">
                      <div className="ps-product__thumbnail categorycom">
                        <Link to={"/listings?page=1&pcateId=" + data?._id}>
                          <img
                            src={URL.API_BASE_URL + data?.image}
                            className="rounded-circle"
                            alt={data?.name}
                          />{" "}
                        </Link>
                      </div>
                      <div className="ps-product__container">
                        <div className="ps-product__content">
                          <Link
                            className="ps-product__title"
                            to={"/listings?page=1&pcateId=" + data?._id}
                          >
                            {data?.name}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
{/* 
          <div class="col-4 col-sm-3 col-md-2 ">
            <div class="icon-boxnew">
              <img src="img/listing/k1.png" alt="Restaurants" />
              <p>Restaurants</p>
            </div>
          </div>

          <div class="col-4 col-sm-3 col-md-2 ">
            <div class="icon-boxnew">
              <img src="img/listing/k2.png" alt="Hotels" />
              <p>Hotels</p>
            </div>
          </div>

          <div class="col-4 col-sm-3 col-md-2 ">
            <div class="icon-boxnew">
              <img src="img/listing/k3.png" alt="Beauty Spa" />
              <p>Beauty Spa</p>
            </div>
          </div>

          <div class="col-4 col-sm-3 col-md-2 ">
            <div class="icon-boxnew">
              <img src="img/listing/k4.png" alt="Home Decor" />
              <p>Home Decor</p>
            </div>
          </div>

          <div class="col-4 col-sm-3 col-md-2 ">
            <div class="icon-boxnew">
              <img src="img/listing/k5.png" alt="Wedding Planning" />
              <p>Wedding Planning</p>
            </div>
          </div>

          <div class="col-4 col-sm-3 col-md-2 ">
            <div class="icon-boxnew">
              <img src="img/listing/k6.png" alt="Education" />
              <p>Education</p>
            </div>
          </div>

          <div class="col-4 col-sm-3 col-md-2 ">
            <div class="icon-boxnew">
              <img src="img/listing/k7.png" alt="Rent & Hire" />
              <p>Rent & Hire</p>
            </div>
          </div>

          <div class="col-4 col-sm-3 col-md-2 ">
            <div class="icon-boxnew">
              <img src="img/listing/k8.png" alt="Hospitals" />
              <p>Hospitals</p>
            </div>
          </div>

          <div class="col-4 col-sm-3 col-md-2 ">
            <div class="icon-boxnew">
              <img src="img/listing/k9.png" alt="Wedding Planning" />
              <p>Contractors</p>
            </div>
          </div>

          <div class="col-4 col-sm-3 col-md-2 ">
            <div class="icon-boxnew">
              <img src="img/listing/k10.png" alt="Education" />
              <p>Pet Shops</p>
            </div>
          </div>

          <div class="col-4 col-sm-3 col-md-2 ">
            <div class="icon-boxnew">
              <img src="img/listing/k11.png" alt="Rent & Hire" />
              <p>PG/Hostels</p>
            </div>
          </div>

          <div class="col-4 col-sm-3 col-md-2 ">
            <div class="icon-boxnew">
              <img src="img/listing/k12.png" alt="Hospitals" />
              <p>Estate Agent</p>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default LsitingCategory;
