import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { allapiAction } from "../../../Redux/common/action";
import { URL } from "../../../Redux/common/url";
import { Link } from "react-router-dom";

function PopularSearches() {
  const get_product_list = useSelector((state) =>
    state?.allapi?.user_product_search ? state?.allapi?.user_product_search : {}
  );

  console.log(get_product_list);
  const listdata = get_product_list?.docs ? get_product_list?.docs : [];

  const randomFourDocs = listdata
    .sort(() => 0.5 - Math.random()) // Shuffle the array
    .slice(0, 6); // Get the first 4 elements

  console.log(randomFourDocs);

  const dispatch = useDispatch();
  useEffect(() => {
    // searchProduct

    const qur = {
      page: 1,
    };

    dispatch(allapiAction.userSearchListings(qur));

    return () => {};
  }, []);
  return (
    <div>
      {" "}
      <div class="container-fluid my-5">
        <h4 class="text-center mb-4">Popular Searches</h4>
        <div class="row g-3">
          {randomFourDocs &&
            randomFourDocs?.map((data, i) => {
              return (
                <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-2">
                  <div class="card rounded-top-4 ">
                    <img
                      src={URL.API_BASE_URL + data?.featureImage}
                      class="rounded-top"
                      alt="Colleges"
                    />
                    <div class=" text-center bg-primary">
                      <h6 class=" text-center text-white mt-3 fw-bold">
                        {data?.title}
                      </h6>
                      <Link
                        to={"/listing/" + data?.pro_sulg}
                        class="btn text-primary bg-white btn-primary mb-3"
                      >
                        Enquire Now
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
          {/* <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-2">
            <div class="card rounded-top-4 ">
              <img
                src="img/listing/g1.png"
                class="rounded-top"
                alt="Colleges"
              />
              <div class=" text-center bg-primary">
                <h6 class=" text-center text-white mt-3 fw-bold">Colleges</h6>
                <a href="#" class="btn text-primary bg-white btn-primary mb-3">
                  Enquire Now
                </a>
              </div>
            </div>
          </div>

          <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-2">
            <div class="card rounded-top-4 ">
              <img src="img/listing/g2.png" class="" alt="Taxi Services" />
              <div class=" text-center bg-primary">
                <h6 class=" text-center text-white mt-3 fw-bold">
                  Taxi Services
                </h6>
                <a href="#" class="btn text-primary bg-white btn-primary mb-3">
                  Enquire Now
                </a>
              </div>
            </div>
          </div>

          <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-2">
            <div class="card rounded-top-4 ">
              <img src="img/listing/g3.png" class="p" alt="Car Rental" />
              <div class=" text-center bg-primary">
                <h6 class=" text-center text-white mt-3 fw-bold">Car Rental</h6>
                <a href="#" class="btn text-primary bg-white btn-primary mb-3">
                  Explore
                </a>
              </div>
            </div>
          </div>

          <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-2">
            <div class="card rounded-top-4 ">
              <img
                src="img/listing/g4.png"
                class=""
                alt="Computer Training Institutes"
              />
              <div class=" text-center bg-primary">
                <h6 class=" text-center text-white mt-3 fw-bold">
                  Computer Training Institutes
                </h6>
                <a href="#" class="btn text-primary bg-white btn-primary mb-3">
                  Explore
                </a>
              </div>
            </div>
          </div>

          <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-2">
            <div class="card rounded-top-4 ">
              <img
                src="img/listing/g5.png"
                class=""
                alt="Gynecologist & Obstetrician"
              />
              <div class=" text-center bg-primary">
                <h6 class=" text-center text-white mt-3 fw-bold">
                  Gynecologist & Obstetrician
                </h6>
                <a href="#" class="btn text-primary bg-white btn-primary mb-3">
                  Explore
                </a>
              </div>
            </div>
          </div>

          <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-2">
            <div class="card rounded-top-4">
              <img
                src="img/listing/g6.png"
                class=""
                alt="Gynecologist & Obstetrician"
              />
              <div class=" text-center bg-primary">
                <h6 class=" text-center text-white mt-3">Sexologist Doctors</h6>
                <a href="#" class="btn bg-white text-primary mb-3 fw-bold">
                  Explore
                </a>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default PopularSearches;
