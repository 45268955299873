// import { allapiAction } from "@/Redux/common/action";
// import { URL } from "@/Redux/common/url";
// import { useRouter } from "next/router";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LatestListing from "./LatestListing";
import { allapiAction } from "../../Redux/common/action";
import { URL } from "../../Redux/common/url";
import { Link, useNavigate, useParams } from "react-router-dom";
import SellerHeader from "../Layout/SellerHeader";
import TruncateText from "../TruncateText/TruncateText";
import HomeSlider from "../Home/HomeSlider";
import { Button, Col, Input, Modal, Row } from "antd";
import { toast } from "react-toastify";
import { Rating } from "react-simple-star-rating";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  LinkedinShareButton,
  LinkedinIcon,
  WhatsappShareButton,
  WhatsappIcon,
  EmailShareButton,
  EmailIcon,
  MailruShareButton,
  MailruIcon,
  OKShareButton,
  OKIcon,
  PinterestShareButton,
  PinterestIcon,
  PocketShareButton,
  PocketIcon,
  RedditShareButton,
  RedditIcon,
  TelegramShareButton,
  TelegramIcon,
  TumblrShareButton,
  TumblrIcon,
} from "react-share";
import RecentProduct from "./RecentProduct";
import Seo from "../Seo/Seo";
import VendorFooter from "../Layout/VendorFooter";
import CoustomImg from "../CoustomImg/CoustomImg";
import VendorHomeSlider from "../Home/VendorHomeSlider";

function VendorStoreCom({}) {
  const router = useNavigate();
  // const { id } = router.query;
  const { id } = useParams();
  const dispatch = useDispatch();
  const [teramList, setteramList] = useState([]);
  const get_like_list = useSelector((state) =>
    state.allapi.get_like_list ? state.allapi.get_like_list : {}
  );
  const vendorDetails = useSelector((state) =>
    state.allapi.get_seller_details ? state.allapi.get_seller_details : {}
  );

  // vendorDetails?.package_id == "652902b79f69ae27bf0863e7" === > "free"
  // vendorDetails?.package_id == "652902f29f69ae27bf0863ec" === > "Sliver"
  // vendorDetails?.package_id == "652903159f69ae27bf0863f1" === > "Gold"
  // vendorDetails?.package_id == "6649acfca9a6e4295b163114" === > "Diamond"
  const get_product_list = useSelector((state) =>
    state?.allapi?.user_vendor_blog_search
      ? state?.allapi?.user_vendor_blog_search
      : {}
  );
  const [value, setValue] = useState("");
  const [valuevendor, setValuevendor] = useState("");
  const [valueuser, setvalueuser] = useState({});
  const [postList, setpostList] = useState({});
  console.log(teramList);
  console.log(postList);
  console.log(postList?.fetured);
  console.log(postList?.listing);
  const prolist = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      id: id,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const res2 = await fetch(
      URL.API_BASE_URL + `${URL.getvendorprofileproduct}`,
      requestOptions
    );

    const events2 = await res2.json();

    const postData2 = events2?.data;
    console.log(postData2);
    setpostList(postData2);
  };

  useEffect(() => {
    prolist();
    dispatch(allapiAction.getsliderList2Vendor({ page: "home", _id: id }));
    dispatch(allapiAction.getBlogListVendorpb({ page: 1, id: id }));
    GetTeam();
    if (typeof window !== "undefined") {
      setValue(localStorage.getItem("access_token"));
      setValuevendor(localStorage.getItem("access_token_vendor"));
      const userDeatail = localStorage.getItem("access_user");

      if (userDeatail) {
        const obj = JSON.parse(userDeatail);
        setvalueuser(obj);
      }
    } else {
      setValue("");
      setValuevendor("");
    }
  }, []);

  const GetTeam = async () => {
    const response = await dispatch(
      allapiAction.getVendorTeamPub({ page: 1, id: id })
    );
    setteramList(response?.data);
  };
  useEffect(() => {
    if (id) {
      if (value || valuevendor) {
        dispatch(allapiAction.userlikeget({ user_id: id }));
      }

      return () => {};
    }
  }, [id, valuevendor, value]);

  const hendletofollow = async () => {
    await dispatch(allapiAction.userfollownow({ user_id: id }));
    dispatch(allapiAction.getsellerprofile({ id: id }));
    return () => {};
  };

  const hendletoLike = async () => {
    await dispatch(allapiAction.userlikenow({ user_id: id }));
    dispatch(allapiAction.getsellerprofile({ id: id }));
    return () => {};
  };

  const [windowWidth, setWindowWidth] = useState(600);

  useLayoutEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }
    setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const [statusDataBoxReturn, setstatusDataBoxReturn] = useState(false);
  const [enquiryData, setenquiryData] = useState({});

  const onChangeData = (e) => {
    setenquiryData({ ...enquiryData, [e.target.name]: e.target.value });
  };
  const hendletoSubmitEnquiry = async () => {
    if (!enquiryData?.name) {
      toast?.error("please enter your name");
      return;
    }
    if (!enquiryData?.number) {
      toast?.error("please enter your number");
      return;
    }
    if (!enquiryData?.address) {
      toast?.error("please enter your address");
      return;
    }

    const response = await dispatch(
      allapiAction.emailenquirysubmit({
        sellerId: vendorDetails?._id,
        sellerName: vendorDetails?.displayName,
        // productId: get_product_details?._id,
        // productName: get_product_details?.title,
        userName: enquiryData?.name,
        address: enquiryData?.address,
        // country,
        // countryCode,
        whatsappNo: enquiryData?.number,
        customerEmail: enquiryData?.email,
        message: enquiryData?.message,
        subject: "Inquiry From shop",
        // sellerEmail,
        // query,
      })
    );

    console.log(response);
    if (response?.success) {
      setstatusDataBoxReturn(false);
      setenquiryData({
        name: "",
        email: "",
        number: "",
        address: "",
        message: "",
      });
    }
  };

  const [shareshow, setstshareshow] = useState(false);
  let pageUrl;

  if (typeof window !== "undefined") {
    if (window.location !== undefined) {
      // Outputs: 'https:'

      // Outputs: '/blog/react-get-current-url/'

      pageUrl = window.location.href;
    }
  }

  const [copied, setCopied] = useState(false);
  function copy() {
    const el = document.createElement("input");
    el.value = pageUrl;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    // setCopied(true);
    toast.success("Copied!");

    setstshareshow(false);
  }

  console.log(vendorDetails);
  console.log(vendorDetails?.displayName);

  const [reviewBox, setreviewBox] = useState("0");
  const [rating, setRating] = useState(0);
  console.log(rating);

  // Catch Rating value
  const handleRating = (rate) => {
    setRating(rate);

    // other logic
  };
  // Optinal callback functions
  const onPointerEnter = () => console.log("Enter");
  const onPointerLeave = () => console.log("Leave");
  const onPointerMove = (value, index) => console.log(value, index);
  const navigate = useNavigate();
  return (
    <div>
      <Seo
        settitle={vendorDetails?.displayName}
        titleSeo={vendorDetails?.displayName}
        seoKeyword={vendorDetails?.displayName}
        seoconcal={"https://bestbharat.com/vendor-store/" + vendorDetails?.slug}
        seo_descriptions={vendorDetails?.displayName}
        title={vendorDetails?.displayName}
      />
      <SellerHeader />
      {(vendorDetails?.package_id == "6649acfca9a6e4295b163114" ||
        vendorDetails?.package_id == "652903159f69ae27bf0863f1") && (
        <div className="hometest">
          <VendorHomeSlider />
        </div>
      )}

      <Modal
        title="Enquiry Now !!"
        centered
        open={statusDataBoxReturn}
        onOk={() => hendletoSubmitEnquiry(false)}
        onCancel={() => setstatusDataBoxReturn(false)}
        width={500}
      >
        <div>
          <Row>
            <Col span={24}>Store Name</Col>
            <Col span={24}>
              <Input
                name="storename"
                placeholder="enter store name..."
                value={vendorDetails?.displayName}
                // onChange={(e) => {
                //   onChangeData(e);
                // }}
              />
            </Col>
            <Col span={24}>Name</Col>
            <Col span={24}>
              <Input
                name="name"
                placeholder="enter name..."
                value={enquiryData?.name}
                onChange={(e) => {
                  onChangeData(e);
                }}
              />
            </Col>
            <Col span={24}>Number</Col>
            <Col span={24}>
              <Input
                name="number"
                placeholder="enter number..."
                value={enquiryData?.number}
                onChange={(e) => {
                  onChangeData(e);
                }}
              />
            </Col>
            <Col span={24}>Email</Col>
            <Col span={24}>
              <Input
                name="email"
                placeholder="enter email..."
                value={enquiryData?.email}
                onChange={(e) => {
                  onChangeData(e);
                }}
              />
            </Col>
            <Col span={24}>Address</Col>
            <Col span={24}>
              <Input
                name="address"
                placeholder="enter address..."
                value={enquiryData?.address}
                onChange={(e) => {
                  onChangeData(e);
                }}
              />
            </Col>
            <Col span={24}>message</Col>
            <Col span={24}>
              <Input
                name="message"
                placeholder="enter message..."
                value={enquiryData?.message}
                onChange={(e) => {
                  onChangeData(e);
                }}
              />
            </Col>
          </Row>
        </div>
      </Modal>
      <Modal
        title="Share"
        centered
        open={shareshow}
        onOk={() => setstshareshow(false)}
        onCancel={() => setstshareshow(false)}
        width={500}
        footer={null}
      >
        <div>
          <div className="row unishareIcon">
            <div className="col-2 mt-3">
              <FacebookShareButton
                url={pageUrl}
                // quote={blog?.heading}
              >
                <FacebookIcon
                  size={26}
                  round={true}
                  onClick={() => setstshareshow(false)}
                />
              </FacebookShareButton>
            </div>
            <div className="col-2 mt-3">
              <TwitterShareButton url={pageUrl}>
                <TwitterIcon
                  size={26}
                  round={true}
                  onClick={() => setstshareshow(false)}
                />
              </TwitterShareButton>
            </div>
            <div className="col-2 mt-3">
              <WhatsappShareButton url={pageUrl}>
                <WhatsappIcon
                  size={26}
                  round={true}
                  onClick={() => setstshareshow(false)}
                />
              </WhatsappShareButton>
            </div>
            <div className="col-2 mt-3">
              <LinkedinShareButton url={pageUrl}>
                <LinkedinIcon
                  size={26}
                  round={true}
                  onClick={() => setstshareshow(false)}
                />
              </LinkedinShareButton>
            </div>
            <div className="col-2 mt-3">
              <EmailShareButton url={pageUrl}>
                <EmailIcon
                  size={26}
                  round={true}
                  onClick={() => setstshareshow(false)}
                />
              </EmailShareButton>
            </div>
            <div className="col-2 mt-3">
              <MailruShareButton url={pageUrl}>
                <MailruIcon
                  size={26}
                  round={true}
                  onClick={() => setstshareshow(false)}
                />
              </MailruShareButton>
            </div>
            <div className="col-2 mt-3">
              <OKShareButton url={pageUrl}>
                <OKIcon
                  size={26}
                  round={true}
                  onClick={() => setstshareshow(false)}
                />
              </OKShareButton>
            </div>
            <div className="col-2 mt-3">
              <PinterestShareButton url={pageUrl}>
                <PinterestIcon
                  size={26}
                  round={true}
                  onClick={() => setstshareshow(false)}
                />
              </PinterestShareButton>
            </div>
            <div className="col-2 mt-3">
              <PocketShareButton url={pageUrl}>
                <PocketIcon
                  size={26}
                  round={true}
                  onClick={() => setstshareshow(false)}
                />
              </PocketShareButton>
            </div>
            <div className="col-2 mt-3">
              <RedditShareButton url={pageUrl}>
                <RedditIcon
                  size={26}
                  round={true}
                  onClick={() => setstshareshow(false)}
                />
              </RedditShareButton>
            </div>
            <div className="col-2 mt-3">
              <TelegramShareButton url={pageUrl}>
                <TelegramIcon
                  size={26}
                  round={true}
                  onClick={() => setstshareshow(false)}
                />
              </TelegramShareButton>
            </div>
            <div className="col-2 mt-3">
              <TumblrShareButton url={pageUrl}>
                <TumblrIcon
                  size={26}
                  round={true}
                  onClick={() => setstshareshow(false)}
                />
              </TumblrShareButton>
            </div>
          </div>

          <div className="shareLinkInModel">
            {" "}
            <p>
              <b>{pageUrl}</b>
            </p>
          </div>
          <div className="shareLinkInModel">
            <Button
              variant="primary"
              onClick={() => {
                copy();
                // onCloseModal();
              }}
            >
              {!copied ? "Copy link" : "Copied!"}
            </Button>
          </div>
        </div>
      </Modal>
      {/* {(vendorDetails?.package_id == "6649acfca9a6e4295b163114" ||
        vendorDetails?.package_id == "652903159f69ae27bf0863f1") && (
        <div className="marquee">
          <marquee behavior="scroll" direction="left" scrollamount="05">
            {vendorDetails?.displayName
              ? vendorDetails?.displayName
              : vendorDetails?.fullName}
          </marquee>
        </div>
      )} */}
      <div className="container-fluid vdstore">
        {(vendorDetails?.package_id == "6649acfca9a6e4295b163114" ||
          vendorDetails?.package_id == "652903159f69ae27bf0863f1") && (
          <div class="row pt-10 pb-10 mb-20 bg-light">
            <div class="col-md-4 col-6">
              <img
                src={
                  vendorDetails?.sellerProfile
                    ? URL.API_BASE_URL + vendorDetails?.sellerProfile
                    : "/img/message/sudarshanchakra.gif"
                }
                alt=""
                class="vendershopimage"
              />
            </div>
            <div class="col-md-3 col-6">
              <h4>
                {" "}
                {vendorDetails?.displayName
                  ? vendorDetails?.displayName
                  : vendorDetails?.fullName}
              </h4>
              <h6>
                {vendorDetails?.city}, {vendorDetails?.state},{" "}
                {vendorDetails?.country},{vendorDetails?.zip}
              </h6>
              {/* <h5>1234567890</h5> */}
              <div className="ps-block__footer pb-3">
                {valuevendor || value ? (
                  valueuser?._id !== id ? (
                    get_like_list?.userFollow?.status == "active" ? (
                      <button
                        className="ps-btn2  "
                        onClick={() => {
                          hendletofollow();
                        }}
                        // href={"#"}
                      >
                        {vendorDetails?.follower} Following
                      </button>
                    ) : (
                      <button
                        className="ps-btn2  "
                        onClick={() => {
                          hendletofollow();
                        }}
                        // href={"#"}
                      >
                        {vendorDetails?.follower} Follow
                      </button>
                    )
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
                {valuevendor || value ? (
                  valueuser?._id !== id ? (
                    get_like_list?.userlike?.status == "active" ? (
                      <button
                        className="ps-btn2 ml-10 "
                        onClick={() => {
                          hendletoLike();
                        }}
                        // href={"#"}
                      >
                        {vendorDetails?.like}{" "}
                        <i className="fa fa-thumbs-up"></i>
                      </button>
                    ) : (
                      <button
                        className="ps-btn2  ml-10"
                        onClick={() => {
                          hendletoLike();
                        }}
                        // href={"#"}
                      >
                        {vendorDetails?.like} <i class="far fa-thumbs-up"></i>
                      </button>
                    )
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </div>
              <div className="ps-product__shopping">
                <button
                  className="ps-btn5 mr-5 mt-5"
                  // href="#"
                  onClick={() => {
                    setstatusDataBoxReturn(true);
                  }}
                >
                  Contect Seller
                </button>
                <button
                  className="ps-btn3 mr-5 mt-5"
                  // href="#"
                  onClick={() => {
                    setstshareshow(true);
                  }}
                >
                  Share
                </button>
                <button
                  className="ps-btn4 mr-5 mt-5"
                  // href="#"
                  onClick={() => {
                    setstatusDataBoxReturn(true);
                  }}
                >
                  Enquiry
                </button>
                {/* <button
                className="ps-btn5 mr-5 mt-5"
                // href="#"
                onClick={() => {
                  setreviewBox("1");
                }}
              >
                Review
              </button> */}
              </div>
            </div>
            {windowWidth > 600 && (
              <div className="col-md-5  col-12">
                {/* postList?.fetured */}

                <div className="rjd ps-section__content mt-10 shewBox  row">
                  {postList?.fetured &&
                    postList?.fetured?.map((data, i) => {
                      return (
                        i < 3 && (
                          <div className="recentlostfound col-4" key={i}>
                            <div className="ps-product">
                              <div className="ps-product__thumbnail">
                                <Link
                                  to={
                                    data?.typeof == "Listing"
                                      ? "/listing/" + data?.pro_sulg
                                      : "/product/" + data?.pro_sulg
                                  }
                                >
                                  <CoustomImg
                                    url={URL.API_BASE_URL + data?.featureImage}
                                    altkey={data?.title}
                                    w1={200}
                                    w2={220}
                                    h1={220}
                                    h2={230}
                                  />
                                </Link>
                              </div>
                              <div className="ps-product__container">
                                <div className="ps-product__content">
                                  <Link
                                    className="ps-product__title"
                                    to={
                                      data?.typeof == "Listing"
                                        ? "/listing/" + data?.pro_sulg
                                        : "/product/" + data?.pro_sulg
                                    }
                                  >
                                    <TruncateText
                                      text={data?.title}
                                      maxLength={windowWidth > 600 ? 25 : 15}
                                    />
                                  </Link>

                                  {data?.afterdiscountprice && data?.price && (
                                    <p className="ps-product__price sale">
                                      ₹{data?.afterdiscountprice}{" "}
                                      <del>₹{data?.price} </del>
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      );
                    })}
                </div>
              </div>
            )}
            {/* <div class="col-md-3 col-3 mt-10 d-flex align-items-center justify-content-center">
            <button class="icon-button bg-primary">
              <i class="fa fa-phone" aria-hidden="true"></i>
            </button>
          </div>
          <div class="col-md-3 col-3 mt-10 d-flex align-items-center justify-content-center">
            <button class="icon-button bg-success">
              <i class="fa fa-whatsapp" aria-hidden="true"></i>
            </button>
          </div>
          <div class="col-md-3 col-3 mt-10 d-flex align-items-center justify-content-center">
            <button class="icon-button bg-warning">
              <i class="fa fa-share" aria-hidden="true"></i>
            </button>
          </div>
          <div class="col-md-3 col-3 mt-10 d-flex align-items-center justify-content-center">
            <button class="icon-button bg-danger">
              <i class="fa fa-map-marker" aria-hidden="true"></i>
            </button>
          </div> */}
          </div>
        )}

        {reviewBox == 1 && (
          <div class="ps-product-list ps-clothings  ">
            <div className="">
              <div className="review-input-section">
                <h5>Leave a Review</h5>

                <div className="row">
                  <div className="col-sm-5">
                    <Rating
                      onClick={handleRating}
                      onPointerEnter={onPointerEnter}
                      onPointerLeave={onPointerLeave}
                      onPointerMove={onPointerMove}
                      transition
                      initialRating={rating}
                      /* Available Props */
                    />

                    <Input.TextArea
                      rows={4}
                      placeholder="Write your review here..."
                      // onChange={(e) => setReviewText(e.target.value)}
                    />
                    <Button
                      className="mt-2"
                      type="primary"
                      // onClick={() => submitReview()}
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {postList?.listing?.length > 0 && (
          <div class="ps-product-list ps-clothings  ">
            <div class="ps-section__header my-5 border border-primary">
              <h3>Latest Update </h3>
            </div>
          </div>
        )}
        <div class="row p-2">
          {postList?.listing &&
            postList?.listing?.map((data, i) => {
              return (
                i < 4 && (
                  <div class="col-md-3 col-6 p-2">
                    <div class="card p-2 text-center">
                      <img
                        src={
                          data?.featureImage && data?.featureImage !== undefined
                            ? URL?.API_BASE_URL + data?.featureImage
                            : "/img/message/sudarshanchakra.gif"
                        }
                        class="mevenderfetureproduct"
                      />
                      <h4 class="mt-3">
                        {" "}
                        <Link to={"/listing/" + data?.pro_sulg}>
                          <TruncateText
                            text={data?.title}
                            maxLength={windowWidth > 600 ? 20 : 8}
                          />
                        </Link>
                      </h4>
                      {/* {data?.afterdiscountprice && (
                        <h5 class="text-success">
                          <span>MRP</span>{" "}
                          <span>{data?.afterdiscountprice}</span>
                        </h5>
                      )} */}
                    </div>
                  </div>
                )
              );
            })}
          {/* <div class="col-md-3 col-6 p-2">
            <div class="card p-2 text-center">
              <img src="/img/img1111.jpg" class="mevenderfetureproduct" />
              <h4 class="mt-3">Gold Nackless</h4>
              <h5 class="text-success">
                <span>MRP</span> <span>25,000</span>
              </h5>
            </div>
          </div>
          <div class="col-md-3 col-6 p-2">
            <div class="card p-2 text-center">
              <img src="/img/img1111.jpg" class="mevenderfetureproduct" />
              <h4 class="mt-3">Gold Nackless</h4>
              <h5 class="text-success">
                <span>MRP</span> <span>25,000</span>
              </h5>
            </div>
          </div>
          <div class="col-md-3 col-6 p-2">
            <div class="card p-2 text-center">
              <img src="/img/img1111.jpg" class="mevenderfetureproduct" />
              <h4 class="mt-3">Gold Nackless</h4>
              <h5 class="text-success">
                <span>MRP</span> <span>25,000</span>
              </h5>
            </div>
          </div>
          <div class="col-md-3 col-6 p-2">
            <div class="card p-2 text-center">
              <img src="/img/img1111.jpg" class="mevenderfetureproduct" />
              <h4 class="mt-3">Gold Nackless</h4>
              <h5 class="text-success">
                <span>MRP</span> <span>25,000</span>
              </h5>
            </div>
          </div> */}
        </div>


<div className="whyc">
<section class="why-choose-us">
        <div class="container">
            <h2>Why Choose Us</h2>
            <div class="row row-cols-1 row-cols-sm-2 row-cols-lg-3 g-4 mt-4">
                <div class="col mt-15">
                    <div class="card p-4 text-center">
                        <div class="icon">✔</div>
                        <h5 class="card-title">High Quality Service</h5>
                        <p class="card-text">We provide unmatched quality services tailored to your needs.</p>
                    </div>
                </div>
                <div class="col mt-15">
                    <div class="card p-4 text-center">
                        <div class="icon">⚡</div>
                        <h5 class="card-title">Quick Response</h5>
                        <p class="card-text">"Fast and reliable support is just a single step away."</p>
                    </div>
                </div>
                <div class="col mt-15" >
                    <div class="card p-4 text-center">
                        <div class="icon">💡</div>
                        <h5 class="card-title">Innovative Solutions</h5>
                        <p class="card-text">Creative and effective solutions for complex challenges.</p>
                    </div>
                </div>
                <div class="col mt-15">
                    <div class="card p-4 text-center">
                        <div class="icon">💼</div>
                        <h5 class="card-title">Professional Team</h5>
                        <p class="card-text">Our experienced professionals deliver exceptional results.</p>
                    </div>
                </div>
                <div class="col mt-15">
                    <div class="card p-4 text-center">
                        <div class="icon">📈</div>
                        <h5 class="card-title">Proven Results</h5>
                        <p class="card-text">Consistent success in delivering high-quality outcomes.</p>
                    </div>
                </div>
                <div class="col  mt-15">
                    <div class="card p-4 text-center">
                        <div class="icon">🌟</div>
                        <h5 class="card-title">Customer Satisfaction</h5>
                        <p class="card-text">Exceeding expectations with every interaction.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>

        {/* <div class="ps-product-list">
          <div class="ps-section__header my-5 border border-primary">
            <h3>why choose us</h3>
          </div>
        </div>
        <div class="row p-2">
          <div class="col-md-3 col-6 p-2">
            <div class="card p-2 text-center">
              <img
                src="https://api.bestbharat.com/static/createListingvendor/bjwHTlbyY-product_583_1.jpg "
                class="mevenderfetureproduct"
              />
            </div>
          </div>
          <div class="col-md-3 col-6 p-2">
            <div class="card p-2 text-center">
              <img
                src="https://api.bestbharat.com/static/createListingvendor/vSsXEEv30-product_589_1.jpg "
                class="mevenderfetureproduct"
              />
            </div>
          </div>
          <div class="col-md-3 col-6 p-2">
            <div class="card p-2 text-center">
              <img
                src="https://api.bestbharat.com/static/createListingvendor/bjwHTlbyY-product_583_1.jpg "
                class="mevenderfetureproduct"
              />
            </div>
          </div>
          <div class="col-md-3 col-6 p-2">
            <div class="card p-2 text-center">
              <img
                src="https://api.bestbharat.com/static/createListingvendor/vSsXEEv30-product_589_1.jpg "
                class="mevenderfetureproduct"
              />
            </div>
          </div>
        </div> */}

        {(vendorDetails?.package_id == "6649acfca9a6e4295b163114" ||
          (vendorDetails?.package_id == "652903159f69ae27bf0863f1" &&
            teramList?.length > 0)) && (
          <h3 class="text-center mt-3 bg-primary text-white">
            <b>Team </b>
          </h3>
        )}

        {(vendorDetails?.package_id == "6649acfca9a6e4295b163114" ||
          (vendorDetails?.package_id == "652903159f69ae27bf0863f1" &&
            teramList?.length > 0)) && (
          <div class="row m-0 p-0">
            {teramList &&
              teramList?.map((data, i) => {
                return (
                  <div class="col-md-3 col-6 p-2 m-0">
                    <div class="team-member text-center">
                      <img
                        src={URL.API_BASE_URL + data?.feature_image}
                        alt={data?.employeeName}
                        class="teammemberimage mx-auto d-block"
                      />
                      <h4>{data?.employeeName}</h4>
                      <h6 class="role">{data?.employeeDesignation}</h6>
                      <a href="#" class="fa fa-facebook"></a>
                      <a href="#" class="fa fa-twitter"></a>
                      <a href="#" class="fa fa-linkedin"></a>
                    </div>
                  </div>
                );
              })}

            {/* <div class="col-md-3 col-6 p-2 m-0">
            <div class="team-member text-center">
              <img
                src="https://citygirlbusinessclub.com/wp-content/uploads/2015/12/bigstock-Young-happy-businesswoman-in-o-26890943-12.jpg"
                alt="Team Member 2"
                class="teammemberimage mx-auto d-block"
              />
              <h4>miss Yogita</h4>
              <h6 class="role">Developer</h6>
              <a href="#" class="fa fa-facebook"></a>
              <a href="#" class="fa fa-twitter"></a>
              <a href="#" class="fa fa-linkedin"></a>
            </div>
          </div>
          <div class="col-md-3 col-6 p-2 m-0">
            <div class="team-member text-center">
              <img
                src="https://5.imimg.com/data5/QP/HX/GLADMIN-50767027/private-client-and-family-advisory-services-500x500.png"
                alt="Team Member 1"
                class="teammemberimage mx-auto d-block"
              />
              <h4>John Doe</h4>
              <h6 class="role">Designer</h6>
              <a href="#" class="fa fa-facebook"></a>
              <a href="#" class="fa fa-twitter"></a>
              <a href="#" class="fa fa-linkedin"></a>
            </div>
          </div>
          <div class="col-md-3 col-6 p-2 m-0">
            <div class="team-member text-center">
              <img
                src="https://citygirlbusinessclub.com/wp-content/uploads/2015/12/bigstock-Young-happy-businesswoman-in-o-26890943-12.jpg"
                alt="Team Member 2"
                class="teammemberimage mx-auto d-block"
              />
              <h4>miss Yogita</h4>
              <h6 class="role">Developer</h6>
              <a href="#" class="fa fa-facebook"></a>
              <a href="#" class="fa fa-twitter"></a>
              <a href="#" class="fa fa-linkedin"></a>
            </div>
          </div> */}
          </div>
        )}
        <RecentProduct postList={postList} />
        {/* <div class="ps-product-list">
          <div class="ps-section__header my-5 border border-primary">
            <h3>feature products</h3>
          </div>
        </div>
        <div class="row p-2">
          {postList?.product &&
            postList?.product?.map((data, i) => {
              return (
                <div class="col-md-3 col-6 p-2">
                  <div
                    class="card p-2 text-center
              "
                  >
                    <Link to={"/product/" + data?.pro_sulg}>
                      <img
                        src={
                          data?.featureImage && data?.featureImage !== undefined
                            ? URL?.API_BASE_URL + data?.featureImage
                            : "/img/message/sudarshanchakra.gif"
                        }
                        class="mevenderfetureproduct"
                      />
                    </Link>

                    <h4 class="mt-3">
                      <Link to={"/product/" + data?.pro_sulg}>
                        <TruncateText
                          text={data?.title}
                          maxLength={windowWidth > 600 ? 20 : 8}
                        />
                      </Link>{" "}
                    </h4>
                    {data?.afterdiscountprice && (
                      <h5 class="text-success">
                        MRP <span>{data?.afterdiscountprice}</span>
                      </h5>
                    )}
                  </div>
                </div>
              );
            })}
          <div class="col-md-3 col-6 p-2">
            <div class="card p-2 text-center">
              <img
                src="https://api.bestbharat.com/static/subsubcreateCategory/UeFWemFMI-634f4d599a912e7d15570f52-likangyue-ladies-sports-running-walking.jpg"
                class="mevenderfetureproduct"
              />
              <h4 class="mt-3">Product Name</h4>
              <h5 class="text-success">
                MRP <span>999</span>
              </h5>
            </div>
          </div>
           
        </div> */}

        {/* <div class="ps-product-list">
          <div class="ps-section__header my-5 border border-primary">
            <h3>About-us</h3>
          </div>
        </div>
        <div class="card">
          <div class="row p-10">
            <div class="col-md-4 col-6">
              <img
                src="https://api.bestbharat.com/static/subsubcreateCategory/tp56uXy7P-images_(1).jpg"
                class="aboutusimgevender"
              />
            </div>
            <div class="col-md-8 col-6">
              <ul>
                <li>Focus on your buyer persona</li>
                <li>Provide testimonials, case study data</li>
                <li>Show personality.</li>
                <li>Make your page </li>
                <li>Provide testimonials, case study data</li>
                <li>Show personality.</li>
                <li>Make your page </li>
              </ul>
            </div>
          </div>
        </div> */}

        {/* <div class="ps-product-list">
          <div class="ps-section__header my-5 border border-primary">
            <h3>News / Blogs slider</h3>
          </div>
        </div>
        <div class="row p-2">
          <div class="col-md-3 col-6 p-2">
            <div class="card p-2 text-center">
              <img
                src="https://api.bestbharat.com/static/createListingvendor/bjwHTlbyY-product_583_1.jpg"
                class="mevenderfetureproduct"
              />
            </div>
          </div>
          <div class="col-md-3 col-6 p-2">
            <div class="card p-2 text-center">
              <img
                src="https://api.bestbharat.com/static/createListingvendor/vSsXEEv30-product_589_1.jpg "
                class="mevenderfetureproduct"
              />
            </div>
          </div>
          <div class="col-md-3 col-6 p-2">
            <div class="card p-2 text-center">
              <img
                src="https://api.bestbharat.com/static/createListingvendor/bjwHTlbyY-product_583_1.jpg "
                class="mevenderfetureproduct"
              />
            </div>
          </div>
          <div class="col-md-3 col-6 p-2">
            <div class="card p-2 text-center">
              <img
                src="https://api.bestbharat.com/static/createListingvendor/vSsXEEv30-product_589_1.jpg"
                class="mevenderfetureproduct"
              />
            </div>
          </div>
        </div>

        <h3 class="text-center mt-3">Slider For Vendor</h3> */}

        {/* <div className="p-25">
          <div className="row justify-content-center mt-5">
            <div className="input-box col-12 col-sm-6 p-5">
              <i className="uil uil-search"></i>
              <input type="text" placeholder="Search here..." />
              <i className="button">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="32"
                  height="32"
                >
                  <path
                    d="M18.031 16.6168L22.3137 20.8995L20.8995 22.3137L16.6168 18.031C15.0769 19.263 13.124 20 11 20C6.032 20 2 15.968 2 11C2 6.032 6.032 2 11 2C15.968 2 20 6.032 20 11C20 13.124 19.263 15.0769 18.031 16.6168ZM16.0247 15.8748C17.2475 14.6146 18 12.8956 18 11C18 7.1325 14.8675 4 11 4C7.1325 4 4 7.1325 4 11C4 14.8675 7.1325 18 11 18C12.8956 18 14.6146 17.2475 15.8748 16.0247L16.0247 15.8748Z"
                    fill="rgba(234,173,33,1)"
                  ></path>
                </svg>
              </i>
            </div>
            <div className="ps-product--detail ps-product--fullwidth col-12 col-sm-6 p-5">
              <div className="ps-product__header">
                <div className="ps-product__sharing p-0 ml-5">
                  <a className="facebook" href="#">
                    <i className="fa fa-facebook"></i>
                  </a>
                  <a className="twitter" href="#">
                    <i className="fa fa-twitter"></i>
                  </a>
                  <a className="google" href="#">
                    <i className="fa fa-google-plus"></i>
                  </a>
                  <a className="linkedin" href="#">
                    <i className="fa fa-linkedin"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* <div id="homepage-1 ">
          <div id="homepage-1" className="px-4">
            <div className="ps-home-banner ps-home-banner--1">
              <div className="ps-container">
                <div className="ps-section__left">
                  <div className="card border border-primary">
                    <div className="row no-gutters">
                      <div className="col-md-6 p-25 pr-0">
                        <img
                          src={
                            vendorDetails?.sellerProfile
                              ? URL.API_BASE_URL + vendorDetails?.sellerProfile
                              : "/img/message/sudarshanchakra.gif"
                          }
                          alt=""
                          className="h-100 w-100"
                        />
                      </div>
                      <div className="col-md-6 p-25 pl-0">
                        <div className="ps-block__header">
                          <h4>
                            {" "}
                            {vendorDetails?.displayName
                              ? vendorDetails?.displayName
                              : vendorDetails?.fullName}
                          </h4>
                        </div>
                        <div className="ps-block__footer pb-3">
                          {valuevendor || value ? (
                            valueuser?._id !== id ? (
                              get_like_list?.userFollow?.status == "active" ? (
                                <a
                                  className="ps-btn2  "
                                  onClick={() => {
                                    hendletofollow();
                                  }}
                                  href={"#"}
                                >
                                  Following
                                </a>
                              ) : (
                                <a
                                  className="ps-btn2  "
                                  onClick={() => {
                                    hendletofollow();
                                  }}
                                  href={"#"}
                                >
                                  Follow
                                </a>
                              )
                            ) : (
                              ""
                            )
                          ) : (
                            ""
                          )}
                          {valuevendor || value ? (
                            valueuser?._id !== id ? (
                              get_like_list?.userlike?.status == "active" ? (
                                <a
                                  className="ps-btn2 ml-10 "
                                  onClick={() => {
                                    hendletoLike();
                                  }}
                                  href={"#"}
                                >
                                  <i className="fa fa-thumbs-up"></i>
                                </a>
                              ) : (
                                <a
                                  className="ps-btn2  ml-10"
                                  onClick={() => {
                                    hendletoLike();
                                  }}
                                  href={"#"}
                                >
                                  <i className="fa fa-thumbs-o-up"></i>
                                </a>
                              )
                            ) : (
                              ""
                            )
                          ) : (
                            ""
                          )}
                        </div>
                        <span className="ps-block__divider"></span>
                        <div className="ps-block__content">
                          <p>
                            <strong>Address</strong> {vendorDetails?.city},{" "}
                            {vendorDetails?.state}, {vendorDetails?.country},
                            {vendorDetails?.zip}
                          </p>
                        </div>
                        <div className="ps-block__footer pb-3">
                          <p>
                            Call us directly
                            <strong> {vendorDetails?.phone}</strong>
                          </p>
                          <p>or Or if you have any question</p>

                          <a
                            className="ps-btn ps-btn--fullwidth"
                            href={"tel:" + vendorDetails?.phone}
                          >
                            Contact Seller
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ps-section__right">
                  <a className="ps-collection" href="#">
                    <img src="/img/slider/home-1/promotion-1.jpg" alt="" />
                  </a>
                  <a className="ps-collection" href="#">
                    <img src="/img/slider/home-1/promotion-2.jpg" alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <LatestListing postList={postList} />

        {(vendorDetails?.package_id == "6649acfca9a6e4295b163114" ||
          (vendorDetails?.package_id == "652903159f69ae27bf0863f1" &&
            get_product_list &&
            get_product_list?.docs?.length > 0)) && (
          <div class="ps-product-list ps-clothings mt-5 px-3">
            <div class="ps-section__header my-5 border border-primary">
              <h3>News / Blogs</h3>
            </div>
          </div>
        )}
        {(vendorDetails?.package_id == "6649acfca9a6e4295b163114" ||
          vendorDetails?.package_id == "652903159f69ae27bf0863f1") && (
          <div class="container-fluid">
            <div class="row">
              {get_product_list &&
                get_product_list?.docs?.length > 0 &&
                get_product_list?.docs?.map((data, index) => {
                  return (
                    <div class="col-lg-4 col-md-4 col-sm-12 col-12  px-25 mt-10">
                      <div class="testimonial-item border p-15 bg-light shadow-lg">
                        <div class="d-flex align-items-center">
                          <div class="">
                            <img
                              src={URL.API_BASE_URL + data?.feature_image}
                              alt="client_image"
                            />
                          </div>
                        </div>
                        <div class="border-top text-secondary text-justify mt-4 pt-3">
                          <div class="mx-4">
                            <h4
                              class="text-dark"
                              onClick={() => {
                                navigate("/blog-details/" + data?.slug);
                              }}
                            >
                              {data?.title}
                            </h4>
                          </div>
                          <p class="mb-0">{data?.short_description}</p>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        )}
      </div>

      <VendorFooter />
    </div>
  );
}

export default VendorStoreCom;
