import React, { useEffect, useState } from "react";
import { URL } from "../../Redux/common/url";

const Sitemap = () => {
  const [products, setProducts] = useState([]);
  const [products2, setProducts2] = useState([]);
  const BASE_URL = URL?.API_BASE_URL_2; // अपना डोमेन नाम
  const BASE_URL1 = "https://example.com"; // अपना डोमेन नाम
  // URL?.API_BASE_URL
  //   URL?.API_BASE_URL_2;
  // API से प्रोडक्ट डेटा लाने का फंक्शन
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(
          URL?.API_BASE_URL + "/publicApi/getslugProduct"
        );
        const data = await response.json();

        console.log(data?.data);

        setProducts(data?.data);
        const response2 = await fetch(
          URL?.API_BASE_URL + "/publicApi/getslugListing"
        );
        const data2 = await response2.json();

        console.log(data?.data);

        setProducts2(data2?.data);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };
    fetchProducts();
  }, []);

  // Static Routes
  const staticRoutes = [
    { path: "/", changefreq: "daily", priority: 1.0 },
    { path: "/about", changefreq: "weekly", priority: 0.8 },
    { path: "/products", changefreq: "weekly", priority: 0.8 },
    { path: "/conservatism", changefreq: "weekly", priority: 0.8 },
    { path: "/student", changefreq: "weekly", priority: 0.8 },
    { path: "/add-lost-found", changefreq: "weekly", priority: 0.8 },
    { path: "/message", changefreq: "weekly", priority: 0.8 },
    { path: "/listings", changefreq: "weekly", priority: 0.8 },
    { path: "/birthdaywishes", changefreq: "weekly", priority: 0.8 },
    { path: "/weddingwishes", changefreq: "weekly", priority: 0.8 },
    { path: "/shoksandesh", changefreq: "weekly", priority: 0.8 },
    { path: "/wedding", changefreq: "weekly", priority: 0.8 },
    { path: "/all-message", changefreq: "weekly", priority: 0.8 },
    { path: "/all-job", changefreq: "weekly", priority: 0.8 },
    { path: "/job-portal", changefreq: "weekly", priority: 0.8 },
    { path: "/all-lost-and-found", changefreq: "weekly", priority: 0.8 },
  ];

  // Sitemap XML Template
  const generateSitemap = () => {
    const staticUrls = staticRoutes
      .map(
        (route) => `
        <url>
          <loc>${BASE_URL}${route.path}</loc>
          <changefreq>${route.changefreq}</changefreq>
          <priority>${route.priority}</priority>
        </url>`
      )
      .join("");

    const productUrls = products
      .map(
        (product) => `
        <url>
          <loc>${BASE_URL}/products/${product?.pro_sulg}</loc>
          <changefreq>weekly</changefreq>
          <priority>0.7</priority>
        </url>`
      )
      .join("");
    const productUrls2 = products2
      .map(
        (product) => `
        <url>
          <loc>${BASE_URL}/listing/${product?.pro_sulg}</loc>
          <changefreq>weekly</changefreq>
          <priority>0.7</priority>
        </url>`
      )
      .join("");

    return `<?xml version="1.0" encoding="UTF-8"?>
    <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
      ${staticUrls}
      ${productUrls}
      ${productUrls2}
    </urlset>`;
  };

  return (
    <div>
      <pre>{generateSitemap()}</pre>
    </div>
  );
};

export default Sitemap;
