// import { allapiAction } from "@/Redux/common/action";
// import { URL } from "@/Redux/common/url";
// import Link from "next/link";
import React, { useEffect, useLayoutEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import CoustomImg from "../CoustomImg/CoustomImg";
import HomeSlider from "../Home/HomeSlider";
import OurWork from "../OurWork/OurWork";
import moment from "moment";

import { allapiAction } from "../../Redux/common/action";
import { URL } from "../../Redux/common/url";
import { Link, useNavigate } from "react-router-dom";
import HomeSlider2 from "../Home/HomeSlider2";
import TruncateText from "../TruncateText/TruncateText";
import Seo from "../Seo/Seo";
function AllLostAndFoundCom() {
  // lostfoundlistpublic

  const router = useNavigate();

  const lost_and_found_pub = useSelector((state) =>
    state?.allapi?.lost_and_found_pub ? state?.allapi?.lost_and_found_pub : {}
  );
  const lostfoundList = lost_and_found_pub?.docs ? lost_and_found_pub?.docs : 0;
  const [page, setPage] = useState(1);
  const [category, setCategory] = useState("");
  const [state, setState] = useState("");
  const [District, setDistrict] = useState("");
  const [Citys, setCity] = useState("");
  const [CitysList, setCitysList] = useState([]);
  const [pincode, setpincode] = useState("");
  const [search, setsearch] = useState("");
  const [gender, setgender] = useState("");
  console.log(lost_and_found_pub);
  const dispatch = useDispatch();
  // const allCountry = Country.getAllCountries();
  // const allStates = State.getAllStates();

  const [value, setValue] = useState("");
  const [valuevendor, setValuevendor] = useState("");

  useEffect(() => {
    setValue(localStorage.getItem("access_token"));
    setValuevendor(localStorage.getItem("access_token_vendor"));
  }, []);

  // console.log(allStates);
  // console.log(CitysList);
  // const statesindia =
  //   allStates &&
  //   allStates?.filter((data) => {
  //     return data.countryCode == "IN";
  //   });
  // console.log(statesindia);
  // useEffect(() => {
  //   if (state) {
  //     console.log("sfdsfdskjfnsdnfn");
  //     // const statecode =
  //     //   statesindia &&
  //     //   statesindia?.filter((data) => {
  //     //     return data.name == state;
  //     //   });

  //     // console.log(state);
  //     const statecode = statesindia.find((data) => data.name == state);
  //     // console.log(statecode);
  //     const cityData = City.getCitiesOfState("IN", statecode?.isoCode);

  //     console.log(cityData);
  //     setCitysList(cityData);
  //   }
  // }, [state]);

  // console.log(statesindia);
  // console.log(allCity);
  useEffect(() => {
    dispatch(allapiAction.lostfoundlistpublic({ page: 1 }));
    dispatch(allapiAction.getsliderList({ page: "lostandfound" }));
  }, []);

  const hendletosearche = (e) => {
    setsearch(e);
    // lostfoundlistsearchpublic
    setPage(1);

    var fanalquery = { page: page, key: e };

    if (category) {
      fanalquery.category = category;
    }
    if (state) {
      fanalquery.state = state;
    }
    if (District) {
      fanalquery.District = District;
    }
    if (Citys) {
      fanalquery.City = Citys;
    }
    if (pincode) {
      fanalquery.pincode = pincode;
    }

    dispatch(allapiAction.lostfoundlistsearchpublic(fanalquery));
  };
  const hendletofiltercate = (e) => {
    setCategory(e);
    setPage(1);

    var fanalquery = { page: page, category: e };

    if (search) {
      fanalquery.key = search;
    }
    if (state) {
      fanalquery.state = state;
    }
    if (District) {
      fanalquery.District = District;
    }
    if (Citys) {
      fanalquery.City = Citys;
    }
    if (pincode) {
      fanalquery.pincode = pincode;
    }
    dispatch(allapiAction.lostfoundlistsearchpublic(fanalquery));
  };
  const hendletofilterstate = (e) => {
    setState(e);
    setPage(1);

    var fanalquery = { page: page, state: e };
    if (category) {
      fanalquery.category = category;
    }
    if (search) {
      fanalquery.key = search;
    }
    // if (state) {
    //   fanalquery.state = state;
    // }
    if (District) {
      fanalquery.District = District;
    }
    if (Citys) {
      fanalquery.City = Citys;
    }
    if (pincode) {
      fanalquery.pincode = pincode;
    }
    dispatch(allapiAction.lostfoundlistsearchpublic(fanalquery));
  };
  const hendletofilterdis = (e) => {
    setDistrict(e);
    setPage(1);
    setgender("");

    var fanalquery = { page: page, District: e };
    if (category) {
      fanalquery.category = category;
    }
    if (search) {
      fanalquery.key = search;
    }
    if (state) {
      fanalquery.state = state;
    }
    // if (District) {
    //   fanalquery.District = District;
    // }
    if (Citys) {
      fanalquery.City = Citys;
    }
    if (pincode) {
      fanalquery.pincode = pincode;
    }
    dispatch(allapiAction.lostfoundlistsearchpublic(fanalquery));
  };
  const hendletofilterdisgender = (e) => {
    // setDistrict(e);
    setgender(e);
    setPage(1);

    var fanalquery = { page: page, gender: e };
    if (category) {
      fanalquery.category = category;
    }
    if (search) {
      fanalquery.key = search;
    }
    if (state) {
      fanalquery.state = state;
    }
    if (District) {
      fanalquery.District = District;
    }
    if (Citys) {
      fanalquery.City = Citys;
    }
    if (pincode) {
      fanalquery.pincode = pincode;
    }
    dispatch(allapiAction.lostfoundlistsearchpublic(fanalquery));
  };
  const hendletofilterCity = (e) => {
    setCity(e);
    setPage(1);

    var fanalquery = { page: page, City: e };
    if (category) {
      fanalquery.category = category;
    }
    if (search) {
      fanalquery.key = search;
    }
    if (state) {
      fanalquery.state = state;
    }
    if (District) {
      fanalquery.District = District;
    }
    // if (City) {
    //   fanalquery.City = City;
    // }
    if (pincode) {
      fanalquery.pincode = pincode;
    }
    dispatch(allapiAction.lostfoundlistsearchpublic(fanalquery));
  };
  const hendletofilterPin = (e) => {
    setpincode(e);
    setPage(1);

    var fanalquery = { page: page, pincode: e };
    if (category) {
      fanalquery.category = category;
    }
    if (search) {
      fanalquery.key = search;
    }
    if (state) {
      fanalquery.state = state;
    }
    if (District) {
      fanalquery.District = District;
    }
    if (Citys) {
      fanalquery.City = Citys;
    }
    // if (pincode) {
    //   fanalquery.pincode = pincode;
    // }
    dispatch(allapiAction.lostfoundlistsearchpublic(fanalquery));
  };

  const items = lost_and_found_pub?.totalDocs;
  const itemsPerPage = 12;
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setPageCount(Math.ceil(items && items / itemsPerPage));
  }, [itemOffset, itemsPerPage, lost_and_found_pub, items]);
  const [windowWidth, setWindowWidth] = useState(600);
  const handlePageClick = (event) => {
    const data = event?.selected + 1;
    const newOffset = (event.selected * itemsPerPage) % items;
    setItemOffset(newOffset);
    setPage(data);
    dispatch(allapiAction.lostfoundlistpublic({ page: data }));
    if (windowWidth < 600) {
      window.scrollTo(350, 350);
    } else {
      window.scrollTo(1150, 1150);
    }
  };

  const hendletoAddNewLostAndFoud = () => {
    if (valuevendor || value) {
      router("/add-lost-found");
    } else {
      router("/login");
    }
  };

  // Update window width when the window is resized
  useLayoutEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }
    setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div>
      <Seo
        settitle={
          "India's Lost and Found Item Listing Portal"
        }
        titleSeo={
          "Find Lost Items Online | List Missing Items on Best Bharat Lost & Found Portal"
        }
        seoKeyword={
          "Lost and found items India, List lost items online, Found item listings, Missing items portal, Lost and found website India, Lost belongings platform, List missing gadgets, Recover missing items India, Best Bharat lost and found, Item listing portal for lost belongings"
        }
        seo_descriptions={
          "List lost or found items online with Best Bharat's Lost & Found Portal. Easily browse or add missing items like documents, gadgets, pets, and more. Connect with others to recover your belongings."
        }
        seoconcal={"https://bestbharat.com/"}
        title={
          "India's Lost and Found Item Listing Portal"
        }
      />
      <div className="hometest">
        <HomeSlider2 />
        <div className="container-fluid shewBox mt-5">
          <h4 class="text-center mt-3">Video(How To add Lost And Found)</h4>
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 mb-2 p-2">
              <img src="/img/youtubelostfound1.webp" alt="first" />
              <div
                className="playIcon5"
                onClick={() => {
                  window?.open(
                    "https://youtube.com/shorts/O-CbYlaCKIg?si=pb5llRAseYWNS1Pm",
                    "_blank"
                  );
                }}
              ></div>
              {/* <iframe
                class="my_videos"
                src="https://www.youtube.com/embed/oAQaxqY9gH4"
                frameborder="0"
                allowfullscreen
              ></iframe> */}
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 mb-2 p-2">
              <img src="/img/youtubelostfound2.webp" alt="first" />
              <div
                className="playIcon5"
                onClick={() => {
                  window?.open(
                    "https://youtu.be/OAxEA1eTo9w?si=uuW64w07sD6WpMqL",
                    "_blank"
                  );
                }}
              ></div>
              {/* <iframe
                class="my_videos"
                src="https://www.youtube.com/embed/oAQaxqY9gH4"
                frameborder="0"
                allowfullscreen
              ></iframe> */}
            </div>
          </div>
        </div>
      </div>
      {/* <hr /> */}
      <div className="ps-product-list ps-clothings mt-5 px-3">
        <div className="ps-section__header my-5 border border-primary">
          <h3>LOST AND FOUND</h3>
          <button
            className="btn btn-primary"
            onClick={() => {
              hendletoAddNewLostAndFoud();
            }}
          >
            Add Lost And Found
          </button>
        </div>
        <div className="ps-block__right"></div>
      </div>
      {/* <div className="ps-product-list ps-clothings mt-5 px-3 App">
        <div className="ps-section__header1 my-2   App">
          <h3 className="App">LOST AND FOUND</h3>
          
        </div>
        <div className="ps-block__right"><button className="btn btn-primary">Add New</button></div>
      </div> */}
      <div className="m-5">
        <div className="row">
          {/* <div className="col-md-4"></div> */}
          <div className="col-md-3 col-sm-3 col-6">
            <input
              type="text"
              className="form-control"
              placeholder="Search For Lost and Found"
              onChange={(e) => {
                hendletosearche(e?.target?.value);
              }}
            />
          </div>
          <div className="col-md-3 col-sm-3 col-6">
            <select
              id="inputState"
              className="form-control shadow-lg border-white"
              onChange={(e) => {
                hendletofiltercate(e?.target?.value);
              }}
            >
              <option value={""}>Search lost and found</option>
              <option value={"found"}>Found(पाया )</option>
              <option value={"lost"}>Lost(खोया)</option>
            </select>
          </div>
          <div className="col-md-3 col-sm-3 col-6">
            <select
              id="inputState"
              className="form-control shadow-lg bg-white"
              onChange={(e) => {
                hendletofilterdis(e?.target?.value);
              }}
            >
              {/* <option selected>District</option> */}

              <option value={""}>Search For Sub Category</option>
              <option value={"Family Member"}>
                Family member(परिवार का सदस्य)
              </option>
              <option value={"Pet-animal"}>Pet-animal(पालतू जानवर)</option>
              <option value={"Product-Document-other"}>
                Product-Document-other(सामान -दस्तावेज-अन्य)
              </option>
            </select>
          </div>
          {District == "Family Member" && (
            <div className="col-sm-3 col-md-3 col-6 mb-2">
              <select
                id="inputState"
                className="form-control shadow-lg bg-white"
                onChange={(e) => {
                  hendletofilterdisgender(e?.target?.value);
                }}
              >
                {/* <option selected>District</option> */}

                <option value={""}>Select gender</option>
                {/* <option value={"Family Member"}>
                Family member(परिवार का सदस्य)
              </option> */}
                <option value={"male"}>Male</option>
                <option value={"female"}>Female</option>
              </select>
            </div>
          )}

          <div className="col-sm-3 col-6 mb-2">
            <select
              id="inputState"
              className="form-control shadow-lg bg-white"
              onChange={(e) => {
                hendletofilterstate(e?.target?.value);
              }}
            >
              <option value={""}>Search For State</option>

              {/* {statesindia &&
                statesindia?.map((data, i) => {
                  return (
                    <option key={i} value={data?.name}>
                      {data?.name}
                    </option>
                  );
                })} */}
            </select>
          </div>
          {/* <div className="col-sm-3 mb-2">
            <select
              id="inputState"
              className="form-control shadow-lg bg-white"
              onChange={(e) => {
                hendletofilterdis(e?.target?.value);
              }}
            >
              <option selected>District</option>

              <option value={"jaipur"}>Jaipur</option>
              <option>District-2</option>
              <option>District-3</option>
            </select>
          </div> */}
          {state && (
            <div className="col-sm-3 col-6 mb-2">
              <select
                id="inputState"
                className="form-control shadow-lg bg-white"
                onChange={(e) => {
                  hendletofilterCity(e?.target?.value);
                }}
              >
                <option value={""}>Search For City</option>

                {CitysList &&
                  CitysList?.map((data, i) => {
                    return (
                      <option key={i} value={data?.name}>
                        {data?.name}
                      </option>
                    );
                  })}
                {/* <option>City-2</option>
              <option>City-3</option> */}
              </select>
            </div>
          )}
          <div className="col-sm-3 col-6 mb-2">
            {/* <select id="inputState" className="form-control shadow-lg bg-white">
              <option selected>Pin Code</option>
              <option>302011</option>
              <option>302012</option>
              <option>302013</option>
            </select> */}
            <input
              type="text"
              className="form-control"
              placeholder="Enter pincode"
              onChange={(e) => {
                hendletofilterPin(e?.target?.value);
              }}
            />
          </div>
        </div>
        <div className="row mt-5"></div>
      </div>

      {/* <div className="ps-product-list ps-clothings mt-5 px-3">
        <div className="ps-section__header my-5 border border-primary">
          <h3>RECENT LOST AND FOUND</h3>
        </div>
        <div className="ps-block__right"></div>
      </div> */}
      {/* </div> */}

      <div className="container-fluid shewBox">
        {lostfoundList?.length > 0 ? (
          <div className="row">
            {lostfoundList &&
              lostfoundList?.map((data, i) => {
                const delaydata = i * 100;
                return (
                  <div
                    className="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-6 mb-2"
                    key={i}
                    data-aos="fade-right"
                    data-aos-delay={delaydata}
                  >
                    <div className="recentlostfound">
                      <div className="ps-product ps-product--inner p-4">
                        <div className="ps-product__thumbnail">
                          <div
                            className={
                              data?.subcategoryId == "found"
                                ? "ps-product__badge hot"
                                : "ps-product__badge"
                            }
                          >
                            {data?.subcategoryId}
                          </div>
                          <Link to={"/lost-and-found/" + data?.slug}>
                            {/* <img
                                src={URL?.API_BASE_URL + data?.image}
                                alt=""
                              /> */}
                            <CoustomImg
                              url={URL.API_BASE_URL + data?.image}
                              altkey={data?.title}
                              w1={165}
                              w2={250}
                              h1={80}
                              h2={120}
                            />
                          </Link>
                        </div>
                        <div className="ps-product__container">
                          <div className="ps-product__content">
                            <Link
                              className="ps-product__title"
                              to={`/lost-and-found/${data?.slug}`}
                            >
                              <TruncateText
                                text={data?.name}
                                maxLength={windowWidth > 600 ? 24 : 18}
                              />
                            </Link>

                            <div
                              className="ps-product__progress-bar ps-progress"
                              data-value="82"
                            >
                              {/* <p className="lostDate">
                              {moment(data?.createdAt).format(" DD/MM/YYYY ")}
                            
                            </p> */}
                              <p className="lostDate">
                                <TruncateText
                                  text={data?.district}
                                  maxLength={windowWidth > 600 ? 18 : 15}
                                />
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        ) : (
          <div className="row justify-content-center align-items-center">
            <div
              className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
              style={{ textAlign: "center" }}
            >
              <h3 style={{ marginTop: "50px" }}>Data Not Found</h3>
            </div>
          </div>
        )}
      </div>
      <div className="container mt-20">
        <nav
          className="d-flex justify-content-between"
          aria-label="Page navigation"
        >
          <ReactPaginate
            breakLabel="..."
            nextLabel="Next"
            onPageChange={handlePageClick}
            className="paging_simple_numbersc pagination"
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="Previous"
            renderOnZeroPageCount={null}
          />
        </nav>
      </div>
      <div className="mt-25">
        <OurWork />
      </div>
    </div>
  );
}

export default AllLostAndFoundCom;
