import React, { useEffect, useState } from "react";
import { Col, Input, Row, Table, Tooltip } from "antd";

import { useDispatch, useSelector } from "react-redux";

import moment from "moment";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Button, Modal, Space } from "antd";

import HeadingBack from "../../HeadingBack";
// import { useRouter } from "next/router";
// import { URL } from "@/Redux/common/url";
// import { allapiAction } from "@/Redux/common/action";
import SvgIcon from "../../const/SvgIcon";
import { Image } from "react-bootstrap";
import { allapiAction } from "../../../Redux/common/action";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { URL } from "../../../Redux/common/url";

function EnquerySellerComponent() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const pagev = searchParams.get("page");
  const searchData = searchParams.get("search");
  // const pagev = navigate?.query?.page;
  // const searchData = navigate?.query?.search;

  const { confirm } = Modal;

  const page = pagev == null ? 1 : pagev;
  const search = searchData == null ? "" : searchData;

  const get_product_list = useSelector((state) =>
    state?.allapi?.get_email_qui ? state?.allapi?.get_email_qui : {}
  );

  const [pages, setPage] = useState(1);

  const listdata = get_product_list?.docs ? get_product_list?.docs : [];

  useEffect(() => {
    // if (search) {
    //   dispatch(allapiAction.searchListing({ page: page, key: search }));
    // } else {
    dispatch(allapiAction.getEmailenquirySeller(page));
    // }

    return () => {};
  }, [location.search]);

  const showDeleteConfirm = (e) => {
    confirm({
      title: "Are you sure delete ?",
      icon: <ExclamationCircleFilled />,
      content: "Some descriptions",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        hendleDelete(e);
        console.log("OK");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const hendleDelete = (e) => {
    // deleteProduct
    // setShowDelete(false);
    dispatch(allapiAction.deleteProduct({ productId: e, pageNo: page }));
    // dispatch(adminAction.getRoleList('dhsg'))
    return () => {};
  };
  const dataSource = [
    {
      key: "1",
      name: "Mike",
      age: 32,
      address: "10 Downing Street",
    },
    {
      key: "2",
      name: "John",
      age: 42,
      address: "10 Downing Street",
    },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userId, setuserId] = useState("");

  const [statusData, setstatusData] = useState({});
  const [userData, setuserData] = useState({});
  const [statusDataBox, setstatusDataBox] = useState(false);

  const hendleToViewM = (e) => {
    setstatusDataBox(true);
    setuserData(e);
  };

  const hendleTochangedata = (e) => {
    const { name, value } = e.target;

    setstatusData({ ...statusData, [name]: value });
  };

  const hendleToSubmetUpdate = () => {
    // dispatch(
    //   allapiAction.addFoundByAdmin({
    //     id: userData?._id,
    //     amount: statusData?.amount,

    //     pageNumber: page,
    //   })
    // );

    setstatusDataBox(false);
  };

  const showModal = (e) => {
    setuserId(e);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    // dispatch(
    //   allapiAction.user_inactive({
    //     _id: userId,
    //     status: userId,
    //     // active: 0,
    //     pageNumber: page,
    //   })
    // );
    setuserId("");
    setIsModalOpen(false);
    return () => {};
  };

  const hendleToChangeStatus = (id, status) => {
    // dispatch(
    //   allapiAction.user_inactive({
    //     _id: id,
    //     status: status,
    //     // active: 0,
    //     pageNumber: page,
    //   })
    // );
    // setuserId("");
    // setIsModalOpen(false);
    return () => {};
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const hendleToStatus = (id, e) => {
    // statusCategory

    // dispatch(allapiAction.statusProduct({ productId: id, status: e }));
    // dispatch(adminAction.getRoleList('dhsg'))
    return () => {};
  };
  const hendleToStatusfuture = (id, e) => {
    // statusCategory

    dispatch(
      allapiAction.futuredListing({ productId: id, status: e, pageNo: page })
    );
    // dispatch(adminAction.getRoleList('dhsg'))
    return () => {};
  };

  const columns = [
    {
      title: "S.No.",
      dataIndex: "sno",
      key: "sno",
      className: "table_first_col",
      render: (value, item, index) => (page - 1) * 10 + index + 1,
    },
    // {
    //   title: "Vendor Id",
    //   dataIndex: "vendorId",
    //   key: "vendorId",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         {/* <Tooltip title="Contact"> */}
    //         <span
    //           className="cursor-pointer coustomNevc"
    //           // onClick={() => navigate(`/user-details/${item?.referId}`)}
    //         >
    //           {item?.vendorId?._id}
    //         </span>
    //       </>
    //     );
    //   },
    // },
    {
      title: "User Name",
      dataIndex: "name",
      key: "name",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer coustomNevc"
              // onClick={() => navigate(`/user-details/${item?.referId}`)}
            >
              {item?.userName}
            </span>
          </>
        );
      },
    },
    {
      title: "User Email",
      dataIndex: "email",
      key: "email",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer coustomNevc"
              // onClick={() => navigate(`/user-details/${item?.referId}`)}
            >
              {item?.userEmail}
            </span>
          </>
        );
      },
    },
    {
      title: "User phone",
      dataIndex: "phone",
      key: "phone",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer coustomNevc"
              // onClick={() => navigate(`/user-details/${item?.referId}`)}
            >
              {item?.whatsappNo}
            </span>
          </>
        );
      },
    },
    {
      title: "Vendor Name",
      dataIndex: "vname",
      key: "vname",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer coustomNevc"
              // onClick={() => navigate(`/user-details/${item?.referId}`)}
            >
              {item?.sellerName}
            </span>
          </>
        );
      },
    },

    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
    },
    {
      title: "Product Name",
      dataIndex: "productName",
      key: "productName",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    // {
    //   title: "admin commission %",
    //   dataIndex: "admincommission",
    //   key: "admincommission",
    // },
    // {
    //   title: "admin commission Amount",
    //   dataIndex: "admincommissionAmount",
    //   key: "admincommissionAmount",
    // },
    // {
    //   title: "Payable amount",
    //   dataIndex: "payableamount",
    //   key: "payableamount",
    // },
    // {
    //   title: "Wallet",
    //   dataIndex: "balance",
    //   key: "balance",
    // },
    // {
    //   title: "Role",
    //   dataIndex: "role_name",
    //   key: "role_name",
    // },
    // {
    //   title: "Wallet",
    //   dataIndex: "Wallet",
    //   key: "Wallet",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>

    //         <span
    //           className="cursor-pointer"
    //           // onClick={() => navigate(`/users/contact/${item?.id}`)}
    //         >
    //           {item?.walletId?.closeBal}
    //         </span>
    //       </>
    //     );
    //   },
    // },
    // {
    //   title: "Reffer Code",
    //   dataIndex: "referCode",
    //   key: "referCode",
    // },

    // {
    //   title: "Rank",
    //   dataIndex: "status",
    //   key: "status",
    //   // className: "table_action_col",
    //   render: (value, item, index) => {
    //     const userRank =
    //       item?.status == "0"
    //         ? "free"
    //         : item?.status == "1"
    //         ? "FRANCHISE"
    //         : item?.status == "2"
    //         ? "BARON"
    //         : item?.status == "3"
    //         ? "COUNT"
    //         : item?.status == "4"
    //         ? "MARQUISE"
    //         : item?.status == "4"
    //         ? "DUKE"
    //         : "ARCH DUKE";
    //     return (
    //       <>
    //         <span
    //           className="cursor-pointer"
    //           // onClick={() => navigate(`/users/contact/${item?.id}`)}
    //         >
    //           {userRank}
    //         </span>

    //       </>
    //     );
    //   },
    // },
    // {
    //   title: "Ekyc",
    //   dataIndex: "kycStatus",
    //   key: "kycStatus",

    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         {/* <Tooltip title="Contact"> */}

    //         <span
    //           className={
    //             item?.kycStatus == "Approved"
    //               ? "cursor-pointer coustomtrue"
    //               : "cursor-pointer coustomfalse"
    //           }
    //           onClick={() => hendleToKycStatus(item)}
    //           // onClick={() => navigate(`/user-details/${item?.referId}`)}
    //         >
    //           {item?.kycStatus}
    //         </span>
    //       </>
    //     );
    //   },
    // },
    // {
    //   title: "Status",
    //   dataIndex: "user_status",
    //   key: "user_status",
    //   // className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         <Tooltip title={item?.referalstatus}>
    //           <button
    //             className={
    //               item?.referalstatus == "complete"
    //                 ? "success-button ml-3"
    //                 : "delete-button2 ml-3"
    //             }
    //             onClick={() => hendleToViewEdit(item)}
    //             // onClick={() =>
    //             //   hendleToChangeStatus(
    //             //     item?._id,
    //             //     item?.user_status == "Active" ? "Inactive" : "Active"
    //             //   )
    //             // }
    //           >
    //             {item?.referalstatus}
    //           </button>
    //         </Tooltip>
    //       </>
    //     );
    //   },
    // },

    {
      title: "Date",
      dataIndex: "Join Date",
      key: "Join Date",
      // className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer"
              // onClick={() => navigate(`/users/contact/${item?.id}`)}
            >
              {moment(item?.createdAt).format(" DD/MM/YYYY ")}
            </span>
          </>
        );
      },
    },
    // {
    //   title: "Student Mobile",
    //   dataIndex: "student_mobile",
    //   key: "student_mobile",
    // },
    // {
    //   title: "Parent Name",
    //   dataIndex: "parent_name",
    //   key: "parent_name",
    // },
    // {
    //   title: "Parent Mobile",
    //   dataIndex: "parent_mobile",
    //   key: "parent_mobile",
    // },
    // {
    //   title: "Address",
    //   dataIndex: "address",
    //   key: "address",
    // },
    // {
    //   title: "Actions",
    //   dataIndex: "action",
    //   key: "action",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         <Tooltip title="View">
    //           <span
    //             className="cursor-pointer"
    //             onClick={() => hendleToViewUser(item)}
    //           >
    //             <SvgIcon.Visibility className="scale-icon edit-icon" />
    //           </span>
    //         </Tooltip>
    //         {/* <Tooltip title="delete">
    //           <span
    //             className="cursor-pointer ml-3"
    //             onClick={() => showModal(item?._id)}
    //           >
    //             <SvgIcon.deletebtn className="scale-icon edit-icon" />

    //           </span>
    //         </Tooltip> */}
    //         {/* <Tooltip title="Edit">
    //           <span
    //             className="cursor-pointer ml-3"
    //             onClick={() => hendleToViewEdit(item)}
    //           >
    //             <SvgIcon.Pencil className="scale-icon edit-icon" />

    //           </span>
    //         </Tooltip> */}
    //         {/* {item?.userlicence == "1" && (
    //           <Tooltip title="license assign">
    //             <button
    //               className="admin-button ml-3 mt-3"
    //               onClick={() => hendleToViewLicence(item)}
    //             >
    //               License Assign
    //             </button>
    //           </Tooltip>
    //         )}

    //         <Tooltip title="Edit Kyc">
    //           <button
    //             className="admin-button ml-3 mt-3"
    //             onClick={() => hendleToViewEditKyc(item)}
    //           >
    //             Edit Kyc
    //           </button>
    //         </Tooltip>

    //         <Tooltip title="Add Point">
    //           <button
    //             className="admin-button ml-3  mt-3"
    //             onClick={() => hendleToViewM(item)}
    //           >
    //             Add Point
    //           </button>
    //         </Tooltip> */}
    //       </>
    //     );
    //   },
    // },
  ];

  const [searchs, setsearch] = useState("");
  const handleChange = (e) => {
    setsearch(e);
    setPage(1);
    navigate("/seller/enquery?page=" + 1 + "&search=" + e);
    // if (e) {
    //   dispatch(allapiAction.getuserListSearch({ page: 1, search: e }));
    //   return () => {};
    // } else {
    //   dispatch(allapiAction.getuserList(1));

    //   return () => {};
    // }
    // getuserListSearch
    // &user_id=${data?.user_id}&search=${data?.user_id}
  };

  const hendlePahegi = (e) => {
    setPage(e);

    navigate("/seller/enquery?page=" + e + "&&search=" + search);

    // if (search) {
    //   dispatch(allapiAction.getuserListSearch({ page: e, search: search }));
    //   return () => {};
    // } else {
    //   dispatch(allapiAction.getuserList(e));

    //   return () => {};
    // }
  };

  return (
    <div>
      <Modal
        title="delete User"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>Are you sure delete</p>
      </Modal>

      <Modal
        title="Add Amount"
        centered
        open={statusDataBox}
        onOk={() => hendleToSubmetUpdate(false)}
        onCancel={() => setstatusDataBox(false)}
        width={500}
      >
        <div>
          <Row>
            <Col span={24}>Amount</Col>
            <Col span={24}>
              <Input
                name="amount"
                placeholder="enter amount"
                value={statusData?.amount}
                onChange={(e) => {
                  hendleTochangedata(e);
                }}
              />
            </Col>
          </Row>
        </div>
      </Modal>

      <div className="flex">
        <div>
          <HeadingBack
            title={"Enquery"}
            subHeading={`Total ${get_product_list?.totalDocs || 0} Enquery`}
          />
        </div>
        <Col md={5} sm={5} xs={12} className="pl-5  ml-5">
          {/* <Input
            type="text"
            autoComplete="new-off"
            className="form-control"
            onChange={(e) => handleChange(e.target.value)}
            placeholder="Search..."
            // value={values?.TestCode}
          /> */}
        </Col>

        <div className="flex1 flex justify-content-end align-items-center">
          <Tooltip title="Add">
            <span
              className="jop_btn"
              onClick={
                () => navigate("/seller/listing/add-listing")
                // (window.location.href = "/seller/listing/add-listing")
              }
            >
              Add
              {/* <SvgIcon.Add width={40} height={40} className="cursor-pointer" /> */}
            </span>
          </Tooltip>
        </div>
      </div>

      <div className="tableResponsive mt-10">
        <Table
          className={"table antd_table"}
          dataSource={listdata}
          columns={columns}
          pagination={{
            current: parseInt(page),
            pageSize: 10,
            onChange(current, pageSize) {
              hendlePahegi(current);
            },
            hideOnSinglePage: true,
            total: get_product_list?.totalDocs
              ? get_product_list?.totalDocs
              : 0,
          }}
        />
      </div>
    </div>
  );
}

export default EnquerySellerComponent;
