import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { allapiAction } from "../../Redux/common/action";
import { URL } from "../../Redux/common/url";
import moment from "moment";
import { useNavigate } from "react-router-dom";
function BlogListpub() {
  // getBlogListPub
  const navigate = useNavigate();
  const blog_list = useSelector((state) =>
    state?.allapi?.blog_list ? state?.allapi?.blog_list : {}
  );
  const get_blog_category_list = useSelector((state) =>
    state?.allapi?.get_blog_category_list
      ? state?.allapi?.get_blog_category_list
      : []
  );
  const get_recent_blog_list = useSelector((state) =>
    state?.allapi?.get_recent_blog_list
      ? state?.allapi?.get_recent_blog_list
      : []
  );

  console.log(get_recent_blog_list);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(allapiAction.getBlogListPub(1));
    // dispatch(allapiAction.getblogcateAllList({}));
    dispatch(allapiAction.getrectentBlog({}));

    return () => {};
  }, []);

  return (
    <div>
      {" "}
      <div class="page-title">
        <div class="container-fluid">
          <div class="page-caption text-center">
            <h1>
              <br />
              Blogs
            </h1>
          </div>
        </div>
      </div>
      <div class="container mt-5">
        <div class="row">
          <div class="col-sm-8">
            {blog_list?.docs &&
              blog_list?.docs?.map((data, i) => {
                return (
                  <div class="row">
                    <div class="col-12 mt-3">
                      <img
                        src={URL?.API_BASE_URL + data?.feature_image}
                        class="img-fluid"
                      />
                    </div>
                    <div class="col-12 mt-2">
                      <p>
                        {/* <i class="fa fa-edit"></i>&nbsp;Software */}
                        &nbsp;&nbsp;&nbsp;
                        <i class="fa fa-car"></i>&nbsp;
                        {/* June 08, 2024 */}
                        {moment(data?.createdAt).format(" MMM DD, YYYY")}
                      </p>
                      <h3>{data?.title}</h3>
                      <p>{data?.short_description}</p>
                      <button
                        type="button"
                        class="btn btn-primary btn-lg"
                        onClick={() => {
                          navigate("/blog-details/" + data?.slug);
                        }}
                      >
                        READ MORE{" "}
                      </button>
                    </div>
                    {/* <div class="col-12 mt-3">
                      <img
                        src="https://creatosoftware.com/public/uploads/news-11.jpg"
                        class="img-fluid"
                      />
                    </div>
                    <div class="col-12 mt-2">
                      <p>
                        <i class="fa fa-edit"></i>&nbsp;Software
                        &nbsp;&nbsp;&nbsp;
                        <i class="fa fa-car"></i>&nbsp;June 08, 2024
                      </p>
                      <h3>Game is legal ?</h3>
                      <p>Which game is legal ?</p>
                      <button type="button" class="btn btn-primary btn-lg">
                        READ MORE{" "}
                      </button>
                    </div> */}
                  </div>
                );
              })}
          </div>

          <div class="col-sm-4">
            <div class="row">
              {/* <div class="col-12 border py-3 mt-4">
                <h3>
                  <u>CATEGORIES</u>
                </h3>

                {get_blog_category_list &&
                  get_blog_category_list?.map((data, i) => {
                    return <p>&nbsp;{data?.name}</p>;
                  })}
                 
              </div> */}

              <div class="col-12 border py-3 mt-4">
                <h3>
                  <u>RECENT POSTS</u>
                </h3>

                {get_recent_blog_list &&
                  get_recent_blog_list?.map((data, i) => {
                    return (
                      <div class="row">
                        <div class="col-6 mt-4">
                          <img
                            src={URL?.API_BASE_URL + data?.feature_image}
                            class="img-fluid"
                          />
                        </div>
                        <div class="col-6 mt-4">
                          <p class="m-0 p-0">
                            <b>{data?.title}</b>
                          </p>
                          <p>
                            {" "}
                            {moment(data?.createdAt).format(" MMM DD, YYYY")}
                          </p>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>

        {/* <div class="row">
          <div class="col-12 text-center mt-3">
            <h1>
              <u>OUR BLOGS</u>
            </h1>
          </div>
        </div> */}

        {/* <div
          id="carouselExampleControls"
          class="carousel slide"
          data-ride="carousel"
        >
          <div class="carousel-inner">
            <div class="carousel-item active">
              <div class="row">
                <div class="col-12">
                  <div class="row  m">
                    <div class="col-md-3 col-sm-6 col-6 ">
                      <div class="row">
                        <div class="col-12">
                          <div class="row m-1 ggg">
                            <div class="col-3"></div>
                            <div class="col-6 text-center">
                              <div class=" hhh p-1 gyy">
                                <img
                                  src="https://utouchdesign.com/themes/envato/escort/assets/img/company_logo_1.png"
                                  class="img-fluid  rounded-circle"
                                  width="110"
                                />
                              </div>
                            </div>
                            <div class="col-3"></div>
                            <div class="col-12 mt-2 text-center ">
                              <h4 class="htg">Product Redesign</h4>
                            </div>
                            <div class="col-12 p-0 text-center text-muted">
                              <p class="htgg">2708 Scenic Way, IL 62373</p>
                            </div>
                            <div class="col-12 text-center">
                              <button
                                type="button"
                                class="btn but btn-outline-success"
                              >
                                APPLY NOW
                              </button>
                            </div>
                            <br />
                            <br />
                            <br />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3 col-sm-6 col-6 ">
                      <div class="row">
                        <div class="col-12">
                          <div class="row m-1 ggg">
                            <div class="col-3"></div>
                            <div class="col-6 text-center">
                              <div class=" hhh p-1 gyy">
                                <img
                                  src="https://utouchdesign.com/themes/envato/escort/assets/img/company_logo_1.png"
                                  class="img-fluid  rounded-circle"
                                  width="110"
                                />
                              </div>
                            </div>
                            <div class="col-3"></div>
                            <div class="col-12 mt-2 text-center">
                              <h4 class="htg">Product Redesign</h4>
                            </div>
                            <div class="col-12 p-0 text-center text-muted">
                              <p class="htgg">2708 Scenic Way, IL 62373</p>
                            </div>
                            <div class="col-12 text-center">
                              <button
                                type="button"
                                class="btn but btn-outline-success"
                              >
                                APPLY NOW
                              </button>
                            </div>
                            <br />
                            <br />
                            <br />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3 col-sm-6 col-6  ">
                      <div class="row">
                        <div class="col-12">
                          <div class="row m-1 ggg">
                            <div class="col-3"></div>
                            <div class="col-6 text-center">
                              <div class=" hhh p-1 gyy">
                                <img
                                  src="https://utouchdesign.com/themes/envato/escort/assets/img/company_logo_1.png"
                                  class="img-fluid  rounded-circle"
                                  width="110"
                                />
                              </div>
                            </div>
                            <div class="col-3"></div>
                            <div class="col-12 mt-2 text-center">
                              <h4 class="htg">Product Redesign</h4>
                            </div>
                            <div class="col-12 p-0 text-center text-muted">
                              <p class="htgg">2708 Scenic Way, IL 62373</p>
                            </div>
                            <div class="col-12 text-center">
                              <button
                                type="button"
                                class="btn but btn-outline-success"
                              >
                                APPLY NOW
                              </button>
                            </div>
                            <br />
                            <br />
                            <br />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3 col-sm-6 col-6  ">
                      <div class="row">
                        <div class="col-12">
                          <div class="row m-1 ggg">
                            <div class="col-3"></div>
                            <div class="col-6 text-center">
                              <div class=" hhh p-1 gyy">
                                <img
                                  src="https://utouchdesign.com/themes/envato/escort/assets/img/company_logo_1.png"
                                  class="img-fluid  rounded-circle"
                                  width="110"
                                />
                              </div>
                            </div>
                            <div class="col-3"></div>
                            <div class="col-12 mt-2 text-center">
                              <h4 class="htg">Product Redesign</h4>
                            </div>
                            <div class="col-12 p-0 text-center text-muted">
                              <p class="htgg">2708 Scenic Way, IL 62373</p>
                            </div>
                            <div class="col-12 text-center">
                              <button
                                type="button"
                                class="btn but btn-outline-success"
                              >
                                APPLY NOW
                              </button>
                            </div>
                            <br />
                            <br />
                            <br />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="row">
                <div class="col-12">
                  <div class="row  m">
                    <div class="col-md-3 col-sm-6 col-6 ">
                      <div class="row">
                        <div class="col-12">
                          <div class="row m-1 ggg">
                            <div class="col-3"></div>
                            <div class="col-6 text-center">
                              <div class=" hhh p-1 gyy">
                                <img
                                  src="https://utouchdesign.com/themes/envato/escort/assets/img/company_logo_1.png"
                                  class="img-fluid  rounded-circle"
                                  width="110"
                                />
                              </div>
                            </div>
                            <div class="col-3"></div>
                            <div class="col-12 mt-2 text-center ">
                              <h4 class="htg">Product Redesign</h4>
                            </div>
                            <div class="col-12 p-0 text-center text-muted">
                              <p class="htgg">2708 Scenic Way, IL 62373</p>
                            </div>
                            <div class="col-12 text-center">
                              <button
                                type="button"
                                class="btn but btn-outline-success"
                              >
                                APPLY NOW
                              </button>
                            </div>
                            <br />
                            <br />
                            <br />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3 col-sm-6 col-6 ">
                      <div class="row">
                        <div class="col-12">
                          <div class="row m-1 ggg">
                            <div class="col-3"></div>
                            <div class="col-6 text-center">
                              <div class=" hhh p-1 gyy">
                                <img
                                  src="https://utouchdesign.com/themes/envato/escort/assets/img/company_logo_1.png"
                                  class="img-fluid  rounded-circle"
                                  width="110"
                                />
                              </div>
                            </div>
                            <div class="col-3"></div>
                            <div class="col-12 mt-2 text-center">
                              <h4 class="htg">Product Redesign</h4>
                            </div>
                            <div class="col-12 p-0 text-center text-muted">
                              <p class="htgg">2708 Scenic Way, IL 62373</p>
                            </div>
                            <div class="col-12 text-center">
                              <button
                                type="button"
                                class="btn but btn-outline-success"
                              >
                                APPLY NOW
                              </button>
                            </div>
                            <br />
                            <br />
                            <br />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3 col-sm-6 col-6  ">
                      <div class="row">
                        <div class="col-12">
                          <div class="row m-1 ggg">
                            <div class="col-3"></div>
                            <div class="col-6 text-center">
                              <div class=" hhh p-1 gyy">
                                <img
                                  src="https://utouchdesign.com/themes/envato/escort/assets/img/company_logo_1.png"
                                  class="img-fluid  rounded-circle"
                                  width="110"
                                />
                              </div>
                            </div>
                            <div class="col-3"></div>
                            <div class="col-12 mt-2 text-center">
                              <h4 class="htg">Product Redesign</h4>
                            </div>
                            <div class="col-12 p-0 text-center text-muted">
                              <p class="htgg">2708 Scenic Way, IL 62373</p>
                            </div>
                            <div class="col-12 text-center">
                              <button
                                type="button"
                                class="btn but btn-outline-success"
                              >
                                APPLY NOW
                              </button>
                            </div>
                            <br />
                            <br />
                            <br />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3 col-sm-6 col-6  ">
                      <div class="row">
                        <div class="col-12">
                          <div class="row m-1 ggg">
                            <div class="col-3"></div>
                            <div class="col-6 text-center">
                              <div class=" hhh p-1 gyy">
                                <img
                                  src="https://utouchdesign.com/themes/envato/escort/assets/img/company_logo_1.png"
                                  class="img-fluid  rounded-circle"
                                  width="110"
                                />
                              </div>
                            </div>
                            <div class="col-3"></div>
                            <div class="col-12 mt-2 text-center">
                              <h4 class="htg">Product Redesign</h4>
                            </div>
                            <div class="col-12 p-0 text-center text-muted">
                              <p class="htgg">2708 Scenic Way, IL 62373</p>
                            </div>
                            <div class="col-12 text-center">
                              <button
                                type="button"
                                class="btn but btn-outline-success"
                              >
                                APPLY NOW
                              </button>
                            </div>
                            <br />
                            <br />
                            <br />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="row">
                <div class="col-12">
                  <div class="row  m">
                    <div class="col-md-3 col-sm-6 col-6 ">
                      <div class="row">
                        <div class="col-12">
                          <div class="row m-1 ggg">
                            <div class="col-3"></div>
                            <div class="col-6 text-center">
                              <div class=" hhh p-1 gyy">
                                <img
                                  src="https://utouchdesign.com/themes/envato/escort/assets/img/company_logo_1.png"
                                  class="img-fluid  rounded-circle"
                                  width="110"
                                />
                              </div>
                            </div>
                            <div class="col-3"></div>
                            <div class="col-12 mt-2 text-center ">
                              <h4 class="htg">Product Redesign</h4>
                            </div>
                            <div class="col-12 p-0 text-center text-muted">
                              <p class="htgg">2708 Scenic Way, IL 62373</p>
                            </div>
                            <div class="col-12 text-center">
                              <button
                                type="button"
                                class="btn but btn-outline-success"
                              >
                                APPLY NOW
                              </button>
                            </div>
                            <br />
                            <br />
                            <br />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3 col-sm-6 col-6 ">
                      <div class="row">
                        <div class="col-12">
                          <div class="row m-1 ggg">
                            <div class="col-3"></div>
                            <div class="col-6 text-center">
                              <div class=" hhh p-1 gyy">
                                <img
                                  src="https://utouchdesign.com/themes/envato/escort/assets/img/company_logo_1.png"
                                  class="img-fluid  rounded-circle"
                                  width="110"
                                />
                              </div>
                            </div>
                            <div class="col-3"></div>
                            <div class="col-12 mt-2 text-center">
                              <h4 class="htg">Product Redesign</h4>
                            </div>
                            <div class="col-12 p-0 text-center text-muted">
                              <p class="htgg">2708 Scenic Way, IL 62373</p>
                            </div>
                            <div class="col-12 text-center">
                              <button
                                type="button"
                                class="btn but btn-outline-success"
                              >
                                APPLY NOW
                              </button>
                            </div>
                            <br />
                            <br />
                            <br />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3 col-sm-6 col-6  ">
                      <div class="row">
                        <div class="col-12">
                          <div class="row m-1 ggg">
                            <div class="col-3"></div>
                            <div class="col-6 text-center">
                              <div class=" hhh p-1 gyy">
                                <img
                                  src="https://utouchdesign.com/themes/envato/escort/assets/img/company_logo_1.png"
                                  class="img-fluid  rounded-circle"
                                  width="110"
                                />
                              </div>
                            </div>
                            <div class="col-3"></div>
                            <div class="col-12 mt-2 text-center">
                              <h4 class="htg">Product Redesign</h4>
                            </div>
                            <div class="col-12 p-0 text-center text-muted">
                              <p class="htgg">2708 Scenic Way, IL 62373</p>
                            </div>
                            <div class="col-12 text-center">
                              <button
                                type="button"
                                class="btn but btn-outline-success"
                              >
                                APPLY NOW
                              </button>
                            </div>
                            <br />
                            <br />
                            <br />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3 col-sm-6 col-6  ">
                      <div class="row">
                        <div class="col-12">
                          <div class="row m-1 ggg">
                            <div class="col-3"></div>
                            <div class="col-6 text-center">
                              <div class=" hhh p-1 gyy">
                                <img
                                  src="https://utouchdesign.com/themes/envato/escort/assets/img/company_logo_1.png"
                                  class="img-fluid  rounded-circle"
                                  width="110"
                                />
                              </div>
                            </div>
                            <div class="col-3"></div>
                            <div class="col-12 mt-2 text-center">
                              <h4 class="htg">Product Redesign</h4>
                            </div>
                            <div class="col-12 p-0 text-center text-muted">
                              <p class="htgg">2708 Scenic Way, IL 62373</p>
                            </div>
                            <div class="col-12 text-center">
                              <button
                                type="button"
                                class="btn but btn-outline-success"
                              >
                                APPLY NOW
                              </button>
                            </div>
                            <br />
                            <br />
                            <br />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button
            class="carousel-control-prev"
            type="button"
            data-target="#carouselExampleControls"
            data-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
          </button>
          <button
            class="carousel-control-next"
            type="button"
            data-target="#carouselExampleControls"
            data-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
          </button>
        </div> */}
      </div>
    </div>
  );
}

export default BlogListpub;
