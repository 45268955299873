import React, { useLayoutEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { URL } from "../../Redux/common/url";
import LoaderComponent from "../Loader";
import BounceLoader from "react-spinners/BounceLoader";
function Cmimg({ url, altkey }) {
  const [isLoaded, setIsLoaded] = useState(false);
  console.log("loding", isLoaded);

  const [windowWidth, setWindowWidth] = useState(600);

  // Update window width when the window is resized
  useLayoutEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }
    setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      <img
        src={url}
        alt={altkey}
        className={`blur-image ${isLoaded ? "hide" : ""}`}
        style={{
          filter: "blur(20px)",
          transition: "opacity 0.3s ease-in-out",
          opacity: isLoaded ? 0 : 1,
        }}
      />
      {!isLoaded && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            marginTop: windowWidth > 600 ? "150px" : "50px",
          }}
        >
          <BounceLoader color="#fd9b57" />
        </div>
      )}
      <LazyLoadImage
        alt={altkey}
        // width={imageWidth}
        // height={imageHeight}
        delayTime={300}
        onLoad={() => setIsLoaded(true)}
        style={{
          // Hide until loaded
          transition: "opacity 0.3s ease-in-out",
          opacity: isLoaded ? 1 : 0,
        }}
        src={url} // use normal <img> attributes as props
      />
    </div>
  );
}

export default Cmimg;
