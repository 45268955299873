import { Col } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { useNavigate, useParams } from "react-router-dom";
// import HeadingBack from "../component/HeadingBack";
// import { allapiAction } from "../../Redux/common/action";
// import { URL } from "@/Redux/common/url";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
// import Api from "@/Redux/common/api";
// import Select from "react-select";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import Editor from "ckeditor5-custom-build/build/ckeditor";
// import dynamic from "next/dynamic";
// const Cktest = dynamic(() => import("../../cktetst/Cktest"), {
//   ssr: false,
// });

import Cktest from "../../cktetst/Cktest";
import ImageUploading from "react-images-uploading";
import { DeleteOutlined } from "@ant-design/icons";
// import { useRouter } from "next/router";
import HeadingBack from "../../HeadingBack";
// import { allapiAction } from "@/Redux/common/action";
import Api from "../../../Redux/common/api";
import { URL } from "../../../Redux/common/url";
import { useNavigate } from "react-router-dom";
import { allapiAction } from "../../../Redux/common/action";
import ImageCropper from "../../ImageCroper/ImageCropper";

function AddBlogComponent() {
  const dispatch = useDispatch();
  const [rows, setRows] = useState([]);
  const [aboutPage, setaboutPage] = useState("");
  const [images, setImages] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const navigate = useNavigate();
  const [subList, setsubList] = useState([]);

  const get_sub_sub_category_list = useSelector((state) =>
    state.allapi.get_sub_sub_category_list
      ? state.allapi.get_sub_sub_category_list
      : []
  );
  const grt_all_brand = useSelector((state) =>
    state.allapi.grt_all_brand ? state.allapi.grt_all_brand : []
  );
  //   const maxNumber = 1

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit

    setImages(imageList);
  };

  useEffect(() => {
    // dispatch(adminAction.getPermissionsList('dhsg'))
    // dispatch(adminAction.getProductList(1))
    dispatch(allapiAction.getsubsubcate({}));
    dispatch(allapiAction.getbrandAllListseller({}));
    // dispatch(adminAction.getSellerListforpro({}))
    // dispatch(adminAction.searchtags({}))
    // dispatch(adminAction.getRoleList('dhsg'))
    dispatch(allapiAction.getsubcateAllList({}));
    dispatch(allapiAction.getcateAllList({}));
    return () => {};
  }, []);
  const [branList, setbrandList] = useState([]);
  const [selectedOptionbrand, setSelectedOptionbrand] = useState(null);
  useEffect(() => {
    const dropList = grt_all_brand?.map((data, i) => {
      data.value = data?._id;
      data.label = data?.name;

      return data;
    });

    setbrandList(dropList);
  }, [grt_all_brand]);

  const get_main_all_category = useSelector((state) =>
    state?.allapi?.get_main_all_category
      ? state?.allapi?.get_main_all_category
      : []
  );
  const get_sub_sub_category = useSelector((state) =>
    state?.allapi?.get_sub_sub_all_category
      ? state?.allapi?.get_sub_sub_all_category
      : []
  );

  const [subList4, setsubList4] = useState([]);
  const [maincateList, setmaincateList] = useState([]);
  const [selectedOption4, setSelectedOption4] = useState(null);
  const [selectedOptionPr, setSelectedOptionPr] = useState(null);

  useEffect(() => {
    const dropList = get_main_all_category?.map((data, i) => {
      data.value = data?._id;
      data.label = data?.name;

      return data;
    });

    setmaincateList(dropList);
  }, [get_main_all_category]);
  useEffect(() => {
    if (selectedOptionPr !== null) {
      const filterdata = get_sub_sub_category?.filter((data) => {
        return data?.parentId == selectedOptionPr?._id;
      });

      const dropList = filterdata?.map((data, i) => {
        data.value = data?._id;
        data.label = data?.name;

        return data;
      });

      setsubList4(dropList);
      setSelectedOption4(null);
      setSelectedOption(null);
    }
  }, [selectedOptionPr]);

  useEffect(() => {
    if (selectedOption4 !== null) {
      const filterdata = get_sub_sub_category_list?.filter((data) => {
        return data?.subparentId == selectedOption4?._id;
      });

      const dropList = filterdata?.map((data, i) => {
        data.value = data?._id;
        data.label = data?.name;

        return data;
      });

      // setsubList4(dropList);

      // const dropList = get_sub_sub_category_list?.map((data, i) => {
      //   data.value = data?._id;
      //   data.label = data?.name;

      //   return data;
      // });

      setsubList(dropList);
      setSelectedOption(null);
    }
  }, [selectedOption4]);

  // useEffect(() => {
  //   const dropList = get_sub_sub_category_list?.map((data, i) => {
  //     data.value = data?._id;
  //     data.label = data?.name;

  //     return data;
  //   });

  //   setsubList(dropList);
  // }, [get_sub_sub_category_list]);

  const maxNumber = 3;

  //   const HendleImgFVer = (e, pictureDataURLs, index) => {

  //     setRows((prevRows) => {
  //       const updatedRows = [...prevRows];

  //       updatedRows[index].image(e[e?.length - 1]);
  //       return updatedRows;
  //     });
  //   };

  const HendleImgFVer = (e, pictureDataURLs, index) => {
    // Log to check if the function is getting the right parameters

    // Assuming that 'rows' is a state variable and 'setRows' is a state updater function
    setRows((prevRows) => {
      // Create a copy of the previous rows array to avoid mutating state directly
      const updatedRows = [...prevRows];

      // Assuming 'e' is an array of images, you want to push the last image to a specific row
      // You should check if 'updatedRows[index].image' is an array, and if not, initialize it as an array

      if (!Array.isArray(updatedRows[index].image)) {
        updatedRows[index].image = [];
      }

      // Push the last image from 'e' to the 'image' array in the specific row
      updatedRows[index].image(e[e.length - 1]);

      // Return the updated rows array to update the state

      return updatedRows;
    });
  };
  const onRemoveImage = (variantIndex, imageIndex) => {
    setRows((prevVariants) => {
      const updatedVariants = [...prevVariants];
      updatedVariants[variantIndex].image.splice(imageIndex, 1);
      return updatedVariants;
    });
  };

  const addSizeProduct = (index) => {
    setRows((prevRows) => {
      const updatedRows = [...prevRows];
      updatedRows[index].sizeProduct({ size: "", price: "", stock: "" });

      return updatedRows;
    });
  };

  const removeSizeProduct = (rowIndex, sizeIndex) => {
    setRows((prevRows) => {
      const updatedRows = [...prevRows];
      updatedRows[rowIndex].sizeProduct.splice(sizeIndex, 1);
      return updatedRows;
    });
  };
  const removeColorProduct = (index) => {
    // setRows((prevRows) => {
    //   const updatedRows = [...prevRows]
    //   updatedRows[rowIndex].sizeProduct.splice(sizeIndex, 1)
    //   return updatedRows
    // })

    setRows((prevRows) => prevRows.filter((row, i) => i !== index));
  };

  const handleSizeChange = (rowIndex, sizeIndex, field, value) => {
    setRows((prevRows) => {
      const updatedRows = [...prevRows];
      updatedRows[rowIndex].sizeProduct[sizeIndex][field] = value;
      return updatedRows;
    });
  };
  const handleColorChange = (rowIndex, sizeIndex, field, value) => {
    setRows((prevRows) => {
      const updatedRows = [...prevRows];
      updatedRows[rowIndex][field] = value;
      return updatedRows;
    });
  };
  const API_URl = URL.API_BASE_URL;
  const UPLOAD_ENDPOINT = "publicApi/productcon";

  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            body.append("upload", file);
            fetch(`${API_URl}/${UPLOAD_ENDPOINT}`, {
              method: "post",
              body: body,
            })
              .then((res) => res.json())
              .then((res) => {
                resolve({ default: `${res.url}` });
              })
              .catch((err) => {
                reject(err);
              });
          });
        });
      },
    };
  }
  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  //   createProduct2

  const [images1, setImages1] = React.useState([]);
  const maxNumber1 = 3;

  const onChange1 = (imageList, addUpdateIndex) => {
    // data for submit

    setImages1(imageList);
  };

  const [productData, setproductData] = useState({});
  const hendleChangerole = (e) => {
    const { name, value } = e.target;
    setproductData({ ...productData, [name]: value });
  };

  const [btndis, setbtndis] = useState(false);

  const [fileName, setFileName] = useState("");
  const [fileContant, setfileContant] = useState("");
  const [finalImg, setfinalImg] = useState("");
  const [croppedImageUrl, setCroppedImageUrl] = useState(null);
  const hendleTosubmit = async () => {
    const data = new FormData();
    try {
      if (!fileName) {
        toast.error("please select feature Image");
      } else if (!productData?.title) {
        toast.error("please enter blog title");
      } /* else if (!selectedOption) {
        toast.error("please select category ");
      } */ /* else if (!productData?.manufacturer) {
        toast.error("please enter manufacturer");
      }  */ /* else if (!selectedOption1) {
        toast.error('please select seller')
      } */ else {
        setbtndis(true);
        data.append("title", productData?.title);
        data.append("type", "Vendor");
        data.append("short_description", productData?.short_description);
        // data.append("gst", productData?.gst);

        if (productData?.meta_title) {
          data.append("meta_title", productData?.meta_title);
        }
        if (productData?.meta_description) {
          data.append("meta_description", productData?.meta_description);
        }

        // data.append("minimumOrderQuantity", productData?.minimumOrderQuantity);
        // data.append("seo_description", productData?.seo_description);

        // data.append("gst", productData?.gst);
        // data.append("afterdiscountprice", productData?.afterdiscountprice);
        // data.append("categoryId", selectedOption?._id);
        data.append("description", aboutPage);
        // data.append("featureImage", images[0]?.file);
        data.append("feature_image", fileContant, fileName);
        // images1?.forEach((file) => {
        //   data.append("images", file?.file);
        // });
        const token = await localStorage.getItem("access_token_vendor");

        const config = {
          headers: {
            Authorization: "Bearer " + token,
          },
        };
        const response = await Api.post(`${URL.createBlogs}`, data, config);

        if (response?.data?.success) {
          setbtndis(false);
          toast.success(response?.data?.message);
          navigate("/seller/blog-list");
        } else {
          setbtndis(false);
          toast.error(response?.data?.message);
        }
        // rows
        // for (const row of rows) {

        //   const data2 = new FormData();
        //   data2.append("productId", response?.data?.data?._id);
        //   data2.append("color", row?.color);
        //   data2.append("gst", productData?.gst);
        //   data2.append("sizeProduct", JSON.stringify(row?.sizeProduct));

        //   row?.image?.forEach((file) => {

        //     data2.append("images", file?.file);
        //   });

        //   const response2 = await Api.post(
        //     `${URL.createProductvariations}`,
        //     data2,
        //     config
        //   );
        // }
      }
    } catch (error) {
      setbtndis(false);
      toast.error(error?.response?.data?.message);
    }
  };

  return (
    <div className=" code-box">
      <div className="flex">
        <div>
          <HeadingBack
            title={"Blog "}
            // subHeading={`Total ${users_list?.totalDocs || 0} Users`}
          />
        </div>
        <Col md={5} sm={5} xs={12} className="pl-5  ml-5"></Col>
      </div>

      <div>
        <div className="container pdcon">
          <div className="row">
            <div className="col-12">
              <div className="fv-row mb-7">
                <label className="fs-6 fw-semibold form-label mb-2">
                  <span className="required">Feature</span>
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="popover"
                    data-bs-trigger="hover"
                    data-bs-html="true"
                    data-bs-content="Permission names is required to be unique."
                  ></i>
                </label>
                <div>
                  {/* <h1>Image Cropper</h1> */}
                  <ImageCropper
                    aspectRatio={4 / 3}
                    // onImageCrop={handleImageCrop}
                    setfileContant={setfileContant}
                    setFileName={setFileName}
                    setfinalImg={setfinalImg}
                    aspectwidth={16}
                    aspectheight={9}
                    onCropComplete={setCroppedImageUrl}
                  />
                  {croppedImageUrl && (
                    <div>
                      <img
                        src={croppedImageUrl}
                        alt="Cropped"
                        width={160}
                        height={100}
                      />
                    </div>
                  )}
                </div>
                {/* <ImageUploading
                  // multiple
                  value={images}
                  onChange={onChange}
                  maxNumber={maxNumber}
                  dataURLKey="data_url"
                  acceptType={["png", "jpeg", "jpg", "webp"]}
                  // acceptType={['jpg']}
                >
                  {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                  }) => (
                    // write your building UI
                    <div className="upload__image-wrapper">
                      <button
                        style={isDragging ? { color: "red" } : {}}
                        onClick={onImageUpload}
                        {...dragProps}
                        className="btn btn-secondary"
                      >
                        Choose feature image
                      </button>
                      &nbsp;
                     
                      {imageList.map((image, index) => (
                        <div key={index} className="image-item">
                          <img src={image.data_url} alt="" width="100" />
                          <div className="image-item__btn-wrapper">
                            <button
                              className="btn btn-secondary"
                              onClick={() => onImageUpdate(index)}
                            >
                              Update
                            </button>
                            <button
                              className="btn btn-secondary"
                              onClick={() => onImageRemove(index)}
                            >
                              Remove
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </ImageUploading> */}
              </div>
            </div>
            <div className="col-3">
              <div className="fv-row mb-7">
                <label className="fs-6 fw-semibold form-label mb-2">
                  <span className="required">Title</span>
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="popover"
                    data-bs-trigger="hover"
                    data-bs-html="true"
                    data-bs-content="Permission names is required to be unique."
                  ></i>
                </label>
                <input
                  // className='form-control form-control-solid'
                  placeholder="Enter a title"
                  name="title"
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                  className="form-control bg-transparent"
                  // onChange={(e:any)=>{hendleChangerole(e)}}
                />
              </div>
            </div>
            <div className="col-3">
              <div className="fv-row mb-7">
                <label className="fs-6 fw-semibold form-label mb-2">
                  <span className="required">Short description</span>
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="popover"
                    data-bs-trigger="hover"
                    data-bs-html="true"
                    data-bs-content="Permission names is required to be unique."
                  ></i>
                </label>
                <input
                  // className='form-control form-control-solid'
                  placeholder="Enter a short description"
                  name="short_description"
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                  className="form-control bg-transparent"
                  // onChange={(e:any)=>{hendleChangerole(e)}}
                />
              </div>
            </div>{" "}
            <div className="col-3">
              <div className="fv-row mb-7">
                <label className="fs-6 fw-semibold form-label mb-2">
                  <span className="required">seo title</span>
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="popover"
                    data-bs-trigger="hover"
                    data-bs-html="true"
                    data-bs-content="Permission names is required to be unique."
                  ></i>
                </label>
                <input
                  // className='form-control form-control-solid'
                  placeholder="Enter a seo title"
                  name="meta_title"
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                  className="form-control bg-transparent"
                  // onChange={(e:any)=>{hendleChangerole(e)}}
                />
              </div>
            </div>
            <div className="col-3">
              <div className="fv-row mb-7">
                <label className="fs-6 fw-semibold form-label mb-2">
                  <span className="required">seo description</span>
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="popover"
                    data-bs-trigger="hover"
                    data-bs-html="true"
                    data-bs-content="Permission names is required to be unique."
                  ></i>
                </label>
                <input
                  // className='form-control form-control-solid'
                  placeholder="Enter a seo description"
                  name="meta_description"
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                  className="form-control bg-transparent"
                  // onChange={(e:any)=>{hendleChangerole(e)}}
                />
              </div>
            </div>
            <div className="col-12">
              <div className="fv-row mb-7">
                <label className="fs-6 fw-semibold form-label mb-2">
                  <span className="required">Description</span>
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="popover"
                    data-bs-trigger="hover"
                    data-bs-html="true"
                    data-bs-content="Permission names is required to be unique."
                  ></i>
                </label>
                {/* <CKEditor
                  editor={Editor}
                  data={aboutPage}
                  onReady={(editor) => {
                  
                  }}
                  onChange={(event, editor) => {
                  
                    const data = editor.getData();
                    setaboutPage(data);
                  }}
                  config={{
                    extraPlugins: [uploadPlugin],
                  }}
                  onBlur={(event, editor) => {
                
                  }}
                  onFocus={(event, editor) => {
                  
                  }}
                /> */}

                <Cktest
                  aboutPage={aboutPage}
                  setaboutPage={setaboutPage}
                  uploadPlugin={uploadPlugin}
                />
              </div>
            </div>
          </div>
          <div className="row">
          <div className="col-4">
            {" "}
            <button
              disabled={btndis}
              className={btndis ? "btn btn-primary" : "btn btn-primary"}
              onClick={() => hendleTosubmit()}
            >
              Submit
            </button>
          </div>
        </div>
        </div>

    
      </div>
    </div>
  );
}

export default AddBlogComponent;
