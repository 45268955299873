import React from "react";
import { useNavigate } from "react-router-dom";

function LoginIntro() {
  const navigate = useNavigate();
  return (
    <div className="loginIntro">
      <div className="container">
        <div class="row card-container" id="cardContainer">
          <div class="col-md-6 col-6">
            <div class="card mycards">
              <div class="card-header customercardhearder">
                <p class="text-center text-white">
                  <b>Customer Register</b>
                </p>
              </div>
              <div class="card-body text-center">
                <h2>
                  {" "}
                  <i class="fa fa-user-circle-o"></i>
                </h2>
                <button
                  // onClick={() => navigate("/register-user")}
                  type="button"
                  class="btn btn-primary buttonsClors"
                >
                  Sign Up
                </button>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-6" id="customerLoginCard">
            <div class="card mycards">
              <div class="card-header customercardhearder">
                <p class="text-center text-white">
                  <b>Customer Login</b>
                </p>
              </div>
              <div class="card-body text-center">
                <h2>
                  {" "}
                  <i class="fa fa-user-circle-o"></i>
                </h2>
                <button
                  onClick={() => navigate("/login-user")}
                  type="button"
                  class="btn btn-primary buttonsClors"
                >
                  Sign In
                </button>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-6 mt-3">
            <div class="card mycards">
              <div class="card-header suppliercardhearder">
                <p class="text-center text-white">
                  <b>Supplier Register</b>
                </p>
              </div>
              <div class="card-body text-center">
                <h2>
                  {" "}
                  <i class="fa fa-cart-plus"></i>
                </h2>
                <button type="button" class="btn btn-primary supplierbutton">
                  Sign Up
                </button>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-6 mt-3">
            <div class="card mycards">
              <div class="card-header suppliercardhearder">
                <p class="text-center text-white">
                  <b>Supplier Login</b>
                </p>
              </div>
              <div class="card-body text-center">
                <h2>
                  {" "}
                  <i class="fa fa-cart-plus"></i>
                </h2>
                <button
                  onClick={() => navigate("/login-supplier")}
                  type="button"
                  class="btn btn-primary supplierbutton"
                >
                  Sign In
                </button>
              </div>
            </div>
          </div>

          <div class="col-md-12 col-12 mt-3">
            <div class="card mycards">
              <div class="card-header customercardhearder">
                <p class="text-center text-white">
                  <b>Customer Login</b>
                </p>
              </div>
              <div class="card-body text-center">
                <div class="row">
                  <div class="col-md-12 text-left">
                    <label for="user_name" class="text-left">
                      <b>User Name / Phone No:</b>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      name="user_name"
                      placeholder="Enter your Phone No"
                    />
                  </div>

                  <div class="col-md-12 text-left">
                    <label for="password" class="text-left">
                      <b>Password:</b>
                    </label>
                    <input
                      type="password"
                      class="form-control"
                      name="password"
                      placeholder="Enter your password"
                    />
                  </div>
                </div>
              </div>
              <button type="button" class="btn btn-primary buttonsClors">
                Submit
              </button>
            </div>
          </div>

          <div class="col-md-12 col-12 mt-3">
            <div class="card mycards">
              <div class="card-header customercardhearder">
                <p class="text-center text-white">
                  <b>Customer Register</b>
                </p>
              </div>
              <div class="card-body text-center">
                <div class="row">
                  <div class="col-md-12 text-left">
                    <label for="name" class="text-left">
                      <b>Full Name:</b>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      name="name"
                      placeholder="Enter your name"
                    />
                  </div>
                  <div class="col-md-12 text-left">
                    <label for="mobile" class="text-left">
                      <b>Phone:</b>
                    </label>
                    <input
                      type="number"
                      class="form-control"
                      name="mobile"
                      placeholder="Enter your Mobile NO..."
                    />
                  </div>
                  <div class="col-md-12 text-left">
                    <label for="email" class="text-left">
                      <b>Email:</b>
                    </label>
                    <input
                      type="email"
                      class="form-control"
                      name="email"
                      placeholder="Enter your email"
                    />
                  </div>

                  <div class="col-md-12 text-left">
                    <label for="password" class="text-left">
                      <b>Password:</b>
                    </label>
                    <input
                      type="password"
                      class="form-control"
                      name="password"
                      placeholder="Enter your password"
                    />
                  </div>
                  <div class="col-md-12 text-left">
                    <label for="con_password" class="text-left">
                      <b>Confirm Password:</b>
                    </label>
                    <input
                      type="password"
                      class="form-control"
                      name="con_password"
                      placeholder="Enter your password"
                    />
                  </div>
                  <div class="col-md-12 text-left">
                    <label for="password" class="text-left">
                      <b>Country</b>
                    </label>
                    <select name="" id="" class="form-control">
                      <option value="">Select Country</option>
                    </select>
                  </div>
                  <div class="col-md-12 text-left">
                    <label for="password" class="text-left">
                      <b>State</b>
                    </label>
                    <select name="" id="" class="form-control">
                      <option value="">Select State</option>
                    </select>
                  </div>
                  <div class="col-md-12 text-left">
                    <label for="password" class="text-left">
                      <b>District</b>
                    </label>
                    <select name="" id="" class="form-control">
                      <option value="">Select District</option>
                    </select>
                  </div>
                  <div class="col-md-12 text-left">
                    <label for="password" class="text-left">
                      <b>City</b>
                    </label>
                    <select name="" id="" class="form-control">
                      <option value="">Select City</option>
                    </select>
                  </div>
                </div>
              </div>
              <button type="button" class="btn btn-primary buttonsClors">
                Submit
              </button>
            </div>
          </div>

          <div class="col-md-12 col-12 mt-3">
            <div class="card mycards">
              <div class="card-header suppliercardhearder">
                <p class="text-center text-white">
                  <b>Supplier Login</b>
                </p>
              </div>
              <div class="card-body text-center">
                <div class="row">
                  <div class="col-md-12 text-left">
                    <label for="user_name" class="text-left">
                      <b>User Name / Phone No:</b>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="user_name"
                      name="user_name"
                      placeholder="Enter your Phone No"
                    />
                  </div>

                  <div class="col-md-12 text-left">
                    <label for="password" class="text-left">
                      <b>Password:</b>
                    </label>
                    <input
                      type="password"
                      class="form-control"
                      id="password"
                      name="password"
                      placeholder="Enter your password"
                    />
                  </div>
                </div>
              </div>
              <button type="button" class="btn btn-primary supplierbutton">
                Sign Up
              </button>
            </div>
          </div>

          <div class="col-md-12 col-12 mt-3">
            <div class="card mycards">
              <div class="card-header suppliercardhearder">
                <p class="text-center text-white">
                  <b>Supplier Register</b>
                </p>
              </div>
              <div class="card-body text-center">
                <div class="row">
                  <div class="col-md-12 text-left">
                    <label for="name" class="text-left">
                      <b>Full Name:</b>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      name="name"
                      placeholder="Enter your name"
                    />
                  </div>
                  <div class="col-md-12 text-left">
                    <label for="mobile" class="text-left">
                      <b>Phone:</b>
                    </label>
                    <input
                      type="number"
                      class="form-control"
                      name="mobile"
                      placeholder="Enter your Mobile NO..."
                    />
                  </div>
                  <div class="col-md-12 text-left">
                    <label for="email" class="text-left">
                      <b>Email:</b>
                    </label>
                    <input
                      type="email"
                      class="form-control"
                      name="email"
                      placeholder="Enter your email"
                    />
                  </div>
                  <div class="col-md-12 text-left">
                    <label for="display_name" class="text-left">
                      <b>Display Name:</b>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      name="display_name"
                      placeholder="Enter your Display Name"
                    />
                  </div>

                  <div class="col-md-12 text-left">
                    <label for="password" class="text-left">
                      <b>Password:</b>
                    </label>
                    <input
                      type="password"
                      class="form-control"
                      name="password"
                      placeholder="Enter your password"
                    />
                  </div>
                  <div class="col-md-12 text-left">
                    <label for="con_password" class="text-left">
                      <b>Confirm Password:</b>
                    </label>
                    <input
                      type="password"
                      class="form-control"
                      name="con_password"
                      placeholder="Enter your password"
                    />
                  </div>
                  <div class="col-md-12 text-left">
                    <label for="password" class="text-left">
                      <b>Country</b>
                    </label>
                    <select name="" id="" class="form-control">
                      <option value="">Select Country</option>
                    </select>
                  </div>
                  <div class="col-md-12 text-left">
                    <label for="password" class="text-left">
                      <b>State</b>
                    </label>
                    <select name="" id="" class="form-control">
                      <option value="">Select State</option>
                    </select>
                  </div>
                  <div class="col-md-12 text-left">
                    <label for="password" class="text-left">
                      <b>District</b>
                    </label>
                    <select name="" id="" class="form-control">
                      <option value="">Select District</option>
                    </select>
                  </div>
                  <div class="col-md-12 text-left">
                    <label for="password" class="text-left">
                      <b>City</b>
                    </label>
                    <select name="" id="" class="form-control">
                      <option value="">Select City</option>
                    </select>
                  </div>
                </div>
              </div>
              <button type="button" class="btn btn-primary supplierbutton">
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginIntro;
