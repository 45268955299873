import React from "react";
import { useSelector } from "react-redux";
import { URL } from "../../../Redux/common/url";
import { useNavigate } from "react-router-dom";

function Requerment() {
  const all_categories_List = useSelector((state) =>
    state?.allapi?.all_categories_List ? state?.allapi?.all_categories_List : []
  );
  console.log(all_categories_List);

  const navigate = useNavigate();
  return (
    <div>
      <div class="container-fluid mt-2">
        <div class="row">
          {all_categories_List &&
            all_categories_List?.map((data, index) => {
              return (
                index < 4 && (
                  <div class="col-12 col-lg-6">
                    <h4> {data?.name}</h4>
                    <div class="row g-3">
                      {data?.subcates &&
                        data?.subcates?.map((data2, index2) => {
                          return (
                            index2 < 3 && (
                              <div class="col-4 col-md-4" key={index2}>
                                <div
                                  class="card"
                                  onClick={() =>
                                    navigate(
                                      "/listings?page=1&maincateId=" + data?._id
                                    )
                                  }
                                >
                                  <img
                                    src={URL.API_BASE_URL + data2?.image}
                                    class="card-img-top"
                                    alt="Banquet Halls"
                                  />
                                  <div class="card-body text-center">
                                    <p class="card-text">{data2?.name}</p>
                                  </div>
                                </div>
                              </div>
                            )
                          );
                        })}
                      {/* <div class="col-4 col-md-4">
                      <div class="card">
                        <img
                          src="img/listing/card 1.png"
                          class="card-img-top"
                          alt="Banquet Halls"
                        />
                        <div class="card-body text-center">
                          <p class="card-text">Banquet Halls</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-4 col-md-4">
                      <div class="card">
                        <img
                          src="img/listing/card 2.png"
                          class="card-img-top"
                          alt="Bridal Requisite"
                        />
                        <div class="card-body text-center">
                          <p class="card-text">Bridal Requisite</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-4 col-md-4">
                      <div class="card">
                        <img
                          src="img/listing/card 3.png"
                          class="card-img-top"
                          alt="Caterers"
                        />
                        <div class="card-body text-center">
                          <p class="card-text">Caterers</p>
                        </div>
                      </div>
                    </div> */}
                    </div>
                  </div>
                )
              );
            })}
          {/* <div class="col-12 col-lg-6">
            <h4>Wedding Requisites</h4>
            <div class="row g-3">
              <div class="col-4 col-md-4">
                <div class="card">
                  <img
                    src="img/listing/card 1.png"
                    class="card-img-top"
                    alt="Banquet Halls"
                  />
                  <div class="card-body text-center">
                    <p class="card-text">Banquet Halls</p>
                  </div>
                </div>
              </div>
              <div class="col-4 col-md-4">
                <div class="card">
                  <img
                    src="img/listing/card 2.png"
                    class="card-img-top"
                    alt="Bridal Requisite"
                  />
                  <div class="card-body text-center">
                    <p class="card-text">Bridal Requisite</p>
                  </div>
                </div>
              </div>
              <div class="col-4 col-md-4">
                <div class="card">
                  <img
                    src="img/listing/card 3.png"
                    class="card-img-top"
                    alt="Caterers"
                  />
                  <div class="card-body text-center">
                    <p class="card-text">Caterers</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 col-lg-6 mt-2">
            <h4>Beauty & Spa</h4>
            <div class="row g-3">
              <div class="col-4 col-md-4">
                <div class="card">
                  <img
                    src="img/listing/card 4.png"
                    class="card-img-top"
                    alt="Beauty Parlours"
                  />
                  <div class="card-body text-center">
                    <p class="card-text">Beauty Parlours</p>
                  </div>
                </div>
              </div>
              <div class="col-4 col-md-4">
                <div class="card">
                  <img
                    src="img/listing/card 5.png"
                    class="card-img-top"
                    alt="Spa & Massages"
                  />
                  <div class="card-body text-center">
                    <p class="card-text">Spa & Massages</p>
                  </div>
                </div>
              </div>
              <div class="col-4 col-md-4">
                <div class="card">
                  <img
                    src="img/listing/card 6.png"
                    class="card-img-top"
                    alt="Salons"
                  />
                  <div class="card-body text-center">
                    <p class="card-text">Salons</p>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      {/* <div class="container-fluid mt-4">
        <div class="row">
          <div class="col-12 col-lg-6 ">
            <h4>Repairs & Services</h4>
            <div class="row g-3">
              <div class="col-4 col-md-4">
                <div class="card">
                  <img
                    src="img/listing/card 1.png"
                    class="card-img-top"
                    alt="Banquet Halls"
                  />
                  <div class="card-body text-center">
                    <p class="card-text">Banquet Halls</p>
                  </div>
                </div>
              </div>
              <div class="col-4 col-md-4">
                <div class="card">
                  <img
                    src="img/listing/card 2.png"
                    class="card-img-top"
                    alt="Bridal Requisite"
                  />
                  <div class="card-body text-center">
                    <p class="card-text">Bridal Requisite</p>
                  </div>
                </div>
              </div>
              <div class="col-4 col-md-4">
                <div class="card">
                  <img
                    src="img/listing/card 3.png"
                    class="card-img-top"
                    alt="Caterers"
                  />
                  <div class="card-body text-center">
                    <p class="card-text">Caterers</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 col-lg-6">
            <h4>Daily Needs</h4>
            <div class="row g-3">
              <div class="col-4 col-md-4">
                <div class="card">
                  <img
                    src="img/listing/card 4.png"
                    class="card-img-top"
                    alt="Beauty Parlours"
                  />
                  <div class="card-body text-center">
                    <p class="card-text">Beauty Parlours</p>
                  </div>
                </div>
              </div>
              <div class="col-4 col-md-4">
                <div class="card">
                  <img
                    src="img/listing/card 5.png"
                    class="card-img-top"
                    alt="Spa & Massages"
                  />
                  <div class="card-body text-center">
                    <p class="card-text">Spa & Massages</p>
                  </div>
                </div>
              </div>
              <div class="col-4 col-md-4">
                <div class="card">
                  <img
                    src="img/listing/card 6.png"
                    class="card-img-top"
                    alt="Salons"
                  />
                  <div class="card-body text-center">
                    <p class="card-text">Salons</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default Requerment;
