// import { allapiAction } from "@/Redux/common/action";
// import { URL } from "@/Redux/common/url";
// import Link from "next/link";
// import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
// import CoustomImg from "../../../../Component/CoustomImg/CoustomImg";
// import SellerHeader from "../../../../Component/Layout/SellerHeader";
// import TruncateText from "../../../../Component/TruncateText/TruncateText";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { URL } from "../../Redux/common/url";
import { allapiAction } from "../../Redux/common/action";
import CoustomImg from "../CoustomImg/CoustomImg";
import SellerHeader from "../Layout/SellerHeader";
import TruncateText from "../TruncateText/TruncateText";
import VendorFooter from "../Layout/VendorFooter";

function VendorBlogs({ post }) {
  const dispatch = useDispatch();
  const router = useNavigate();

  console.log(router);

  const { id } = useParams();
  const locationv = useLocation();
  const searchParams = new URLSearchParams(locationv.search);
  const search = searchParams.get("search");
  const location = searchParams.get("location");
  const page = searchParams.get("page");

  const get_product_list = useSelector((state) =>
    state?.allapi?.user_vendor_blog_search
      ? state?.allapi?.user_vendor_blog_search
      : {}
  );
  const get_category_Filter = useSelector((state) =>
    state?.allapi?.get_category_Filter ? state?.allapi?.get_category_Filter : []
  );

  console.log(get_category_Filter);

  const pageno = page ? page : 1;

  const listdata = get_product_list?.docs ? get_product_list?.docs : [];
  const listdataListing = get_product_list?.listingList?.docs
    ? get_product_list?.listingList?.docs
    : [];
  // get_product_list

  useEffect(() => {
    // searchProduct

    // if (search) {
    //   dispatch(
    //     allapiAction.getBlogListVendorpb({
    //       page: pageno,
    //       // id: search,
    //       id: id,
    //     })
    //   );
    // } else {
    dispatch(allapiAction.getBlogListVendorpb({ page: pageno, id: id }));
    // }
    // dispatch(allapiAction.getfiltercategory({}));
    return () => {};
  }, [locationv.search]);

  const henldetofilterbyCate = (e) => {
    if (search) {
      dispatch(
        allapiAction.userSearchProduct({ page: pageno, id: search, cateId: e })
      );
    }
  };
  const [childcateee, setchildcateee] = useState("");
  const hendletoviewchlidcate = (e) => {};

  const items = get_product_list?.totalDocs;
  const itemsPerPage = 18;
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setPageCount(Math.ceil(items && items / itemsPerPage));
  }, [itemOffset, itemsPerPage, get_product_list, items]);

  const handlePageClick = (event) => {
    const data = event?.selected + 1;
    // const newOffset = (event.selected * itemsPerPage) % items;
    // setItemOffset(newOffset);
    // setPage(data);
    // "/vendor-store/blog-details/" + id + "?search=" + search + "&page=" + data

    const srch = search ? search : "";
    router("/vendor-store/blogs/" + id + "?search=" + srch + "&page=" + data);
    window.scrollTo(50, 0);
    // dispatch(allapiAction.lostfoundlistpublic({ page: data }));
  };
  return (
    <div>
      <SellerHeader vendorDetails={post} />

      <div className="container-fluid">
        <div className="ps-deal-of-day shewBox">
          <div className="ps-container">
            <div class="ps-layout--shop mt-30">
              {/* <div class="ps-layout__left">
                <aside class="widget widget_shop">
                  <h4 class="widget-title">Categories</h4>
                  <ul class="ps-list--categories">
                    {get_category_Filter &&
                      get_category_Filter?.map((data, i) => {
                        return (
                          <li class="menu-item-has-children" key={i}>
                            <a href="#">{data?.name}</a>
                            <span
                              class={
                                childcateee == data?._id
                                  ? "sub-toggle active "
                                  : "sub-toggle"
                              }
                              onClick={() => {
                                setchildcateee(data?._id);
                              }}
                            >
                              <i class="fa fa-angle-down"></i>
                            </span>
                            <ul
                              class="sub-menu"
                              style={
                                childcateee == data?._id
                                  ? { display: "block" }
                                  : { display: "none" }
                              }
                            >
                              {data?.subcates &&
                                data?.subcates?.map((datachild, index) => {
                                  return (
                                    <li key={index}>
                                      <a
                                        href="#"
                                        onClick={() => {
                                          henldetofilterbyCate(datachild?._id);
                                        }}
                                      >
                                        {datachild?.name}
                                      </a>
                                    </li>
                                  );
                                })}
                            </ul>
                          </li>
                        );
                      })}
                  </ul>
                </aside>
              </div> */}
              <div class="">
                <div class="ps-block--shop-features">
                  <div class="ps-block__header">
                    <h3>Blog/News</h3>
                    {/* <div class="ps-block__navigation">
                      <a class="ps-carousel__prev" href="#recommended1">
                        <i class="icon-chevron-left"></i>
                      </a>
                      <a class="ps-carousel__next" href="#recommended1">
                        <i class="icon-chevron-right"></i>
                      </a>
                    </div> */}
                  </div>

                  {listdata?.length == 0 && (
                    <div className="notfound">
                      <h2>Not Found</h2>
                    </div>
                  )}
                  <div className="row ps-section__content mt-10">
                    {" "}
                    {/* {listdata &&
                      listdata?.map((data, i) => {
                        return (
                          <div className="col-6 col-sm-2" key={i}>
                            <div className="recentlostfound">
                              <div className="ps-product">
                                <div className="ps-product__thumbnail  ps-product__thumbnailms3">
                                  <Link to={"/blog-details/" + data?.slug}>
                                    <CoustomImg
                                      url={
                                        URL.API_BASE_URL + data?.feature_image
                                      }
                                      altkey={data?.title}
                                      w1={165}
                                      w2={300}
                                      h1={130}
                                      h2={150}
                                    />
                                  </Link>
                                </div>
                                <div className="ps-product__container">
                                  <div className="ps-product__content">
                                    <Link
                                      className="ps-product__title"
                                      to={"/blog-details/" + data?.slug}
                                    >
                                      <TruncateText
                                        text={data?.title}
                                        maxLength={23}
                                      />
                                     
                                    </Link>

                                    
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })} */}
                    {listdata &&
                      listdata?.map((data, i) => {
                        return (
                          <div class="col-lg-3 col-md-3 col-sm-12 col-12  px-25 mt-10">
                            <div class="testimonial-item border p-15 bg-light shadow-lg">
                              <div class="d-flex align-items-center">
                                <div class="">
                                  <img
                                    onClick={() => {
                                      router("/blog-details/" + data?.slug);
                                    }}
                                    src={URL.API_BASE_URL + data?.feature_image}
                                    alt="client_image"
                                  />
                                </div>
                              </div>
                              <div class="border-top text-secondary text-justify mt-4 pt-3">
                                <div class="mx-4">
                                  <h4
                                    class="text-dark"
                                    onClick={() => {
                                      router("/blog-details/" + data?.slug);
                                    }}
                                  >
                                    {data?.title}
                                  </h4>
                                </div>
                                <p class="mb-0">{data?.short_description}</p>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-20">
          <nav
            className="d-flex justify-content-between"
            aria-label="Page navigation"
          >
            <ReactPaginate
              breakLabel="..."
              nextLabel="Next"
              onPageChange={handlePageClick}
              className="paging_simple_numbersc pagination"
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel="Previous"
              renderOnZeroPageCount={null}
            />
          </nav>
        </div>
      </div>
      <VendorFooter />
    </div>
  );
}

export default VendorBlogs;
