import React from "react";
import Slider from "react-slick";
function SliderList() {
  var settings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div>
      <div class="container-fluid mt-3 ">
        <div class="row ">
          <div class="col-12 col-sm-5 col-md-5 col-lg-5 mt-5">
            <div class="slider">
              <Slider {...settings}>
                <img src="img/listing/banner_bills_2024.webp" alt="Slide 1" />
                <img src="img/listing/banner_cctv_2024.webp" alt="Slide 2" />
                <img src="img/listing/banner_hotels_2024.webp" alt="Slide 3" />
                <img
                  src="img/listing/banner_interiordesigners_2024.webp"
                  alt="Slide 1"
                />
                <img
                  src="img/listing/banner_packersmovers_2024.webp"
                  alt="Slide 2"
                />
              </Slider>
            </div>
          </div>

          <div class="col-12 col-sm-7 col-md-7 col-lg-7 mt-5">
            <div class="">
              <div class="row">
                <div class="col-3">
                  <img
                    src="img/listing/m1.png"
                    class="card-img-top"
                    alt="Flights"
                  />
                </div>
                <div class="col-3">
                  <img
                    src="img/listing/m2.png"
                    class="card-img-top"
                    alt="Flights"
                  />
                </div>
                <div class="col-3">
                  <img
                    src="img/listing/m3.png"
                    class="card-img-top"
                    alt="Flights"
                  />
                </div>
                <div class="col-3">
                  <img
                    src="img/listing/m4.png"
                    class="card-img-top"
                    alt="Flights"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SliderList;
