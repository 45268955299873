// import { URL } from "@/Redux/common/url";
import React, { useEffect, useRef, useState } from "react";
import HomeSlider from "../Home/HomeSlider";
import OurWork from "../OurWork/OurWork";
import ReactToPrint from "react-to-print";
import { Button, Modal } from "react-bootstrap";
// import { allapiAction } from "@/Redux/common/action";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useParams } from "react-router-dom";
import { URL } from "../../Redux/common/url";
import { allapiAction } from "../../Redux/common/action";
function WinnerDetails({}) {
  // console.log(post);
  const dispatch = useDispatch();
  const componentRef = useRef();
  const { id } = useParams();
  const [showDelelt, setShowDelete] = useState(false);
  const [post, setpost] = useState({});
  const handleCloseDelete = () => setShowDelete(false);

  const get_marques_List = useSelector((state) =>
    state?.allapi?.get_marques_List ? state?.allapi?.get_marques_List : {}
  );

  const news = get_marques_List?.news ? get_marques_List?.news : [];
  const admq = get_marques_List?.ads ? get_marques_List?.ads : [];
  const about = get_marques_List?.about ? get_marques_List?.about : [];
  useEffect(() => {
    dispatch(allapiAction.getsliderList({ page: "winner" }));
    dispatch(allapiAction.getMarqueesList2({ page: "winner" }));
    getDetails();
  }, []);

  const getDetails = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      id: id,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const res = await fetch(
      URL.API_BASE_URL + `${URL.CertificatesDetails}`,
      requestOptions
    );

    // const res2 = await fetch(
    //   URL.API_BASE_URL + `${URL.lostfoundlist}`,
    //   requestOptions
    // );
    const events = await res.json();
    // const events2 = await res2.json();

    const postData = events?.data;
    // const postData2 = events2?.data;
    console.log(postData);
    setpost(postData);
  };
  return (
    <div className="hometest">
      <Modal show={showDelelt} size="lg" onHide={handleCloseDelete}>
        <Modal.Header closeButton>
          <Modal.Title> Certificate</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container img100 " ref={componentRef}>
            <div className="row">
              <div className="col-12">
                <div className="card text-center certificate mt-25">
                  <img
                    src="/img/certificate.png"
                    className="certificate_image"
                    alt="..."
                  />
                  <div className="card-img-overlay text-center">
                    <div className="cer">
                      <h1 className="card-title cer"></h1>
                      <img src="/img/cer.png" alt="cer" />
                      <h6 className="card-text second_txt"></h6>
                    </div>
                    <p className="card-text mt-5 ">
                      THIS CERTIFICATE IS PROUDLY PRESENTED TO
                    </p>
                    <h3 className=" name text-danger">{post?.name}</h3>
                    {/* <h5 className="mt-5">
                      <strong>Lorem Ipsum dolor sit amet adipiscing </strong>
                    </h5> */}
                    <p className="achiver_pra">
                      “This certificate is awarded to{" "}
                      <b className="text-capitalize">{post?.name}</b> in
                      recognition of their remarkable achievements and
                      exceptional contributions to{" "}
                      <b className="text-capitalize">{post?.crType}</b> on
                      {moment(post?.createdAt).format(" DD/MM/YYYY ")}. Your
                      hard work, determination, and passion have set a high
                      standard of excellence”
                      <br />
                    </p>

                    <div className="d-flex justify-content-around mt-5 pt-5">
                      {/* <p className="sign">
                        <i>Signature</i>
                      </p> */}
                      {/* <p className="sign">
                        <i>Signature</i>
                      </p> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {/* <ReactToPrint
            trigger={() => (
              <Button variant="primary">Print / Download</Button>
              // <button className='bg-blue-500 ml-5 text-white font-bold py-2 px-8 rounded shadow border-2 border-blue-500 hover:bg-transparent hover:text-blue-500 transition-all duration-300'>
              //   Print / Download
              // </button>
            )}
            content={() => componentRef.current}
          /> */}

          {/* <Button variant="secondary" onClick={handleCloseDelete}>
              No
            </Button>
            <Button variant="primary" onClick={hendleDelete}>
              Yes
            </Button> */}
        </Modal.Footer>
      </Modal>

      {news?.length > 0 && (
        <div className="marquee">
          <marquee behavior="scroll" direction="left" scrollamount="05">
            {news &&
              news?.map((data, i) => {
                return <span key={data._id}>{data?.title}</span>;
              })}
          </marquee>
        </div>
      )}
      <HomeSlider />

      <div className="container-fluid">
        <h2 className="text-center Achievers">Our Achievers</h2>
        <div className="row">
          <div className="col-md-3">
            <div className="card-body">
              <img
                src={URL.API_BASE_URL + post?.image}
                alt=""
                className="cer_img"
              />
            </div>
          </div>
          <div className="col-md-6">
            <h3>Achievers {post?.name}</h3>
            <p className="abc">
              Archives have several characteristics: They are only retained if
              they are considered to be of long-term historical value. This can
              be difficult to assess but what it means is that archive
              collections do not and cannot hold every document ever created.
              They are not created consciously as a historical record. Archives
              have several characteristics: They are only retained if they are
              considered to be of long-term historical value.
            </p>
          </div>
          <div className="col-md-3">
            <img
              src="/img/png-clipart-trophy-award-trophy-data-award-removebg-preview.png"
              alt=""
              className="troffy img-fluid"
            />
          </div>
        </div>
      </div>

      {/* certifi */}

      <div className="container mt-25 mb-25 " ref={componentRef}>
        <div className="row">
          <div className="col-12">
            <div className="card text-center certificate">
              <img
                src="/img/certificate.png"
                className="certificate_image"
                alt="..."
              />
              <div className="card-img-overlay text-center">
                <div className="cer">
                  <h1 className="card-title cer"></h1>
                  <img src="/img/cer.png" alt="cer" />
                  <h6 className="card-text second_txt"></h6>
                </div>
                <p className="card-text mt-5 ">
                  THIS CERTIFICATE IS PROUDLY PRESENTED TO
                </p>
                <div className="d-flex justify-content-around mt-5 pt-5">
                  <div style={{ width: "100px" }}>
                    <img
                      style={{ width: "100%", border: "2px solid orange" }}
                      src={URL.API_BASE_URL + post?.image}
                      alt=""
                      className="rounded-circle"
                    />
                  </div>
                </div>
                <h3 className=" name text-danger">{post?.name}</h3>
                <p className="achiver_pra">
                  “This certificate is awarded to{" "}
                  <b className="text-capitalize">{post?.name}</b> in recognition
                  of their remarkable achievements and exceptional contributions
                  to <b className="text-capitalize">{post?.crType}</b> on
                  {moment(post?.createdAt).format(" DD/MM/YYYY ")}. Your hard
                  work, determination, and passion have set a high standard of
                  excellence”
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-around mt-25 mb-25">
        <div className="buttons d-flex">
          {/* <a href="#">
            <button className="btn btn-1">DOWNLOAD</button>
          </a> */}

          {/* <a href="#">
            <button
              className="btn btn-2"
              onClick={() => {
                setShowDelete(true);
              }}
            >
              PRINT
            </button>
          </a> */}
          {/* <ReactToPrint
            trigger={() => (
              <button className="btn btn-2">PRINT</button>
              // <button className='bg-blue-500 ml-5 text-white font-bold py-2 px-8 rounded shadow border-2 border-blue-500 hover:bg-transparent hover:text-blue-500 transition-all duration-300'>
              //   Print / Download
              // </button>
            )}
            content={() => componentRef.current}
          /> */}
        </div>
      </div>

      <OurWork />
    </div>
  );
}

export default WinnerDetails;
