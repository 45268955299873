import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { toast } from "react-toastify";
import { allapiAction } from "../../Redux/common/action";
import { URL } from "../../Redux/common/url";
import HomeSlider from "../Home/HomeSlider";

function LoginSuppler() {
  const slider_list = useSelector((state) =>
    state?.allapi?.slider_list ? state?.allapi?.slider_list : {}
  );

  console.log(slider_list);

  const slider = slider_list?.sliders ? slider_list?.sliders : [];
  const ad = slider_list?.ads ? slider_list?.ads : [];
  const banner = slider_list?.banner ? slider_list?.banner : [];
  const ads2 = [...ad].reverse();

  // console.log(ttt);
  var settings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const [userData, setuserData] = useState({ phone: "", password: "" });
  const [type, settype] = useState("Vendor");
  const dispatch = useDispatch();
  const router = useNavigate();

  const hendletochange = (e) => {
    const { name, value } = e?.target;
    setuserData({ ...userData, [name]: value });
  };

  const hendletoSubmit = (e) => {
    e.preventDefault();

    if (!userData?.phone) {
      toast.error("please enter phone number");
    } else if (!userData?.password) {
      toast.error("please enter password");
    } else {
      userData.role_name = type;
      dispatch(allapiAction.signinSeller(userData, router));
      return () => {};
    }
  };

  useEffect(() => {
    dispatch(allapiAction.getsliderList({ page: "loginpage" }));

    return () => {};
  }, []);

  return (
    <div className="loginIntro">
      <div className="hometest">
        <HomeSlider />
      </div>
      <div className="container">
        <div className="row">
          <div class="col-md-12 col-12 mt-3">
            <div class="card mycards">
              <div class="card-header customercardhearder">
                <p class="text-center text-white">
                  <b>Supplier Login</b>
                </p>
              </div>
              <div class="card-body text-center">
                <div class="row">
                  <div class="col-md-12 text-left">
                    <label for="user_name" class="text-left">
                      <b> Phone No:</b>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      name="phone"
                      onChange={(e) => {
                        hendletochange(e);
                      }}
                      placeholder="Enter your Phone No"
                    />
                  </div>

                  <div class="col-md-12 text-left">
                    <label for="password" class="text-left">
                      <b>Password:</b>
                    </label>
                    <input
                      type="password"
                      class="form-control"
                      name="password"
                      onChange={(e) => {
                        hendletochange(e);
                      }}
                      placeholder="Enter your password"
                    />
                  </div>
                </div>
              </div>
              <button
                onClick={(e) => {
                  hendletoSubmit(e);
                }}
                type="button"
                class="btn btn-primary buttonsClors"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginSuppler;
