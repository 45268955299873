import React from "react";
import SliderList from "./ListPacgComponent/SliderList";
import LsitingCategory from "./ListPacgComponent/LsitingCategory";
import Requerment from "./ListPacgComponent/Requerment";
import ExploreTopCities from "./ListPacgComponent/ExploreTopCities";
import PopularSearches from "./ListPacgComponent/PopularSearches";
import Recharge from "./ListPacgComponent/Recharge";

function ListIngPage() {
  return (
    <div className="listingNew">
      <SliderList />
      <LsitingCategory />
      <Requerment />
      <PopularSearches />
      {/* <Recharge /> */}
      <ExploreTopCities />
    </div>
  );
}

export default ListIngPage;
